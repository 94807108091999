import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import logo from '../../../assets/logoschool.png';
import alyousefLogo from '../../../assets/alyousef/alyousof-logo.png';
import logofilfil from '../../../assets/logofilfil.png';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { environment } from '../../../environments/environment';
import { useLocation, useNavigate } from 'react-router';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../../../../libs/components/src';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Badge from '@mui/material/Badge';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { loggedOutSuccess } from '@tabletuck/redux-slices';
import CartDrawer from '../CartDrawer/CartDrawer';
import MenuTTdemo from '../../../assets/MenuTTdemo.png';
import {
  activeMenu,
  allactiveMenues,
  checkStore,
  loggedInSuccess,
  menues,
  setUser,
} from '../../../../../../libs/redux-slices/src';
import { Box } from '@mui/system';
import { setDefaultStore } from '../CartDrawer/utils';
import { useTheme } from '@emotion/react';
import TTdemoLogo from '../../../assets/TTdemoLogo.png';

const SchoolHeader = () => {
  const navigate = useNavigate();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const userData = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const res = useSelector((state) => state.authReducer.res);
  const defaultStoreID = userData?.defaultStore;
  const cart = useSelector((state) => state.shopReducer.cart);
  const token = useSelector((state) => state.authReducer.token);
  const [t, i18n] = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [openCartDialoge, setOpenCartDialoge] = React.useState(false);
  const [defaultStoreAdress, setDefaultStoreAdress] = useState('');
  const [open, setOpen] = useState(false);
  const [store, setStore] = React.useState('');
  const [storeaddress, setStoreaddress] = React.useState('');
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    stores?.filter((store) =>
      store.id === userData?.defaultStore
        ? setDefaultStoreAdress(store?.address?.displayName)
        : ''
    );
  }, [defaultStoreAdress]);
  const getCount = () => {
    let count = 0;
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      count = count + element?.quantity;
    }
    setCounter(count);
  };
  useEffect(() => {
    getCount();
  }, [cart, auth?.currentUser?.uid]);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const res = await firestore.collection('users').doc(user?.uid).get();
        if (res.exists) {
          const data = res.data();
          if (data?.email && data.fullName) {
            dispatch(loggedInSuccess({ token: user.uid }));
            dispatch(setUser({ user: user, userData: data }));
          }
        }
      }
    });
  }, [auth, window.location.pathname]);
  const signOut = async () => {
    
    try {
      await auth.signOut();
      dispatch(loggedOutSuccess());
      dispatch(setCart([]));
      localStorage.clear();
      navigate('/');
    } catch (e) {
      console.log(e.message);
    }
    navigate('/');
  };

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'signup':
        return licenses?.frontend?.signup;
      case 'profile':
        return licenses?.frontend?.profile;
      case 'checkout':
        return licenses?.frontend?.checkout;

      default:
        return false;
    }
  };
  const handleClickOpenCartDialoge = () => {
    setOpenCartDialoge(true);
  };
  const handleCloseCartDialoge = () => {
    setOpenCartDialoge(false);
  };
  useEffect(() => {
    auth?.currentUser?.uid &&
      dispatch(menues(res[userData?.defaultStore]?.menus));
  }, [userData?.defaultStore]);
  console.log(userData?.defaultStore, 'test ola');

  return (
    <AppBar
      position="absolute"
      style={{
        backgroundColor:
          location.pathname == '/' ||
          location.pathname == '/menu' ||
          location.pathname == '/confirmOrder'
            ? 'transparent'
            : environment.restaurantId == 'alyousef'
            ? 'black'
            : environment.restaurantId == 'filfil'
            ? 'black'
            : environment.mainColor,

        boxShadow: 'none',
      }}
    >
      <Toolbar>
        {/* Logo on the right */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <img
            src={
              environment.restaurantId === 'alyousef'
                ? alyousefLogo
                : environment.restaurantId === 'ttDemo'
                ? TTdemoLogo
                : environment.restaurantId === 'filfil'
                ? logofilfil
                : logo
            }
            alt=""
            style={{ width: '80px', height: '70px', cursor: 'pointer' }}
            onClick={() => navigate('/')}
          />
        </Typography>
        {auth?.currentUser?.uid && userData?.defaultStore?.length > 0 && (
          <Typography
            variant="caption"
            style={{
              color: '#c6c6c6',
              cursor: 'pointer',
              fontSize: mdDown && '6px',
            }}
            onClick={handleClickOpen}
          >
            (
            {stores
              ?.find((c) => c.id === userData?.defaultStore)
              ?.address?.displayName.split(',')
              .slice(0, 1)
              .join(',')}
            )
          </Typography>
        )}

        {/* Menu items in a row */}
        <div>
          <div>
            <Button
              color="inherit"
              style={{
                fontWeight: 'bold',
                color:
                  location.pathname == '/menu'
                    ? environment?.primaryColor
                    : 'white',
              }}
              onClick={() =>
                auth?.currentUser?.uid && userData?.defaultStore?.length > 0
                  ? navigate('/menu')
                  : handleClickOpen()
              }
            >
              Menu
            </Button>
          </div>
        </div>

        <Dialog onClose={handleClose} open={open}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                padding: '15px 20px 0px 0px',
                display: 'flex',
                justifyContent: 'end',
                borderRadius: '25px',
              }}
            >
              {' '}
              <CancelRoundedIcon
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  marginLeft: '15px',
                }}
                onClick={handleClose}
              />
            </div>

            <DialogTitle
              style={{
                display: 'grid',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  component="p"
                  variant="h5"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  Two Branches, One Appetite!{' '}
                </Typography>
              </div>

              <Typography
                component="p"
                variant="body1"
                style={{ color: 'gray' }}
              >
                {' '}
                Two Branches, Endless Choices! Explore our menu at your
                preferred spot.{' '}
              </Typography>

              <div></div>
            </DialogTitle>

            <DialogContent dividers style={{ textAlign: 'center' }}>
              {stores?.map((c) => (
                <Grid
                  item
                  key={c.id}
                  lg={4}
                  style={{
                    display: 'inline-block',
                    color: 'white',
                    textAalign: 'center',
                    padding: '14px',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    textAlign: 'center',
                  }}
                >
                  <Card
                    style={{
                      border: '2px solid lightgray',
                      borderRadius: '10px',
                      borderColor: 'gray',
                      width: '180px',
                      cursor: 'pointer',
                    }}
                    onClick={async () => {
                      setDefaultStore(
                        token,
                        c.id,
                        c?.restaurant_name,
                        setStore,
                        c?.pickup_enabled,
                        c?.pickup_enabled,
                        c.dinein_enable,
                        c.dinein_enable,
                        c.address.displayName,
                        c?.address?.displayName
                      );
                      if (userData?.bagItems?.length > 0 && cart?.length > 0) {
                        dispatch(checkStore(true));
                      } else dispatch(checkStore(false));

                      // onClickItem(item.restaurant_name)
                      handleClose();
                      const updatedUser = {
                        ...userData,
                        defaultStore: c.id,
                      };
                      dispatch(setUser({ userData: updatedUser }));
                      dispatch(activeMenu(res[c.id]?.active_menu_now));
                      dispatch(allactiveMenues(res[c.id]?.all_active_menus));
                      dispatch(menues(res[c?.id]?.menus));
                      navigate('/menu');
                    }}
                  >
                    <CardContent
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '245px',
                      }}
                    >
                      <img
                        src={c.image}
                        alt={c.image}
                        style={{ width: '100px', height: '80px' }}
                      />
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        {c.restaurant_name}
                      </Typography>
                      <Typography variant="body2" style={{ color: 'gray' }}>
                        {c.address.displayName.split(',').slice(0, 2).join(',')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </DialogContent>
          </div>
        </Dialog>
        <Hidden smDown>
          <>
            <Button
              color="inherit"
              style={{
                fontWeight: 'bold',
                color:
                  location.pathname == '/careers'
                    ? environment?.primaryColor
                    : 'white',
              }}
              onClick={() => navigate('/careers')}
            >
              Careers
            </Button>

            {/* Contact */}
            <Button
              color="inherit"
              style={{
                fontWeight: 'bold',
                color:
                  location.pathname == '/contactus'
                    ? environment?.primaryColor
                    : 'white',
              }}
              onClick={() => navigate('/contactus')}
            >
              Contact
            </Button>
          </>
        </Hidden>

        {/* Vertical line */}
        <div
          style={{
            borderLeft: `1.5px solid ${environment.primaryColor}`,
            height: '30px',
            margin: '0 10px',
          }}
        ></div>

        {/* Basket icon */}
        {
          <>
            {checkLicenses('checkout') && (
              <>
                <CartDrawer
                  openCartDialoge={openCartDialoge}
                  handleCloseCartDialoge={handleCloseCartDialoge}
                  userData={userData}
                  defaultStoreAdress={defaultStoreAdress}
                  setDefaultStoreAdress={setDefaultStoreAdress}
                />
                {counter > 0 ? (
                  <Badge
                    badgeContent={counter > 0 ? counter : ''}
                    sx={{
                      '& .MuiBadge-badge': {
                        color: 'white',
                        backgroundColor: environment.mainColor,
                      },
                    }}
                    style={{
                      marginBottom: '1pc',
                      background: 'radial-gradient(black, transparent)',
                    }}
                  ></Badge>
                ) : (
                  ''
                )}

                <Hidden smUp>
                  <ShoppingCartIcon
                    sx={{
                      color: 'white',
                      width: '10%',
                      display: 'flex',
                      borderRadius: '30px',
                      height: '35px',
                      backgroundColor: environment.mainColor,
                      padding: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={handleClickOpenCartDialoge}
                  />
                </Hidden>
                <Hidden smDown>
                  <ShoppingCartIcon
                    sx={{
                      color: 'white',
                      width: '3%',
                      display: 'flex',
                      borderRadius: '30px',
                      height: '35px',
                      backgroundColor: environment.mainColor,
                      padding: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={handleClickOpenCartDialoge}
                  />
                </Hidden>

                {/* </IconButton> */}
              </>
            )}
            {/* Profile icon */}
          </>
        }
        {auth?.currentUser?.uid && (
          <>
            {checkLicenses('profile') && (
              <>
                <div
                  style={{
                    border: '6px solid white',
                    background: 'white',
                    borderRadius: '20px',
                    marginRight: '14px',
                    marginLeft: '10px',
                    boxShadow:
                      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  }}
                >
                  <PersonOutlineSharpIcon
                    style={{
                      color: environment.primaryColor,
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/profile')}
                  />
                </div>
              </>
            )}

            <IconButton color="inherit">
              <ExitToAppIcon
                onClick={signOut}
                style={{
                  transform: i18n.language === 'ar' ? 'scaleX(-1.0)' : 'none',
                  color: environment.primaryColor,
                }}
              />
            </IconButton>
          </>
        )}
        {!auth?.currentUser?.uid && checkLicenses('signup') && (
          <>
            <Hidden smUp>
              <CustomButton
                bg={environment.primaryColor}
                onClick={() => navigate('/signup')}
                buttonText={t('Joinus')}
              />
            </Hidden>
            <Hidden smDown>
              <CustomButton
                bg={environment.primaryColor}
                onClick={() => navigate('/signup')}
                buttonText={t('Joinus')}
              />
            </Hidden>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default SchoolHeader;
