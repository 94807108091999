import React, { useEffect, useState } from 'react';

import { Container, Typography } from '@mui/material';
import {
  firestore,
  RootFirestore,
} from '../../../../../../libs/firebase-service/src';
import { environment } from '../../../environments/environment';

const TermsConditions = () => {
  const [privacy, setPrivacy] = useState([]);
  const res = environment?.headerTitle;

  const getPrivacy = async () => {
    try {
      let ItemsRef =
        RootFirestore.collection('privacy').doc('termsAndConditions');
      let list = [];
      await ItemsRef.get().then((c) => {
        if (c?.exists) {
          setPrivacy(c?.data());
          list = c?.data();
        }
      });

      setPrivacy(list);
    } catch (err) {
      // console.log(err);
    }
  };
  useEffect(() => {
    getPrivacy();
  }, [privacy]);

  return (
    <>
      <div style={{ width: '100%', background: 'rgb(235,235,235)' }}>
        <div style={{ marginTop: '82px' }} />
        <Typography
          variant={'h4'}
          black
          style={{
            textAlign: 'center',
            padding: '20px',
            fontWeight: 'bold',
            marginTop: '20px',
          }}
        >
          {'Terms and Conditions'}
        </Typography>
      </div>
      <Container fixed maxWidth={'md'}>
        <div style={{ padding: '10vh 16px' }}>
          <Typography variant={'body1'} medium style={{ fontWeight: 'bold' }}>
            Effective: 7th Aug 2023
          </Typography>
          <div style={{ height: '24px' }} />

          <Typography
            variant={'body1'}
            regular
            style={{ lineHeight: '1.5', marginTop: '10px' }}
          >
            {privacy.privacy?.map((c) =>
              c.body?.map((a, index) => {
                // Replace the word 'oldWord' with 'newWord' in the text
                const modifiedText = a.replace('<RESTAURANT_NAME>', res);

                return (
                  <div key={index} style={{ marginBottom: '12px' }}>
                    {modifiedText}
                  </div>
                );
              })
            )}
          </Typography>

          <div style={{ height: '48px' }} />

          <div style={{ height: '48px' }} />

          {/*<div style={{ height: "48px" }}/>*/}
          {/*<Text variant={"h6"} bold className={classes.subHeading}>*/}
          {/*    Why do we collect this information and how do we use it?*/}
          {/*</Text>*/}
          {/*<Text variant={"body1"} regular>*/}
          {/*    Your personal information is collected primarily to conduct a background check to ensure our Posters have safe Taskers providing services. Other information is used for a variety of reasons:*/}
          {/*</Text>*/}
        </div>
      </Container>
      <div
        style={{ height: '1px', width: '100%', background: 'rgb(220,220,220)' }}
      />
    </>
  );
};

export default TermsConditions;
