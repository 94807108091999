import React from 'react';
import { useNavigate } from 'react-router-dom';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import registerFooterBg from '../../../assets/registerFooterBg.png';
const RegisterFooter = () => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  return (
    <div
      style={{
        backgroundImage: `url(${registerFooterBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: 'white',
        padding: '55px 0',
      }}
    >
      <Grid
        container
        spacing={3}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Grid item xs={12} lg={4}>
          <Typography
            variant="h3"
            style={{
              fontFamily: 'Poppins',
              textAlign: 'center',
              fontWeight: 700,
              fontSize: '1.875rem',
              lineHeight: '2.813rem',
              textTransform: 'uppercase',
            }}
          >
             {t('footer.registerFor')}{' '}
            <span style={{ color: environment.mainColor }}>{t('footer.free')}</span>{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography
            variant="body1"
            style={{
              fontFamily: 'Poppins',
              textAlign: 'center',
            }}
          >
            {t('footer.registerWithUs')}{' '}
            <span style={{ color: environment.mainColor, fontWeight: 700 }}>
            {t('footer.deliveryOrder')}
            </span>{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography
            variant="body1"
            style={{
              fontFamily: 'Poppins',
              textAlign: 'center',
              fontWeight: 700,
              borderRadius: '5px',
              backgroundColor: environment.mainColor,
              cursor: 'pointer',
              padding: '10px 40px',
              width: 'fit-content',
            }}
            onClick={() => navigate('/signup')}
          >
            {t('footer.register')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterFooter;
