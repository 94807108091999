import React, { useState } from 'react';
import { Grid, Container } from '@mui/material';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { firestore } from '@tabletuck/firebase-services';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FulPageDialoge({ open, handleClose }) {
  const navigate = useNavigate();
  const categories = useSelector((state) => state.shopReducer.currentCategories);



  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {/* <Header isAdmin={false} /> */}
      <Container
        maxWidth={'lg'}
        style={{ marginTop: '100px', borderBottom: '2px solid #ebebeb' }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          onClick={handleClose}
        >
          <CloseIcon style={{ height: '20px', color: environment.mainColor }} />
        </div>
        <Typography
          variant="h4"
          style={{ color: environment.mainColor, padding: '10px' }}
          onClick={handleClose}
        >
          Menu <KeyboardArrowUpIcon style={{ height: '18px' }} />
        </Typography>

        <Typography
          sx={{ ml: 2, flex: 1, marginBottom: '20px' }}
          variant="body2"
          component="div"
        >
          BROWSE BY CATEGORY
        </Typography>
      </Container>

      <Container style={{ marginTop: '20px', marginBottom: '20px' }}>
        <div
          className="grid grid-rows-5 md:grid-flow-col gap-4  "
          style={{ marginBottom: '22px', marginTop: '30px' }}
        >
          {' '}
          {categories?.map((item, index) => (
            <div
              key={item.id}
              className="border-b-2"
              style={{ display: 'flex' }}
            >
              {item.category_image_url_web ? (
                <img
                  src={item.category_image_url_web}
                  alt="category"
                  width={'13%'}
                />
              ) : (
                ''
              )}
              <Typography
                variant="h5"
                style={{ marginTop: '20px', cursor: 'pointer' }}
                onClick={() => {
                  handleClose();
                  navigate(`/menu/${item.id}`);
                }}
              >
                {!item.category_image_url_web && '-'} {item.name}
              </Typography>{' '}
            </div>
          ))}
        </div>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: '100px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: '12px',
              color: '#3d3d3d',
              marginTop: '13px',
            }}
          >
            Hey you, start your oder then checkout to have a great experience
            and enjoy your meal !
          </Typography>
        </Grid>
      </Container>
    </Dialog>
  );
}
export default FulPageDialoge;
