import { Hidden, Typography } from '@mui/material';
import { Container, Grid } from '@mui/material';
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import footerSchool from '../../../assets/footerSchool.png';
import footerAlyosef from '../../../assets/alyousef/footer1.jpg';
import bgfilfil from '../../../assets/bgfilfil.jpeg';
import { useSelector } from 'react-redux';
import { FaYelp } from 'react-icons/fa';

function Footer() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'ar':
        return licenses?.lng?.ar;
      case 'en':
        return licenses?.lng?.en;
      case 'es':
        return licenses?.lng?.es;
      case 'he':
        return licenses?.lng?.he;
      case 'zh':
        return licenses?.lng?.zh;

      default:
        return false;
    }
  };

  return (
    <>
  

      <div
        dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
        style={{
          width: '100%',
          padding: '32px 0',
          background:
            environment.restaurantId === 'elmbrookschools' ||
            environment.restaurantId === 'alyousef' ||
            environment.restaurantId === 'filfil'
              ? ''
              : '#222831',
          backgroundImage:
            environment.restaurantId === 'elmbrookschools'
              ? `url(${footerSchool})`
              : environment.restaurantId === 'alyousef'
              ? `url(${footerAlyosef})`
              : environment.restaurantId === 'filfil'
              ? `url(${bgfilfil})`
              : ``,
        }}
      >
        <Container maxWidth={'lg'}>
          <Grid
            container
            spacing={4}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item lg={5} xl={4} sm={12}>
              <Typography
                variant="h6"
                style={{
                  color: 'white',
                  marginTop: '20px',
                  marginBottom: '10px',
                }}
              >
                {environment?.headerTitle}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  opacity: '0.8',
                }}
              >
                {t('footerDetails')}
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                marginTop="20px"
              >
                {environment.facebookLink != '' && (
                  <Grid item lg={1}>
                    <a href={environment.facebookLink} target="_blank">
                      <FacebookIcon
                        style={{ color: 'white', marginRight: '4px' }}
                      />
                    </a>
                  </Grid>
                )}
                {environment.instagramLink != '' && (
                  <Grid item lg={1}>
                    <a href={environment.instagramLink} target="_blank">
                      <InstagramIcon
                        style={{ color: 'white', marginRight: '4px' }}
                      />
                    </a>
                  </Grid>
                )}
                {environment.twitterLink != '' && (
                  <Grid item lg={1}>
                    <a href={environment.twitterLink} target="_blank">
                      <TwitterIcon style={{ color: 'white' }} />
                    </a>
                  </Grid>
                )}
                {environment.yelpLink != '' &&
                  environment?.restaurantId == 'istanbulDk' && (
                    <Grid item lg={1}>
                      <a href={environment.yelpLink} target="_blank">
                        <FaYelp style={{ color: 'white' }} />
                      </a>
                    </Grid>
                  )}
                {environment.yelpLink != '' &&
                  environment?.restaurantId == 'ttJordanDev' && (
                    <Grid item lg={1}>
                      <a href={environment.yelpLink} target="_blank">
                        <FaYelp style={{ color: 'white' }} />
                      </a>
                    </Grid>
                  )}
                     {environment.yelpLink != '' &&
                  environment?.restaurantId == 'ttJordan' && (
                    <Grid item lg={1}>
                      <a href={environment.yelpLink} target="_blank">
                        <FaYelp style={{ color: 'white' }} />
                      </a>
                    </Grid>
                  )}
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              xl={3}
              md={12}
              sm={12}
              style={{ marginTop: '20px', marginBottom: '10px' }}
            >
              <Typography
                variant="h6"
                style={{
                  color: 'white',
                }}
              >
                {t('contact')}
              </Typography>
              <Typography
                variant="caption"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  height: '85%',
                  marginTop: '3px',
                  cursor: 'pointer',
                }}
              >
                {' '}
                <LocationOnIcon />{' '}
                <a
                  href={
                    environment?.restaurantId === 'ttJordanDev'
                      ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                      : environment?.restaurantId === 'istanbulDk'
                      ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                      : environment?.restaurantId === 'alyousef'
                      ? 'https://www.google.com/maps/place/Al-Yousef+Supermarket+%26+Restaurant/@42.9295555,-87.9399237,17z/data=!3m1!4b1!4m6!3m5!1s0x88051151327e486b:0x7c529f0a50e1089d!8m2!3d42.9295555!4d-87.9399237!16s%2Fg%2F11bw50pjkw?entry=ttu'
                     :  environment?.restaurantId === 'ttJordan'
                      ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                      : 'https://www.google.com/maps/place/700+W+Wisconsin+Ave+%23A,+Milwaukee,+WI+53233,+EUA/@43.0389455,-87.9205169,17z/data=!3m1!4b1!4m6!3m5!1s0x8805199d8fc34fc9:0x19a0735cad76b699!8m2!3d43.0389455!4d-87.9205169!16s%2Fg%2F11qzdbhjb8'
                  }
                  target="_blank"
                >
                  {environment.address}
                </a>
              </Typography>{' '}
              {environment?.restaurantId === 'filfil' && (
                <>
                  <br />
                  <Typography
                    variant="caption"
                    style={{
                      color: 'white',
                      opacity: '0.8',
                      height: '85%',
                      marginTop: '3px',
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    <LocationOnIcon />{' '}
                    <a
                      href={
                        'https://www.google.com/maps/place/1125+N+9th+St,+Milwaukee,+WI+53233,+USA/@43.0449532,-87.926173,17z/data=!3m1!4b1!4m6!3m5!1s0x8805197a07844bf1:0x6753493f28655d70!8m2!3d43.0449532!4d-87.9235981!16s%2Fg%2F11c1xpx0z9?entry=ttu'
                      }
                      target="_blank"
                    >
                      1125 N 9th St, Milwaukee
                    </a>
                  </Typography>{' '}
                </>
              )}
              <br />
              <Typography
                variant="caption"
                style={{
                  color: 'white',
                  opacity: '0.8',
                  height: '85%',
                  marginTop: '30px',
                  marginBottom: '20px',
                }}
              >
                {' '}
                <MailIcon />
                <a
                  href={`mailto:${environment.email}`}
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                >
                  {environment.email}
                </a>
              </Typography>
              <br />
              {/* <Typography
              variant="caption"
              style={{
                color: 'white',
                opacity: '0.8',
                height: '85%',
                marginTop: '30px',
                marginBottom: '20px',
                display: 'initial',
              }}
            >
              {' '}
              <LocalPhoneIcon />{' '}
              <a
                href={`tel:${environment.phoneNumber}`}
                style={{
                  cursor: 'pointer',
                }}

              >
                {environment.phoneNumber}
              </a>
            </Typography> */}
            </Grid>
            <Hidden mdUp>
              <Grid
                item
                lg={5}
                xl={3}
                md={12}
                sm={12}
                style={{ marginTop: '20px', marginBottom: '10px' }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: 'white',
                  }}
                >
                  {t('getInTouch')}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    height: '85%',
                    marginTop: '3px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/contactus');
                  }}
                >
                  {' '}
                  {t('contact')}
                </Typography>{' '}
                <br />
                <Typography
                  variant="caption"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    height: '85%',
                    marginTop: '30px',
                    marginBottom: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigate('/careers');
                  }}
                >
                  {' '}
                  {t('career')}
                </Typography>
              </Grid>
            </Hidden>
            {environment?.restaurantId === 'istanbulDk' && (
              <div>
                <link
                  rel="stylesheet"
                  href="https://cdn6.localdatacdn.com/badges/restaurantji/css/badge-1.css"
                ></link>
                <div id="circle_v2" class="v5 v5_1" tabIndex="0">
                  <div class="rb_flex rb_top">
                    <div class="arc-heading ">
                      <svg
                        height="160px"
                        viewBox="0 0 160 160"
                        width="160px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <path
                            d="M 30 80 a 50 50 0 1 1 110 0"
                            id="heading-arc"
                          ></path>
                        </defs>
                        <text
                          class="arc-heading__heading "
                          fill="#000"
                          textAnchor="middle"
                        >
                          <textPath startOffset="50%" xlinkHref="#heading-arc">
                            <a
                              href="https://www.restaurantji.com/wi/greenfield/taqwas-bakery-and-restaurant-/"
                              target="_blank"
                            >
                              Recommended
                            </a>
                          </textPath>
                        </text>
                      </svg>
                    </div>
                    <div class="arc-heading arc-heading__bottom">
                      <svg
                        height="140px"
                        viewBox="0 0 140 140"
                        width="140px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <path
                            d="M 12 60 a 55 55 0 0 0 140 0"
                            id="subheading-arc"
                            stroke="red"
                          ></path>
                        </defs>
                        <text
                          class="arc-heading__subheading"
                          fill="#000"
                          textAnchor="middle"
                        >
                          <textPath
                            startOffset="50%"
                            xlinkHref="#subheading-arc"
                          >
                            <a
                              target="_blank"
                              href="https://www.restaurantji.com"
                            >
                              Restaurantji
                            </a>
                          </textPath>
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {environment?.restaurantId === 'ttJordanDev' && (
              <div>
                <link
                  rel="stylesheet"
                  href="https://cdn6.localdatacdn.com/badges/restaurantji/css/badge-1.css"
                ></link>
                <div id="circle_v2" class="v5 v5_1" tabIndex="0">
                  <div class="rb_flex rb_top">
                    <div class="arc-heading ">
                      <svg
                        height="160px"
                        viewBox="0 0 160 160"
                        width="160px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <path
                            d="M 30 80 a 50 50 0 1 1 110 0"
                            id="heading-arc"
                          ></path>
                        </defs>
                        <text
                          class="arc-heading__heading "
                          fill="#000"
                          textAnchor="middle"
                        >
                          <textPath startOffset="50%" xlinkHref="#heading-arc">
                            <a
                              href="https://www.restaurantji.com/wi/greenfield/taqwas-bakery-and-restaurant-/"
                              target="_blank"
                            >
                              Recommended
                            </a>
                          </textPath>
                        </text>
                      </svg>
                    </div>
                    <div class="arc-heading arc-heading__bottom">
                      <svg
                        height="140px"
                        viewBox="0 0 140 140"
                        width="140px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <path
                            d="M 12 60 a 55 55 0 0 0 140 0"
                            id="subheading-arc"
                            stroke="red"
                          ></path>
                        </defs>
                        <text
                          class="arc-heading__subheading"
                          fill="#000"
                          textAnchor="middle"
                        >
                          <textPath
                            startOffset="50%"
                            xlinkHref="#subheading-arc"
                          >
                            <a
                              target="_blank"
                              href="https://www.restaurantji.com"
                            >
                              Restaurantji
                            </a>
                          </textPath>
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
               {environment?.restaurantId === 'ttJordan' && (
              <div>
                <link
                  rel="stylesheet"
                  href="https://cdn6.localdatacdn.com/badges/restaurantji/css/badge-1.css"
                ></link>
                <div id="circle_v2" class="v5 v5_1" tabIndex="0">
                  <div class="rb_flex rb_top">
                    <div class="arc-heading ">
                      <svg
                        height="160px"
                        viewBox="0 0 160 160"
                        width="160px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <path
                            d="M 30 80 a 50 50 0 1 1 110 0"
                            id="heading-arc"
                          ></path>
                        </defs>
                        <text
                          class="arc-heading__heading "
                          fill="#000"
                          textAnchor="middle"
                        >
                          <textPath startOffset="50%" xlinkHref="#heading-arc">
                            <a
                              href="https://www.restaurantji.com/wi/greenfield/taqwas-bakery-and-restaurant-/"
                              target="_blank"
                            >
                              Recommended
                            </a>
                          </textPath>
                        </text>
                      </svg>
                    </div>
                    <div class="arc-heading arc-heading__bottom">
                      <svg
                        height="140px"
                        viewBox="0 0 140 140"
                        width="140px"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <path
                            d="M 12 60 a 55 55 0 0 0 140 0"
                            id="subheading-arc"
                            stroke="red"
                          ></path>
                        </defs>
                        <text
                          class="arc-heading__subheading"
                          fill="#000"
                          textAnchor="middle"
                        >
                          <textPath
                            startOffset="50%"
                            xlinkHref="#subheading-arc"
                          >
                            <a
                              target="_blank"
                              href="https://www.restaurantji.com"
                            >
                              Restaurantji
                            </a>
                          </textPath>
                        </text>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <Typography
            variant="caption"
            style={{
              color: 'white',
              opacity: '0.8',
              height: '85%',
              marginTop: '3px',
              cursor: 'pointer',
            }}
          >
            {' '}
            {t("language")}
          </Typography> */}
            <br />

            <Grid
              item
              lg={5}
              xl={3}
              md={12}
              sm={12}
              style={{ marginTop: '20px', marginBottom: '10px' }}
              display="flex"
              gap={5}
            >
              {checkLicenses('ar') && i18n.language === 'en' && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    i18n.changeLanguage('ar');
                  }}
                >
                  العربية
                </Typography>
              )}

              {checkLicenses('en') && i18n.language === 'ar' && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    i18n.changeLanguage('en');
                  }}
                >
                  English
                </Typography>
              )}
              {checkLicenses('es') && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    i18n.changeLanguage('en');
                  }}
                >
                  {/* EN */}
                  English (US)
                </Typography>
              )}
              {checkLicenses('zh') && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    i18n.changeLanguage('zh');
                  }}
                >
                  {/* CH */}
                  中文
                </Typography>
              )}
              {checkLicenses('es') && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    i18n.changeLanguage('es');
                  }}
                >
                  {/* ES */}
                  Español
                </Typography>
              )}
              {checkLicenses('he') && (
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    opacity: '0.8',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    i18n.changeLanguage('he');
                  }}
                >
                  {/* HE */}
                  עִברִית
                </Typography>
              )}
            </Grid>
          </Grid>
          <div
            style={{
              margin: '24px 0',
              background: 'rgb(70,70,80)',
              height: '1px',
            }}
          />
          <Grid
            container
            spacing={4}
            justify={'space-between'}
            alignItems={'center'}
            style={{ justifyContent: 'space-between' }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                }}
              >
                <Typography
                  variant={'body2'}
                  style={{ color: 'white', opacity: '0.5' }}
                >
                  {/* Copyright © {environment.restaurantId} All Rights Reserved. */}
                  {/* {t('copyRight')} */}
                  {environment.restaurantId === 'ttJordanDev' ||  environment.restaurantId === 'ttJordan'? (
                    t('copyRightZetaton')
                  ) : (
                    <Typography>
                      Copyright ©{environment?.headerTitle} All Rights Reserved.
                    </Typography>
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                }}
              >
                <Typography
                  variant={'body2'}
                  style={{ color: 'white', opacity: '0.5', cursor: 'pointer' }}
                >
                  <span onClick={() => navigate('/privacy-policy')}>
                    {t('privacyPolicy')}
                  </span>{' '}
                  |{' '}
                  <span onClick={() => navigate('/terms-conditions')}>
                    {t('termsAndConditions')}
                  </span>{' '}
                  {environment?.restaurantId != 'alyousef' &&
                  environment?.restaurantId != 'filfil' ? (
                    <>
                      | {'  '}{' '}
                      <a href="https://zetaton.com/" target="_blank">
                        {t('powered')}
                      </a>
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
export default Footer;
