import {
  Button,
  Divider,
  Grid,
  Typography,
  Paper,
  TextField,
  useMediaQuery,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useDispatch, useSelector } from 'react-redux';
import { setCart } from '../../../../../../libs/redux-slices/src';
import TipsBox from './TipsBox';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import FeesDialog from '../Dialouge/FeesDailoge';
import ReusableDialog from '../Dialouge/ReusableDailoge';

const useStyles = makeStyles((theme) => ({
  typographyH6: {
    flex: 1,
    color: 'gray',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  typographyP: {
    ml: 2,
    flex: 1,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

const OrderSummary = ({
  typeTip,
  setTypeTip,
  otherTip,
  setOtherTip,
  setTipsAmount,
  tipAmount,
  delivery_method,
  defaultStore,
  defaultStorefee,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [store, setStore] = React.useState([]);
  const [addressStore, setAddressStore] = useState([]);
  const [fees, setFees] = useState(0);
  const currentFees = useSelector((state) => state.shopReducer.currentFees);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [openDeliveryfees, setOpenDeliveryfees] = useState(false);
  const [opentaxes, setOpentaxes] = useState(false);
  const [dataStore, setDataStore] = useState([]);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const addresses = useSelector((state) => state.authReducer.userAddresses);
  const userData = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const [serviceFees, setServiceFees] = useState(0);

  const {
    orderType,
    subTotal: total,
    discount,
    isVerify,
  } = useSelector((state) => state.shopReducer.cartData);
  const licenses = useSelector((state) => state.licenReducer.licenses);

  const checkLicenses = (pageName) => {
    return licenses?.licenses?.frontend?.[pageName];
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      setOpen(false);
    }
  };

  const getUserAddresses = async () => {
    const bags = userData?.bagItems;
    bags.length && dispatch(setCart(bags));
    const defaultDeliveryAddress = userData.defaultDeliveryAddress;

    try {
      await Promise.all(
        addresses?.map(async (a) => {
          if (a?.id === defaultDeliveryAddress) {
            setAddressStore(a?.delivery_store_id);
          }
        })
      );
    } catch (error) {
      console.error('Error in getUserAddresses:', error);
    }
  };

  const getStores = () => {
    stores?.map((store) => {
      if (store?.id === addressStore) {
        setData(store);
      }

      if (store?.delivery_method === 'local') {
        setFees(store?.local_delivery_fee.fixed_fees);
      } else setFees(store?.doordash_delivery_fees);
    });
  };

  const handleBackdropClick = () => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };

  const getTips = () => {
    if (
      environment?.restaurantId === 'ttJordanDev' ||
      environment?.restaurantId === 'ttJordan'
    ) {
      let parsedTip = parseFloat(otherTip);
      if (!isNaN(parsedTip) && parsedTip >= 0) {
        setTipsAmount(parsedTip);
      } else {
        parsedTip = 0;
        setTipsAmount(parsedTip);
      }
      if (total <= 10) {
        if (typeTip === 'a') {
          return setTipsAmount(0), 0;
        } else if (typeTip === 'b') {
          return setTipsAmount(0.5), 0.5;
        } else if (typeTip === 'c') {
          return setTipsAmount(1), 1;
        } else if (typeTip === 'd') {
          return setTipsAmount(1.5), 1.5;
        } else if (typeTip === 'other') {
          if (typeof otherTip === 'string' && otherTip?.trim() === '') {
            return total;
          } else return setTipsAmount(otherTip), parseFloat(otherTip);
        } else return total;
      } else if (total > 10) {
        if (typeTip === 'a') {
          return setTipsAmount(0), 0;
        } else if (typeTip === 'b') {
          return setTipsAmount(0.5), 0.5;
        } else if (typeTip === 'c') {
          return setTipsAmount(1), 1;
        } else if (typeTip === 'd') {
          return setTipsAmount(1.5), 1.5;
        } else if (typeTip === 'other') {
          if (otherTip === '') {
            return total;
          } else return setTipsAmount(otherTip), parseFloat(otherTip);
        } else return total;
      }
    } else {
      let parsedTip = parseFloat(otherTip);
      if (!isNaN(parsedTip) && parsedTip >= 0) {
        setTipsAmount(parsedTip); // Update state with parsed tip value
      } else {
        parsedTip = 0; // Set tip amount to 0 in case of invalid input
        setTipsAmount(parsedTip); // Update state with 0
      }
      if (total <= 10) {
        if (typeTip === 'a') {
          return setTipsAmount(1), Math.floor(1);
        } else if (typeTip === 'b') {
          return setTipsAmount(2), Math.floor(2);
        } else if (typeTip === 'c') {
          return setTipsAmount(3), Math.floor(3);
        } else if (typeTip === 'other') {
          if (typeof otherTip === 'string' && otherTip?.trim() === '') {
            return total;
          } else return setTipsAmount(otherTip), parseFloat(otherTip);
        } else return total;
      } else if (total > 10) {
        if (typeTip === 'a') {
          return (
            setTipsAmount(Math.floor(0.1 * (total + fees))),
            Math.floor(0.1 * (total + fees))
          );
        } else if (typeTip === 'b') {
          return (
            setTipsAmount(Math.floor(0.2 * (total + fees))),
            Math.floor(0.2 * (total + fees))
          );
        } else if (typeTip === 'c') {
          return (
            setTipsAmount(Math.floor((total + fees) * 0.3)),
            Math.floor(0.3 * (total + fees))
          );
        } else if (typeTip === 'other') {
          if (otherTip === '') {
            return total;
          } else return setTipsAmount(otherTip), parseFloat(otherTip);
        } else return total;
      }
    }
  };

  const getUser = async () => {
    const bags = userData?.bagItems;
    bags.length && dispatch(setCart(bags));
    const storeId = userData?.defaultStore;
    stores?.map((store) => {
      if (store?.id === storeId) {
        setStore(store?.restaurant_name);
      }
    });
  };
  const getStore = async () => {
    stores?.map((storee) => {
      if (storee?.restaurant_name === store) {
        setDataStore(storee);
      }
    });
  };
  useEffect(() => {
    setDeliveryFee(currentFees);
  }, [currentFees]);

  useEffect(() => {
    getUserAddresses();
    getStores();
  }, [addressStore]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getStore();
  }, [store, dataStore]);

  useEffect(() => {
    if (userData?.pickupOrDelivery === 'dinein') {
      setServiceFees(dataStore?.service_fee?.dineIn);
    } else if (userData?.pickupOrDelivery === 'pickup') {
      setServiceFees(dataStore?.service_fee?.pickUp);
    } else if (userData?.pickupOrDelivery === 'delivery') {
      setServiceFees(dataStore?.service_fee?.delivery);
    }
  }, [userData, userData?.pickupOrDelivery, store]);

  return (
    <Paper
      style={{
        padding: '1.8rem 4.5rem 2rem 4.5rem',
        marginLeft: '10px',
        marginTop: lgDown ? '10px' : '0',
      }}
    >
      {orderType !== 'dinein'
        ? checkLicenses('tips') && (
            <Grid item style={{}} xs={12}>
              <Typography
                component="p"
                variant="h6"
                style={{ fontWeight: 'bold', marginTop: '20px' }}
              >
                {orderType === 'delivery'
                  ? t('confirmPage.tips')
                  : t('confirmPage.RewardWaiter')}
              </Typography>
              <TipsBox
                typeTip={typeTip}
                setTypeTip={setTypeTip}
                environment={environment}
                total={total}
                fees={fees}
                handleClickOpen={handleClickOpen}
                t={t}
              />
              <Typography
                component="p"
                variant="body2"
                style={{ color: 'gray' }}
              >
                {orderType === 'delivery' ? t('confirmPage.tipsDetails') : ''}
              </Typography>
            </Grid>
          )
        : ''}
      <Typography component="p" variant="h5" style={{ fontWeight: 'bold' }}>
        {t('confirmPage.Ordersummary')}
      </Typography>
      <Divider />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: '28px', marginBottom: '28px' }}
      >
        <Grid item xs={8}>
          <Typography
            className={classes.typographyP}
            variant="body1"
            component="p"
          >
            {t('basket.subtotal')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.typographyH6}>
            {environment.currency}
            {isVerify
              ? parseFloat((total - discount).toString()).toFixed(2)
              : parseFloat(total.toString()).toFixed(2)}
          </Typography>
        </Grid>
        {orderType === 'delivery' && (
          <>
            <Grid item xs={6}>
              <Typography
                className={classes.typographyP}
                variant="body1"
                component="p"
              >
                {t('basket.deliveryFees')}{' '}
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenDeliveryfees(true)}
                />
              
                <ReusableDialog
                  open={openDeliveryfees}
                  onClose={() => setOpenDeliveryfees(false)}
                  title={t('basket.deliveryFees')}
                  description={t(
                    'basket.deliveryFeesAreCalculatedBasedOnYourLocationAndOtherFactors'
                  )}
                  buttonText={t('basket.oK')}
                />
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography
                component="a"
                variant="h6"
                className={classes.typographyH6}
              >
                <p>
                  {environment.currency}
                  {parseFloat(
                    deliveryFee + total * dataStore?.tax?.miscellaneous
                  )?.toFixed(2)}{' '}
                </p>
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={7}>
          <Typography
            className={classes.typographyP}
            variant="body1"
            component="p"
          >
            {orderType === 'pickup' || orderType === 'dinein' ? (
              <span>{t('basket.estTaxesAndFees')}</span>
            ) : (
              <span>{t('basket.estTaxes')}</span>
            )}{' '}
            <InfoOutlinedIcon
              fontSize="small"
              sx={{
                cursor: 'pointer',
              }}
              style={{ direction: 'initial' }}
              onClick={() => setOpentaxes(true)}
            />
            <FeesDialog
              opentaxes={opentaxes}
              setOpentaxes={setOpentaxes}
              dataStore={dataStore}
              orderType={orderType}
              serviceFees={serviceFees}
              total={total}
              data={data}
            />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.typographyH6}>
            {orderType === 'dinein' && (
              <p>
                <span>
                  <span>
                    {/* {defaultStorefee?.percentage_type === 'amount' ? (<>{''}</>):(<>{''}</>)} */}{' '}
                    {dataStore?.tax?.type === 'amount' ? (
                      <>
                        {''} {environment.currency}
                        {isVerify
                          ? parseFloat(
                              (
                                dataStore.tax?.tax +
                                dataStore?.service_fee?.dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                dataStore.tax?.tax +
                                dataStore?.service_fee?.dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''} {environment.currency}
                        {isVerify
                          ? parseFloat(
                              (
                                dataStore.tax?.tax * (total - discount) +
                                dataStore?.service_fee?.dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                dataStore.tax?.tax * total +
                                dataStore?.service_fee?.dineIn
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    )}
                  </span>
                </span>
              </p>
            )}
            {orderType === 'delivery' && (
              <p style={{ marginLeft: '5px' }}>
                <span>
                  {data.id === addressStore ? (
                    <span>
                      {dataStore?.tax?.type === 'amount' ? (
                        <>
                          {''} {environment.currency}
                          {isVerify
                            ? parseFloat(
                                (dataStore.tax?.tax * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (dataStore.tax?.tax * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {''} {environment.currency}
                          {isVerify
                            ? parseFloat(
                                (dataStore.tax?.tax * (total - discount))
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (dataStore.tax?.tax * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      )}{' '}
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </p>
            )}
            {orderType === 'pickup' && (
              <p>
                <span>
                  <span>
                    {' '}
                    {defaultStorefee?.percentage_type === 'amount' ? (
                      <>
                        {''} {environment.currency}
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee?.tax?.tax +
                                defaultStorefee?.service_fee?.pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee?.tax?.tax +
                                defaultStorefee?.service_fee?.pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {''} {environment.currency}
                        {isVerify
                          ? parseFloat(
                              (
                                defaultStorefee?.tax?.tax * (total - discount) +
                                defaultStorefee.service_fee?.pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)
                          : parseFloat(
                              (
                                defaultStorefee?.tax?.tax * total +
                                defaultStorefee.service_fee?.pickUp
                              )
                                .toString()
                                .replaceAll(',', '')
                            ).toFixed(2)}
                      </>
                    )}{' '}
                  </span>
                </span>
              </p>
            )}
          </Typography>
        </Grid>

        {orderType != 'dinein' ? (
          <>
            {' '}
            {checkLicenses('tips') && (
              <>
                <Grid item xs={6}>
                  <Typography
                    className={classes.typographyP}
                    variant="body1"
                    component="p"
                  >
                    {t('confirmPage.tip')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className={classes.typographyH6}>
                    {environment.currency}
                    {tipAmount}
                  </Typography>
                </Grid>{' '}
              </>
            )}
          </>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography
            className={classes.typographyP}
            variant="h6"
            component="div"
            style={{ color: environment?.mainColor }}
          >
            {t('basket.total')}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant="h6" className={classes.typographyH6}>
            {orderType === 'pickup' && (
              <>
                {dataStore?.tax?.type === 'amount' ? (
                  <>
                    {environment.currency}
                    {parseFloat(
                      (
                        (checkLicenses('tips') ? getTips() : 0) +
                        (dataStore?.tax?.tax || 0) +
                        (dataStore.service_fee?.pickUp || 0) +
                        (isVerify ? total - discount : total)
                      )
                        .toString()
                        .replaceAll(',', '')
                    ).toFixed(2)}
                  </>
                ) : (
                  <>
                    {environment.currency}
                    {parseFloat(
                      (
                        (checkLicenses('tips') ? getTips() : 0) +
                        (dataStore?.tax?.tax || 0) *
                          (isVerify ? total - discount : total) +
                        (dataStore.service_fee?.pickUp || 0) +
                        (isVerify ? total - discount : total)
                      )
                        .toString()
                        .replaceAll(',', '')
                    ).toFixed(2)}
                  </>
                )}
              </>
            )}
            {orderType === 'dinein' && (
              <>
                {dataStore?.tax?.type === 'amount' ? (
                  <>
                    {environment.currency}
                    {isVerify
                      ? parseFloat(
                          (
                            dataStore?.tax?.tax +
                            dataStore?.service_fee?.dineIn +
                            (total - discount)
                          )
                            .toString()
                            .replaceAll(',', '')
                        ).toFixed(2)
                      : parseFloat(
                          (
                            dataStore?.tax?.tax +
                            dataStore?.service_fee?.dineIn +
                            total
                          )
                            .toString()
                            .replaceAll(',', '')
                        ).toFixed(2)}
                  </>
                ) : (
                  <>
                    {environment.currency}
                    {isVerify
                      ? parseFloat(
                          (
                            dataStore?.tax?.tax * (total - discount) +
                            dataStore?.service_fee?.dineIn +
                            (total - discount)
                          )
                            .toString()
                            .replaceAll(',', '')
                        ).toFixed(2)
                      : parseFloat(
                          (
                            dataStore?.tax?.tax * total +
                            dataStore?.service_fee?.dineIn +
                            total
                          )
                            .toString()
                            .replaceAll(',', '')
                        ).toFixed(2)}
                  </>
                )}
              </>
            )}
          </Typography>
          <Typography variant="h6" className={classes.typographyH6}>
            {orderType === 'delivery' && (
              <>
                {data.id === addressStore ? (
                  <>
                    {dataStore?.tax?.type === 'amount' ? (
                      <>
                        {environment.currency}
                        {isVerify &&
                          parseFloat(
                            (
                              (checkLicenses('tips') ? getTips() : 0) +
                              dataStore?.tax?.tax +
                              (total - discount) +
                              deliveryFee +
                              (total - discount * dataStore?.tax?.miscellaneous)
                            )
                              .toString()
                              .replaceAll(',', '')
                          ).toFixed(2)}
                        {!isVerify &&
                          parseFloat(
                            (
                              dataStore?.tax?.tax +
                              total +
                              deliveryFee +
                              total * dataStore?.tax?.miscellaneous +
                              (checkLicenses('tips') ? getTips() : 0)
                            )
                              .toString()
                              .replaceAll(',', '')
                          ).toFixed(2)}
                      </>
                    ) : (
                      <>
                        {environment.currency}
                        {isVerify &&
                          parseFloat(
                            (
                              (checkLicenses('tips') ? getTips() : 0) +
                              dataStore.tax.tax * (total - discount) +
                              (total - discount) +
                              deliveryFee
                            )
                              .toString()
                              .replaceAll(',', '')
                          ).toFixed(2)}
                        {!isVerify &&
                          parseFloat(
                            dataStore.tax.tax * total +
                              total +
                              deliveryFee +
                              total * dataStore?.tax?.miscellaneous +
                              (checkLicenses('tips') ? getTips() : 0)
                          ).toFixed(2)}
                      </>
                    )}
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Dialog
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown
        open={open}
        style={{ borderRadius: '250px' }}
        dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
      >
        <div
          style={{
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'space-evenly',
            borderRadius: '25px',
          }}
        >
          {' '}
          <DialogTitle
            style={{
              display: 'grid',
              justifyContent: 'center',
            }}
          >
            <Typography
              component="p"
              variant="h6"
              style={{
                fontWeight: 'bold',
                color: 'rgb(160, 25, 19)',
              }}
            >
              {' '}
              {i18n.language === 'ar'
                ? t('confirmPage.DILIVERYTIPS')
                : 'DELIVERY TIPS'}
            </Typography>
          </DialogTitle>
        </div>

        <DialogContent dividers>
          <Grid
            container
            style={{
              width: '100%',
              flexDirection: 'row',
            }}
          >
            <Grid item xs={12}>
              <TextField
                id="standard-basic"
                label={i18n.language === 'ar' ? '' : 'Add a Tip'}
                variant="outlined"
                value={otherTip}
                sx={{
                  '& label.Mui-focused': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                  },
                }}
                error={otherTip < 0}
                style={{ width: '100%' }}
                onChange={(e) => setOtherTip(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (parseFloat(otherTip) >= 0) {
                    setOtherTip(otherTip);
                    handleClose();
                  }
                }}
                style={{ color: environment?.mainColor }}
              >
                {' '}
                {t('profilee.save')}
              </Button>
              <Button
                onClick={() => {
                  setTypeTip('a');

                  handleClose();
                }}
                style={{ color: environment?.mainColor }}
              >
                {' '}
                {t('profilee.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default OrderSummary;
