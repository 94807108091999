import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: [],
  currentItem: null,
  resId: null,
  currentCategories: null,
  SMStabValue: 0,
  menuDetails: {
    startDate: null,
    endDate: null,
    imageUrl: '',
    selectedDays: [],
  },
  cartData: {
    subTotal: 0,
    orderType: '',
    code: '',
    IsVerify: false,
    discount: 0,
    note: '',
    value: 1,
    typeAddress: '',
    storeId: '',
  },
  defaultStore: '',
  deliveryStore: '',
  checkItem: '',
  deliveryAddress: '',
  subTotal: '',
  orderType: '',
  code: '',
  IsVerify: false,
  discount: 0,
  note: '',
  tableNo: 0,
  value: 1,
  typeAddress: '',
  // menudetails:{}
    currentFees :0

};

const authSlice = createSlice({
  name: 'shopReducer',
  initialState,
  reducers: {
    AddToCart: (state, action) => {
      const newAddItemState = [...state.cart];
      const prevItem = newAddItemState.find(
        (item) => item.id === action.payload.id
      );
      if (prevItem) prevItem.qty += 1;
      else {
        newAddItemState.push({ ...action.payload });
      }

      state.cart = newAddItemState;
    },
    RemoveFromCart: (state, action) => {
      const cloneCart = [...state.cart];
      state.cart = cloneCart.filter((item) => item.id !== action.payload);
    },
    AdjItems: (state, action) => {
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id
            ? { ...item, qty: +action.payload.qty }
            : item
        ),
      };
    },
    LoadItems: (state, action) => {
      return {
        ...state,
        currentItem: action.payload,
      };
    },  LoadDeliveryFees: (state, action) => {
      return {
        ...state,
        currentFees: action.payload,
      };
    },
    LoadCategories: (state, action) => {
      return {
        ...state,
        currentCategories: action.payload,
      };
    },
    setStores: (state, action) => {
      return {
        ...state,
        stores: action.payload,
      };
    },
    ResturantID: (state, action) => {
      return {
        ...state,
        resId: action.payload,
      };
    },
    updateStoresId: (state, action) => {
      return {
        ...state,
        storeId: action.payload,
      };
    },
    setModifiers: (state, action) => {
      return {
        ...state,
        modifiers: action.payload,
      };
    },
    UpdateCartData: (state, action) => {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          note: action.payload.note,
          typeAddress: action.payload.typeAddress,
          discount: action.payload.discount,
          code: action.payload.code,
          // value: action.payload.value,
          isVerify: action.payload.isVerify,
          subTotal: action.payload.subTotal,
          orderType: action.payload.orderType,
          storeId: action.payload.storeId,
        },
      };
    },
    UpdateMenuDetails: (state, action) => {
      return {
        ...state,
        menuDetails: {
          ...state.menuDetails,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          imageUrl: action.payload.imageUrl,
          selectedDays: action.payload.selectedDays,
          isCatering: action.payload.isCatering,
        },
      };
    },
    UpdatTabsSMSvalue: (state, action) => {
      return {
        ...state,
        SMStabValue: action.payload,
      };
    },
    clearCartData: (state) => {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          code: '',
          note: '',
          discount: 0,
          isVerify: false,
          subTotal: 0,
        },
      };
    },
    updateCode: (state, action) => {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          code: action.payload.code,
          discount: action.payload.discount,
          isVerify: action.payload.isVerify,
        },
      };
    },
    UdpateNote: (state, action) => {
      return {
        ...state,
        note: action.payload,
      };
    },
    TableNumber: (state, action) => {
      return {
        ...state,
        tableNo: action.payload,
      };
    },
    CurrentItemValue: (state, action) => {
      return {
        ...state,
        value: action.payload,
      };
    },

    DefaultAddress: (state, action) => {
      return {
        ...state,
        defauldAdd: action.payload,
      };
    },
    DefaultStore: (state, action) => {
      return {
        ...state,
        defaultStore: action.payload,
      };
    },
    DefaultStoreFee: (state, action) => {
      return {
        ...state,
        defaultStorefee: action.payload,
      };
    },

    CheckItem: (state, action) => {
      return {
        ...state,
        checkItem: action.payload,
      };
    },
    setDeliveryStore: (state, action) => {
      return {
        ...state,
        deliveryAddress: action.payload.address,
        deliveryStore: action.payload.store,
      };
    },

    setCart: (state, action) => {
      return {
        ...state,
        cart: action.payload,
      };
    },
    setMenuDetals: (state, action) => {
      return {
        ...state,
        menu: action.payload,
      };
    },
  },

  setOutOStockItemsDelivery: (state, action) => {
    return {
      ...state,
      outOfStock: action.payload,
    };
  },
  setOutOStockItemsDinein: (state, action) => {
    return {
      ...state,
      outOfStock: action.payload,
    };
  },
});
export const {
  AddToCart,
  RemoveFromCart,
  ResturantID,
  AdjItems,
  LoadItems,
  LoadCategories,
  setModifiers,
  setCart,
  IsVerify,
  DefaultStore,
  DefaultAddress,
  DefaultStoreFee,
  AddressType,
  CurrentItemValue,
  setDeliveryStore,
  UpdateCartData,
  UdpateNote,
  clearCartData,
  updateCode,
  TableNumber,
  CheckItem,
  updateStoresId,
  setStores,
  setOutOStockItemsDelivery,
  setOutOStockItemsDinein,
  setMenuDetals,
  UpdateMenuDetails,
  UpdatTabsSMSvalue,LoadDeliveryFees   

} = authSlice.actions;
export const shopReducer = authSlice.reducer;
