import React from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from '@tabletuck/redux-slices';
import { PersistGate } from 'redux-persist/integration/react'; 
export function ReduxWrapper({ children }) {
  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    {children}
    </PersistGate>
    </Provider>;
}
export default ReduxWrapper;
