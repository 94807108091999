import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Hidden, Typography } from '@mui/material';

import { Carousel } from 'react-responsive-carousel';
import './Home.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  firestore,
  RootFirestore,
} from '../../../../../../libs/firebase-service/src';
import { useNavigate } from 'react-router-dom';
import { environment } from '../../../environments/environment';

function Advs(props) {
  const { title, image, id   } = props.item;
  const navigate = useNavigate();
  return (
    <>
   
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // style={{ padding: '5px', height: '15pc' }}
        style={{ position: 'relative', background: environment.mainColor }} onClick={()=>{navigate(`/details/${id}`)}}
      >
        <Grid item lg={4} style={{display:'flex', justifyContent:'center',alignItems:"center"}}>
        <Typography
              variant="h3"
              style={{
                fontSize: '50px',
                color:'white'
              }}
            >
             {title} 
            </Typography>
        </Grid>
        <Grid item lg={4}>
          <img src={image} alt={title} style={{ width: '50%', height: '50%' }} />
        </Grid>
      </Grid>
     </>
  );
}
export default Advs;
