import { Container, Typography, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch';
import { environment } from '../../../environments/environment';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../../../../../libs/firebase-service/src';
import LandingRestaurants from '../../components/Restaurants/LandingRestaurants';
import Restaurants from '../../components/Restaurants/Restaurants';
import RestaurantHomeCard from '../../components/Card/RestaurantHomeCard';
import Loading from 'react-fullscreen-loading';
function RestaurantPage() {
  const [stores, setStores] = useState();
  const [storesFirebase, setStoresFirebase] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const [loading, setLoading] = useState(false);


  const goToMyLocation = () => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          setCurrentLocation({
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
          console.log('Current Location:', {
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
        },
        async (error) => {
          console.log(error);
          await getStores();
        },
        { enableHighAccuracy: true, timeout: 5000 }
      );
    }
  };

  const getStores = async () => {
    setLoading(true);
    try {
      let storesRef = firestore.collection('stores');
      storesRef = storesRef.where('is_deleted', '==', false);
      const resultData = await storesRef.get();
      const mappedItems = resultData.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
      setStoresFirebase(mappedItems);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  async function getStoresNearUser(userLatitude, userLongitude) {
    setLoading(true);
    const client = environment.restaurantId === 'ttJordanDev' ? algoliasearch(
      'AQFFALVPNM',
      '01f30e72eb08a9881f4701717c03599d'
    )
    :
    algoliasearch(
      'BP82AK7SFR',
      '626be990d00969534d3028a74fe84592'
    )
    const index = client.initIndex('stores');

    try {
      const result = await index.search('', {
        aroundLatLng: `${userLatitude}, ${userLongitude}`,
        aroundRadius: 'all',
        getRankingInfo: true,
      });
      setLoading(false);
      return result.hits;
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      throw new Error('Error fetching stores near user');
    }
  }

  const nearMe = async () => {
    setLoading(true);
    try {
      const storesnearme = await getStoresNearUser(
        currentLocation?.lat,
        currentLocation?.lng
      );
      const filteredStores = storesnearme.filter((store) => !store.is_deleted);
      setStores(filteredStores);
      setLoading(false);
      console.log('Stores near user (filtered):', filteredStores);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    goToMyLocation();
    nearMe();
  }, [currentLocation?.lat]);
  return (
    <>
      <LandingRestaurants
        setLoading={setLoading}
        setStores={setStores}
        setStoresFirebase={setStoresFirebase}
        getStores={getStores}
        nearMe={nearMe}
      />
      <Container maxWidth="lg" style={{ marginTop: '80px' }}>
        <Grid
          container
          spacing={3}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {currentLocation?.lat &&
            (stores?.length > 0 ? (
              stores.map((store) => (
                <Grid
                  item
                  key={store.id}
                  xs={12}
                  sm={6}
                  md={3}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <RestaurantHomeCard store={store} />
                </Grid>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: environment.darkBlueColor,
                  fontSize: '1.125rem',
                  lineHeight: '1.688rem',
                  textAlign: 'center',
                  margin: 'auto',
                }}
              >
                No stores to display
              </Typography>
            ))}
          {!currentLocation?.lat &&
            (storesFirebase?.length > 0 ? (
              storesFirebase.map((store) => (
                <Grid
                  item
                  key={store.id}
                  xs={12}
                  sm={6}
                  md={3}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <RestaurantHomeCard store={store} />
                </Grid>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: environment.darkBlueColor,
                  fontSize: '1.125rem',
                  lineHeight: '1.688rem',
                  textAlign: 'center',
                  margin: 'auto',
                }}
              >
                No stores to display
              </Typography>
            ))}

        </Grid>
      </Container>
      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={environment.mainColor}
      />
    </>
  );
}

export default RestaurantPage;
