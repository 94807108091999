import React, { useState, useEffect } from 'react';
import { environment } from '../../../environments/environment';
import {
  Container,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Button,
  IconButton,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import arrows from '../../../assets/arrows.png';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
const RestaurentSearch = ({
  setLoading,
  setStores,
  setStoresFirebase,
  getStores,
  nearMe,
  storesFirebase
}) => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [searchText, setSearchText] = useState('');

  const searchStoresMethod = async (text) => {
    const formattedText = text?.trim()?.toLowerCase();
    setSearchText(formattedText);

    // setLoading(true);
    if (formattedText === '' || formattedText.length === 0) {
      getStores();
      nearMe();
      // setLoading(false);
      return;
    } else {
      let ref = firestore.collection('stores');
      ref = ref.where('is_deleted', '==', false);
      const resultData = await ref.get();
      const mappedItems = resultData.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
      const result = mappedItems?.filter((d) => {
        const menuName = d?.restaurant_name?.trim()?.toLowerCase();
        return menuName?.includes(formattedText);
      });
      // setLoading(false);
      setStores(result);
      setStoresFirebase(result);
    }
  };
  useEffect(() => {
    searchStoresMethod(searchText);
  }, [searchText]);
  return (
    <>
      <Container
        sx={{
          marginTop: 10,
        }}
      >
        <Grid
          container
          rowSpacing={2}
          flexDirection="column"
          style={{ alignItems: 'center', width: '100%' }}
        >
          <Grid item xs={12} lg={6}>
            <Typography
              variant="h1"
              style={{
                fontFamily: 'Playfair Display',
                fontWeight: 900,
                fontSize: smDown ? '1.5rem' : '2.5rem',
                lineHeight: smDown ? '2.283rem' : '3.283rem',
                color: '#080808',
                textAlign: 'center',
              }}
            >
              {t('searchAmong')}{' '}
              <span style={{ color: environment.mainColor }}>{storesFirebase?.length}</span>{' '}
              {t('restaurantAndFindYourFavoriteFood')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '25px',
          }}
        >
          <Grid item xs={11} md={6}>
            <Formik
              initialValues={{
                searchValue: '',
              }}
              onSubmit={(values) => {
                searchStoresMethod(values.searchValue);
              }}
            >
              {({ handleBlur, handleChange, errors, values, isValid }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder={t('searchAnything')}
                  fullWidth
                  onBlur={handleBlur('searchValue')}
                  onChange={(e) => {
                    handleChange(e); // Update Formik's state
                    searchStoresMethod(e.target.value); // Call searchMethod with the new value
                  }}
                  style={{
                    background: environment.secoundaryColor,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: environment.mainColor,
                              textTransform: 'capitalize',
                              borderRadius: '10px',
                            }}
                            onClick={() => navigate('/restaurants')}
                          >
                            {t('restaurants')}
                          </Button>
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& input': {
                      height: '40px',
                      border: 'none',
                    },
                    '& .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: '12px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: environment.mainColor,
                      },
                    },
                  }}
                />
              )}
            </Formik>
          </Grid>
          <Grid
            item
            md={3}
            style={{ position: 'relative', display: mdDown ? 'none' : 'flex' }}
          >
            <Typography style={{ textAlign: 'center' }}>
              {t('orViewTheFollowingRestaurant')}
            </Typography>
            <img
              alt="arrows"
              src={arrows}
              style={{
                position: 'absolute',
                right: i18n.language === 'ar' || i18n.language === 'he' ? "unset" :'-28px',
                top: '-17px',
                transform: i18n.language === 'ar' || i18n.language === 'he' ? 'scaleX(-1)' :"unset",
                left: i18n.language === 'ar' || i18n.language === 'he' ? ' 60px' : "unset"
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default RestaurentSearch;
