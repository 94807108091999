// import {
//   Box,
//   Container,
//   InputAdornment,
//   Paper,
//   TextField,
//   Typography,
//   Grid,
//   Button,
//   FormGroup,
//   FormControlLabel,
//   Checkbox,
//   MenuItem,
//   MenuList,
//   Divider,
//   Menu,
//   useMediaQuery,
// } from '@mui/material';
// import React, { useEffect, useState, useRef } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
// import algoliasearch from 'algoliasearch';
// import { environment } from '../../../environments/environment';
// import { useNavigate } from 'react-router-dom';

// import { makeStyles } from '@mui/styles';

// import { styled, alpha } from '@mui/material/styles';
// import { useTheme } from '@mui/styles';
// import { firestore } from '../../../../../../libs/firebase-service/src';
// import RestaurantHomeCard from '../../components/Card/RestaurantHomeCard';
// import Loading from 'react-fullscreen-loading';
// import { Formik } from 'formik';
// const useStyles = makeStyles(() => ({
//   box: {
//     color: 'white',
//     border: '1px solid #E8E8E8',
//     borderRadius: '5px',
//     padding: '24px',
//     display: 'flex',
//     flexDirection: 'column',
//     gap: '20px',
//   },
//   flexColumn: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'start',
//     gap: '16px',
//   },
//   button: {
//     borderRadius: '8px',
//     fontFamily: 'Poppins',
//     fontWeight: 500,
//   },
// }));
// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right',
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   '& .MuiPaper-root': {
//     borderRadius: 6,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color:
//       theme.palette.mode === 'light'
//         ? 'rgb(55, 65, 81)'
//         : theme.palette.grey[300],
//     boxShadow:
//       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//     '& .MuiMenu-list': {
//       padding: '4px 0',
//     },
//     '& .MuiMenuItem-root': {
//       '& .MuiSvgIcon-root': {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5),
//       },
//       '&:active': {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           theme.palette.action.selectedOpacity
//         ),
//       },
//     },
//   },
// }));
// const options = ['18 Per Page', '18 Per Page', '18 Per Page'];
// const sortOptions = ['Default sorting', 'Default sorting', 'Default sorting'];
// function Restaurants() {
  
//   return (
//     <>
//       <Container maxWidth="lg" style={{ marginTop: '80px' }}>
//         <Grid
//           container
//           spacing={2}
//           style={{
//             justifyContent: 'space-between',
//           }}
//         >
//           <Grid
//             item
//             xs={12}
//             lg={3}
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               gap: '16px',
//             }}
//           >
//             <Box className={classes.box}>
//               {/* <Box className={classes.flexColumn}>
//                 <Button
//                   variant="contained"
//                   className={classes.button}
//                   style={{
//                     backgroundColor: environment.mainColor,
//                     color: 'white',
//                     textTransform: 'capitalize',
//                     lineHeight: '1.5rem',
//                     textAlign: 'center',
//                     letterSpacing: '0.013rem',
//                   }}
//                 >
//                   {' '}
//                   Product Price
//                   <CloseIcon
//                     style={{ marginLeft: '5px', width: '14px', height: '14px' }}
//                   />
//                 </Button>
//                 <Button
//                   variant="text"
//                   className={classes.button}
//                   style={{
//                     color: environment.mainColor,
//                     textTransform: 'none',
//                     lineHeight: '1rem',
//                   }}
//                 >
//                   {' '}
//                   Clear all
//                 </Button>
//               </Box> */}
//               <Formik
//                 initialValues={{
//                   searchValue: '',
//                 }}
//                 onSubmit={(values) => {
//                   searchStoresMethod(values.searchValue);
//                 }}
//               >
//                 {({ handleBlur, handleChange, errors, values, isValid }) => (
//                   <TextField
//                     size="small"
//                     variant="outlined"
//                     placeholder="Search"
//                     fullWidth
//                     onBlur={handleBlur('searchValue')}
//                     onChange={(e) => {
//                       handleChange(e); // Update Formik's state
//                       searchStoresMethod(e.target.value); // Call searchMethod with the new value
//                     }}
//                     style={{
//                       background: environment.secoundaryColor,
//                     }}
//                     InputProps={{
//                       startAdornment: (
//                         <InputAdornment position="start">
//                           <SearchIcon />
//                         </InputAdornment>
//                       ),
//                     }}
//                     sx={{
//                       '& input': {
//                         height: '40px',
//                         border: 'none',
//                       },
//                       '& .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root': {
//                         borderRadius: '12px',
//                       },
//                       '& .MuiOutlinedInput-root': {
//                         '&.Mui-focused fieldset': {
//                           borderColor: environment.mainColor,
//                         },
//                       },
//                     }}
//                   />
//                 )}
//               </Formik>
//               {/* <Box display="flex" justifyContent="space-between">
//                 <Typography
//                   variant="body1"
//                   style={{
//                     fontFamily: 'Poppins',
//                     fontWeight: 600,
//                     letterSpacing: '0.006rem',
//                     color: environment.darkBlueColor,
//                   }}
//                 >
//                   Category
//                 </Typography>
//                 <Button
//                   variant="contained"
//                   style={{
//                     background: '#F1F1F1',
//                     color: '#BBC1CE',
//                     borderRadius: '26px',
//                     fontFamily: 'Poppins',
//                     fontWeight: 500,
//                     lineHeight: '1.5rem',
//                     minWidth: '25px',
//                     width: '25px',
//                     height: '25px',
//                     padding: '0',
//                   }}
//                 >
//                   {' '}
//                   <AddIcon style={{ fontSize: '1rem' }} />
//                 </Button>
//               </Box>
//               <FormGroup>
//                 <Box
//                   display="flex"
//                   justifyContent="space-between"
//                   alignItems="center"
//                 >
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         defaultChecked={isChecked}
//                         onChange={(event) => setIsChecked(event.target.checked)}
//                         sx={{
//                           color: '#BBC1CE',
//                           '&.Mui-checked': {
//                             color: environment.mainColor,
//                           },
//                         }}
//                       />
//                     }
//                     label="Sandwiches"
//                     sx={{
//                       fontFamily: 'Poppins',
//                       color: isChecked
//                         ? environment.darkBlueColor
//                         : environment.grayBlueColor,
//                       '& .MuiTypography-root': {
//                         fontFamily: 'Poppins',
//                         letterSpacing: '0.2px',
//                         fontWeight: isChecked ? 500 : 400,
//                       },
//                     }}
//                   />
//                   <Typography
//                     variant="body2"
//                     style={{
//                       fontFamily: 'Poppins',
//                       textAlign: 'center',
//                       lineHeight: '1.5rem',
//                       fontWeight: 500,
//                       color: environment.grayBlueColor,
//                       letterSpacing: '0.013rem',
//                     }}
//                   >
//                     11
//                   </Typography>
//                 </Box>
//               </FormGroup> */}
//             </Box>

//             {/* <Box className={classes.box}>
//               <Box className={classes.flexColumn}>
//                 <Typography
//                   variant="body1"
//                   style={{
//                     fontFamily: 'Poppins',
//                     fontWeight: 600,
//                     color: environment.darkBlueColor,
//                     fontSize: '1.125rem',
//                     lineHeight: '1.688rem',
//                   }}
//                 >
//                   Product Tags
//                 </Typography>
//                 <Box
//                   style={{
//                     display: 'flex',
//                     gap: '10px',
//                     flexWrap: 'wrap',
//                     justifyContent: 'space-between',
//                   }}
//                 >
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       color: environment.shapeBlueColor,
//                       borderBottom: '1px solid #F2F2F2',
//                     }}
//                   >
//                     Services
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       color: environment.shapeBlueColor,
//                       borderBottom: '1px solid #F2F2F2',
//                     }}
//                   >
//                     Services
//                   </Typography>{' '}
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       color: environment.shapeBlueColor,
//                       borderBottom: '1px solid #F2F2F2',
//                     }}
//                   >
//                     Services
//                   </Typography>{' '}
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       color: environment.shapeBlueColor,
//                       borderBottom: '1px solid #F2F2F2',
//                     }}
//                   >
//                     Services
//                   </Typography>{' '}
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       color: environment.shapeBlueColor,
//                       borderBottom: '1px solid #F2F2F2',
//                     }}
//                   >
//                     Services
//                   </Typography>{' '}
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       color: environment.shapeBlueColor,
//                       borderBottom: '1px solid #F2F2F2',
//                     }}
//                   >
//                     Services
//                   </Typography>
//                 </Box>
//               </Box>
//             </Box>
//             <Box className={classes.box}>
//               <Box className={classes.flexColumn}>
//                 <Typography
//                   variant="body1"
//                   style={{
//                     fontFamily: 'Poppins',
//                     fontWeight: 600,
//                     color: environment.darkBlueColor,
//                     fontSize: '1.125rem',
//                     lineHeight: '1.688rem',
//                   }}
//                 >
//                   Rate
//                 </Typography>
//                 <FormGroup
//                   style={{
//                     width: '100%',
//                   }}
//                 >
//                   <Box
//                     display="flex"
//                     justifyContent="space-between"
//                     alignItems="center"
//                   >
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           defaultChecked={isChecked}
//                           onChange={(event) =>
//                             setIsChecked(event.target.checked)
//                           }
//                           sx={{
//                             color: '#BBC1CE',
//                             '&.Mui-checked': {
//                               color: environment.mainColor,
//                             },
//                           }}
//                         />
//                       }
//                       label={
//                         <>
//                           <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon />{' '}
//                           <StarIcon />
//                         </>
//                       }
//                       sx={{
//                         fontFamily: 'Poppins',
//                         color: '#FFC529',
//                         '& .MuiTypography-root': {
//                           //   width: '16px',
//                           //   height: '16px',
//                         },
//                       }}
//                     />
//                     <Typography
//                       variant="body2"
//                       style={{
//                         fontFamily: 'Poppins',
//                         textAlign: 'center',
//                         lineHeight: '1.5rem',
//                         fontWeight: 500,
//                         color: environment.grayBlueColor,
//                         letterSpacing: '0.013rem',
//                       }}
//                     >
//                       11
//                     </Typography>
//                   </Box>
//                   <Box
//                     display="flex"
//                     justifyContent="space-between"
//                     alignItems="center"
//                   >
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           defaultChecked={isChecked}
//                           onChange={(event) =>
//                             setIsChecked(event.target.checked)
//                           }
//                           sx={{
//                             color: '#BBC1CE',
//                             '&.Mui-checked': {
//                               color: environment.mainColor,
//                             },
//                           }}
//                         />
//                       }
//                       label={
//                         <>
//                           <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon />{' '}
//                           <StarIcon style={{ color: '#E3E3E3' }} />
//                         </>
//                       }
//                       sx={{
//                         fontFamily: 'Poppins',
//                         color: '#FFC529',
//                         '& .MuiTypography-root': {
//                           //   width: '16px',
//                           //   height: '16px',
//                         },
//                       }}
//                     />
//                     <Typography
//                       variant="body2"
//                       style={{
//                         fontFamily: 'Poppins',
//                         textAlign: 'center',
//                         lineHeight: '1.5rem',
//                         fontWeight: 500,
//                         color: environment.grayBlueColor,
//                         letterSpacing: '0.013rem',
//                       }}
//                     >
//                       11
//                     </Typography>
//                   </Box>
//                 </FormGroup>
//               </Box>
//             </Box> */}
//           </Grid>
//           <Grid item xs={12} lg={8}>
//             {/* <Box
//               style={{
//                 display: 'flex',
//                 justifyContent: smDown ? 'start' : 'end',
//                 alignItems: 'center',
//                 gap: '32px',
//                 flexWrap: 'wrap',
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 style={{
//                   fontFamily: 'Poppins',
//                   fontWeight: 500,
//                   color: environment.grayBlueColor,
//                   lineHeight: '1.313rem',
//                   width: 'fit-content',
//                 }}
//               >
//                 Display
//               </Typography>
//               <Button
//                 id="demo-customized-button"
//                 ref={anchorRef}
//                 sx={{
//                   background: 'white',
//                   borderRadius: '8px',
//                   color: environment.darkBlueColor,
//                   marginRight: '10px',
//                   border: '1px solid #EAECEE',
//                   '&:hover': {
//                     background: 'white',
//                   },
//                 }}
//                 aria-controls={open ? 'demo-customized-menu' : undefined}
//                 aria-haspopup="true"
//                 aria-expanded={open ? 'true' : undefined}
//                 style={{
//                   fontWeight: 500,
//                   textTransform: 'capitalize',
//                   padding: '12px 16px',
//                 }}
//                 onClick={() => {
//                   handleToggle();
//                   handleClick();
//                 }}
//                 endIcon={<KeyboardArrowDownIcon />}
//               >
//                 {options[selectedIndex]}
//               </Button>
//               <StyledMenu
//                 id="demo-customized-menu"
//                 MenuListProps={{
//                   'aria-labelledby': 'demo-customized-button',
//                 }}
//                 anchorEl={anchorRef.current}
//                 open={open}
//                 onClose={handleClose}
//               >
//                 <MenuList id="split-button-menu" autoFocusItem>
//                   {options.map((option, index) => (
//                     <MenuItem
//                       key={option}
//                       selected={index === selectedIndex}
//                       onClick={(event) => handleMenuItemClick(event, index)}
//                     >
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </MenuList>
//               </StyledMenu>

//               <Button
//                 id="demo-customized-button"
//                 ref={anchorRef}
//                 sx={{
//                   background: 'white',
//                   borderRadius: '8px',
//                   color: environment.darkBlueColor,
//                   marginRight: '10px',
//                   border: '1px solid #EAECEE',
//                   '&:hover': {
//                     background: 'white',
//                   },
//                 }}
//                 aria-controls={open ? 'demo-customized-menu' : undefined}
//                 aria-haspopup="true"
//                 aria-expanded={open ? 'true' : undefined}
//                 style={{
//                   fontWeight: 500,
//                   textTransform: 'capitalize',
//                   padding: '12px 16px',
//                 }}
//                 onClick={() => {
//                   handleToggle();
//                   handleClick();
//                 }}
//                 endIcon={<KeyboardArrowDownIcon />}
//               >
//                 {options[selectedIndex]}
//               </Button>
//               <StyledMenu
//                 id="demo-customized-menu"
//                 MenuListProps={{
//                   'aria-labelledby': 'demo-customized-button',
//                 }}
//                 anchorEl={anchorRef.current}
//                 open={open}
//                 onClose={handleClose}
//               >
//                 <MenuList id="split-button-menu" autoFocusItem>
//                   {sortOptions.map((option, index) => (
//                     <MenuItem
//                       key={option}
//                       selected={index === selectedIndex}
//                       onClick={(event) => handleMenuItemClick(event, index)}
//                     >
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </MenuList>
//               </StyledMenu>
//             </Box> */}
//             <Box
//               style={{
//                 display: 'flex',
//                 gap: '16px',
//                 flexWrap: 'wrap',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 marginTop: '30px',
//               }}
//             >
//               {currentLocation?.lat &&
//                 (stores?.length > 0 ? (
//                   stores.map((store) => (
//                     <Grid
//                       item
//                       key={store.id}
//                       xs={8}
//                       sm={4}
//                       md={3.5}
//                       style={{
//                         cursor: 'pointer',
//                       }}
//                     >
//                       <RestaurantHomeCard store={store} />
//                     </Grid>
//                   ))
//                 ) : (
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       fontWeight: 600,
//                       color: environment.darkBlueColor,
//                       fontSize: '1.125rem',
//                       lineHeight: '1.688rem',
//                     }}
//                   >
//                     No stores to display
//                   </Typography>
//                 ))}
//               {!currentLocation?.lat &&
//                 (storesFirebase?.length > 0 ? (
//                   storesFirebase.map((store) => (
//                     <Grid
//                       item
//                       key={store.id}
//                       xs={8}
//                       sm={4}
//                       md={3.5}
//                       style={{
//                         cursor: 'pointer',
//                       }}
//                     >
//                       <RestaurantHomeCard store={store} />
//                     </Grid>
//                   ))
//                 ) : (
//                   <Typography
//                     variant="body1"
//                     style={{
//                       fontFamily: 'Poppins',
//                       fontWeight: 600,
//                       color: environment.darkBlueColor,
//                       fontSize: '1.125rem',
//                       lineHeight: '1.688rem',
//                     }}
//                   >
//                     No stores to display
//                   </Typography>
//                 ))}
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//       <Loading
//         loading={!!loading}
//         background="rgba(86, 100, 210, 0.1)"
//         loaderColor={environment.mainColor}
//       />
//     </>
//   );
// }

// export default Restaurants;
