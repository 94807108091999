import React, { useEffect, useState } from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  FormLabel,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ChildItemModifier from './ChildItemModifier';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { environment } from '../../../environments/environment';

function OnePermitted({
  modifier,
  handleChange,
  setOptionsPrice,
  setExtraQuantity,
  setCalculatedPrice,
  calculatedPrice,
  priceToShow,
  //   handleChangeChild,
  modifiersGroup,
  handleChooseModifires,
  extraQuantity,
  zeros,
  setZeros,
  optionsPrice,
  showResults,
  checkedModifiers,
  setCheckedModifiers,
  childPriceOption,
  errReq,
  summErr,
  checkSumAdd,
  checkSumM,
  checkedOptions,
  setMaxErr,
  modid,
  optionid,
  setChildPriceOption,
  handleClose,
  setShowChildP,
  c,
  handleOpenn,
  showChildoneP,
  openn,
  handleClosee,
  handleChangeChild,
  optionsPriceChild,
  setOptionsPriceChild,
  checkBoxchangeChild

}) {
  const [value, setValue] = useState(null);
  const [prev, setPrev] = useState(null);
  const [quantity, setQuantity] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [price, setPrice] = useState(0);
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const [ChildID, setChildId] = useState([]);
  const dispatch = useDispatch();
  const [modName, setModName] = useState({});

  const getDefault = () => {
    for (const opt of modifier.options) {
      if (opt.isDefault) {
        setValue(opt.title);
        setExtraQuantity((exq) => ({
          ...exq,
          [modifier?.id]: [
            {
              customizationMeta: {},
              defaultQuantity: opt?.minPermitted,
              id: opt?.id,
              modifierTitle: opt?.title,
              price: opt?.price,
              quantity: 1,
              title: modifier?.title,
            },
          ],
        }));
        setCalculatedPrice(priceToShow);

        return;
      }
    }
  };
  useEffect(() => {
    getDefault();
  }, [modifier?.options]);
  console.log(value, price);

  useEffect(() => {
    let option = modifier?.options.filter((o) => o?.isDefault === true)[0];
    setSelectedOptions(
      modifier?.options.filter((o) => o?.isDefault === true)[0]
    );
    setQuantity({ [option?.id]: option?.defaultQuantity });
    setPrice(option?.defaultQuantity * option?.price);
  }, [modifier]);

  return (
    <div>
      {' '}
      <div
        style={{
          // backgroundColor: '#E6E6E6',
          marginTop: '15px',
        }}
      >
        <Typography
          component="p"
          variant="body1"
          style={{
            fontWeight: 'bold',
          }}
        >
          {modifier?.title}
          <br />
          <span style={{ color: 'gray', fontWeight: 'normal' }}>
            {/* to do */}
            (Select 1)
          </span>
        </Typography>
      </div>
      <FormControl
        style={{
          // padding: '1rem 2.5rem 1rem 1rem',
          width: '100%',
        }}
      >
        {modifier?.options?.map((o) => (
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={(e) => {
              setChildId(o?.childModifiers);
              setModName({ ...modName, title: o.title, id: o?.id });
              let option = modifier?.options.filter(
                (o) => o?.title === e?.target?.value
              );
              setSelectedOptions(option[0]);
              setQuantity({ [option[0]?.id]: option[0]?.defaultQuantity });
              handleChange(
               e,
                modifier,
                setValue,
                setPrev,
                prev,
                value,
                { [option[0]?.id]: option[0]?.defaultQuantity },
                option[0]?.defaultQuantity * option[0]?.price,
                o
              );
              setPrice(option[0]?.defaultQuantity * option[0]?.price);

              // setOptionsPrice(0);
            }}
          >
            <Grid
              container
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <FormControlLabel
                value={o?.title}
                control={
                  <Radio
                    sx={{
                      color: green[900],
                      '&.Mui-checked': {
                        color: green[900],
                      },
                    }}
                    onChange={(e) => {
                      if (o?.childModifiers?.length > 0) {
                        setShowChildP(true);
                        handleOpenn(c?.id, c);
                      } else {
                        setShowChildP(false);
                      }
                      // setValue(e.target.value);
                    }}
                  />
                }
                label = {
                  o?.price > 0
                    ? `${o?.title} (${environment?.currency}${o?.price})`
                    : o?.title
                }
              />
              {modifier?.maxPermitted > modifier?.maxPermittedUnique &&
                selectedOptions?.id === o?.id && (
                  <Grid>
                    <Typography
                      component="p"
                      variant="h5"
                      style={{ fontWeight: 'bold' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          border: '2px solid green',
                          borderRadius: '20px',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{ padding: '5px 10px 5px' }}
                          onClick={() => {
                            if (quantity[o?.id] > o?.defaultQuantity) {
                              setQuantity({ [o?.id]: quantity[o?.id] - 1 });
                              setPrice(price - o?.price);
                              handleChange(
                                o?.title,
                                modifier,
                                setValue,
                                setPrev,
                                prev,
                                value,
                                { [o?.id]: quantity[o?.id] - 1 },
                                price - o?.price
                              );
                            }
                          }}
                        >
                          <RemoveCircleRoundedIcon
                            style={{
                              color:
                                quantity[o?.id] > o?.defaultQuantity
                                  ? 'green'
                                  : 'gray',
                            }}
                          />
                        </div>

                        <Typography
                          component="a"
                          variant="h6"
                          sx={{
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            display: 'flex',
                            fontWeight: 'bold',
                            marginTop: '3px',
                          }}
                        >
                          {quantity[o?.id]
                            ? quantity[o?.id]
                            : o?.defaultQuantity}
                        </Typography>
                        <div
                          style={{ padding: '5px 10px 5px' }}
                          onClick={() => {
                            if (
                              quantity[o?.id] < o?.maxPermitted &&
                              modifier?.maxPermitted > quantity[o?.id]
                            ) {
                              setQuantity({ [o?.id]: quantity[o?.id] + 1 });
                              setPrice(price + o?.price);
                              // setOptionsPrice(price + o?.price);
                              handleChange(
                                o?.title,
                                modifier,
                                setValue,
                                setPrev,
                                prev,
                                value,
                                { [o?.id]: quantity[o?.id] + 1 },
                                price + o?.price
                              );
                            }
                          }}
                        >
                          <AddCircleOutlinedIcon
                            style={{
                              color:
                                quantity[o?.id] < o?.maxPermitted &&
                                modifier?.maxPermitted > quantity[o?.id]
                                  ? 'green'
                                  : 'gray',
                            }}
                          />
                        </div>
                      </div>
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </RadioGroup>
        ))}
        {showChildoneP && (
          <Dialog
            maxWidth="xl"
            PaperProps={{ style: { maxHeight: '500px', maxWidth: '600px' } }}
            onClose={handleClosee}
            aria-labelledby="customized-dialog-title"
            open={openn}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            {modifiers.map((d, index) =>
              ChildID?.includes(d.id) ? (
                <div style={{ width: '30rem', height: 'auto' }}>
                  <DialogTitle>
                    {' '}
                    <p style={{ color: 'gray', fontSize: '17px' }}>{c?.name}</p>
                    <p style={{ fontSize: '18px' }}>{modName?.title}</p>
                  </DialogTitle>

                  <DialogContent
                    dividers
                    style={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <div>
                     <ChildItemModifier
                     d={d}
                     id={d?.id}
                     modifier={d}
                     handleChange={handleChange}
                     setOptionsPrice={setOptionsPrice}
                     zeros={zeros}
                     setZeros={setZeros}
                     modifiersGroup={modifiersGroup}
                     showResults={showResults}
                     setExtraQuantity={setExtraQuantity}
                     extraQuantity={extraQuantity}
                     optionsPrice={optionsPrice}
                     handleChooseModifires={handleChooseModifires}
                     checkedModifiers={checkedModifiers}
                     setCheckedModifiers={setCheckedModifiers}
                     checkedOptions={checkedOptions}
                     errReq={errReq}
                     checkSumAdd={checkSumAdd}
                     checkSumM={checkSumM}
                     setMaxErr={setMaxErr}
                     summErr={summErr}
                     modid={modid}
                     optionid={optionid}
                     setCalculatedPrice={setCalculatedPrice}
                     setShowChildP={setShowChildP}
                     handleChangeChild={handleChangeChild}
                     optionsPriceChild={optionsPriceChild}
                     setOptionsPriceChild={setOptionsPriceChild}
                     checkBoxchangeChild={checkBoxchangeChild}
                     />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        style={{
                          color: 'white',
                          background: environment?.mainColor,
                          borderRadius: '20px',
                          padding: '5px 30px 4px 30px',
                          width: '100%',
                        }}
                      >
                        Save{' '}
                      </Button>
                    </Grid>
                  </DialogActions>
                </div>
              ) : (
                ''
              )
            )}
          </Dialog>
        )}
      </FormControl>
    </div>
  );
}

export default OnePermitted;
