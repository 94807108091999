import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { environment } from '../../../environments/environment';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import GoogleMapReact from 'google-map-react';
import GoogleMap from './googlemaps';
import MailIcon from '@mui/icons-material/Mail';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import '../Menu/index.css';
import footer from '../../../assets/contactus.png';
import { Box } from '@mui/system';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { firestore } from '../../../../../../libs/firebase-service/src';
import CustomPhoneNumber from '../../components/PhoneNumber/PhoneInput';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { fetchData, handleToaster } from '../../Utils/UtilsFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { FaYelp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import PhoneInternational from '../../components/PhoneNumber/phoneinternational';
import { startsWithPlus } from '../CheckoutPage/utils';

function Contactus() {
  const [loading, setLoading] = useState(false);
  const [emailErr, setEmailErr] = useState('');
  const stores = useSelector((state) => state.shopReducer.stores);
  const [t, i18n] = useTranslation();

  const [time24, setTime24] = useState('');
  const [time24close, setTime24close] = useState('');

  const [time12open, setTime12open] = useState('');
  const [time12close, setTime12close] = useState('');
  const pattern = /^[a-zA-Z0-9]+@[a-zA-Z0-9.]+$/;

  useEffect(() => {
    setTime12open(convertTo12Hour(time24));
    setTime12close(convertTo12Hourclose(time24close));
  }, []);

  const convertTo12Hour = (time24) => {
    const [hours, minutes] = time24.split(':');
    let suffix = 'AM';
    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
      suffix = 'PM';
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    return `${hours12}:${minutes} ${suffix}`;
  };
  const convertTo12Hourclose = (time24close) => {
    const [hours, minutes] = time24close.split(':');
    let suffix = 'AM';
    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
      suffix = 'PM';
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    return `${hours12}:${minutes}${suffix}`;
  };

  useEffect(() => {
    stores[0]?.timing.map((c) =>
      c.slots.map(
        (b) => (setTime24(b.open_time), setTime24close(b?.close_time))
      )
    );
  }, []);
  const handleSubmit = async (values) => {
    setLoading(true);
    const updateDoc = await firestore.collection('restaurant').doc();
    const doc = await firestore.collection('contactus').doc();
    let phoneNumbert = values.phoneNumber;
 
    const re = /\S+@\S+\.\S+/;

    // setLoading(true);
    if (doc?.id.length > 0 && re.test(values?.email)) {
      setEmailErr('');
      await firestore
        .collection('contactus')
        .doc(doc?.id)
        .set({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: phoneNumbert,
          message: values.message,
          id: doc.id,
          createdAt: new Date(),
        })
        .then(async () => {
          values.firstName = '';
          values.lastName = '';
          values.email = '';
          values.message = '';
          values.phoneNumber = '';

          setLoading(false);
          // notify();
        })
        .catch((e) => {
          setLoading(false);
          // notify();
        });

      await firestore
        .collection('restaurant')
        .doc('Restaurant')
        .set(
          {
            update_items_id: updateDoc.id,
          },
          { merge: true }
        )

        .catch((e) => {
          console.log('err:', e);
        });

      await fetchData(
        `${environment?.apiUrl}/sendEmail?restaurantId=${environment.restaurantId}`,
        'post',
        {
          type: 'contactus',
          id: doc.id,
        }
      )
        .then((c) => {
          console.log('success');
          console.log(c);
          handleToaster('success', t('contactUs.sentSuccessfully'));
        })
        .catch((e) => {
          console.log('api error catch', t('errorTryAgain'));
          handleToaster('error', e);
        });
    } else {
      if (!re.test(values?.email)) {
        setEmailErr(`${t('contactUs.validName')}`);
      }
    }
  };

 




  const validationScheme = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .matches(
        /^[A-Za-z\s]+$/,
        t('careerss.nameShouldContainOnlyLettersAndSpaces')
      )
      .min(3, t('careerss.minCharMustEqual3'))
      .max(30, t('careerss.maxCharMustEqual30'))
      .required(`${t('contactUs.firstNameErr')}`),
    lastName: yup
      .string()
      .matches(
        /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        t(
          'contactUs.shouldnotContainNumbersSpecialcharactersOrSpacesAtBeginningOrEnd'
        )
      )
      .min(3, t('careerss.minCharMustEqual3'))
      .max(30, t('careerss.maxCharMustEqual30'))
      .required(`${t('contactUs.lastNaneErr')}`),
    message: yup
      .string()
      .matches(/^(?!\s*$).*/, t('careerss.shouldnotContainOnlySpaces'))
      .min(3, t('careerss.minCharMustEqual3'))
      .max(250, t('careerss.maxCharMustEqual250'))
      .required(`${t('contactUs.messageErr')}`),

    email: yup
      .string()
      .required(`${t('contactUs.emailErr')}`)
      .email(t('careerss.pleaseProvideValidEmail')),

    phoneNumber: yup
    .string()
    .required(`${t('careerss.phoneErr')}`)
    .test(
      'is-plus-one',
      `${t('validNumber')}`,
      function(value) {
        if (value && value.startsWith('+1')) {
          return startsWithPlus(value,'+1');
        }
        if (value && value.startsWith('+962')) {
          return startsWithPlus(value,'+962');

        }
        return true; 
      }
      )

  
  });



  return (
    <>
      {environment?.restaurantId === 'istanbulDk' ||
        (environment.restaurantId === 'taqwasbakery' && (
          <Helmet>
            <title>Taqwa's Bakery and Restaurant | Contact us</title>
          </Helmet>
        ))}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {environment.restaurantId === 'istanbulDk' ||
        (environment.restaurantId === 'taqwasbakery' && (
          <>
            <img
              alt="tesxt"
              height="90%"
              src={footer} // use normal <img> attributes as props
              width="100%"
            />
            <Container maxWidth={'lg'} style={{ marginTop: '20px' }}>
              <Grid
                container
                spacing={2}
                columns={18}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  marginTop: '30px',
                  marginBottom: '30px',
                }}
              >
                <Grid lg={6} xs={12}>
                  <Typography
                    variant="h3"
                    style={{
                      color: environment.mainColor,
                      fontFamily: `Hanalei Fill, cursive`,
                    }}
                  >
                    {t('contactUs.workingHours')}
                  </Typography>

                  <br></br>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {t('contactUs.Daily')}{' '}
                  </Typography>

                  {stores[0]?.timing.map((c) => (
                    <Typography
                      variant="body1"
                      style={{ color: 'gray', fontWeight: 'normal' }}
                    >
                      {c.slots.map((slot, slotIndex) => {
                        // Combine seconds and nanoseconds to get the timestamp in milliseconds
                        const timestamp =
                          slot.open_time.seconds * 1000 +
                          slot.open_time.nanoseconds / 1e6;
                        const openTime = new Date(timestamp);
                        const timestampclose =
                          slot.close_time.seconds * 1000 +
                          slot.close_time.nanoseconds / 1e6;
                        const close_time = new Date(timestampclose);

                        return (
                          <div key={slotIndex}>
                            {c.day}
                            {''}: {moment(openTime).format(' h:mm A')} -{' '}
                            {moment(close_time).format(' h:mm A')}
                          </div>
                        );
                      })}
                    </Typography>
                  ))}
                </Grid>
                {/* <Grid item lg={6} xs={12}>
                  <Typography
                    variant="h3"
                    style={{
                      color: environment.mainColor,
                      fontFamily: `Hanalei Fill, cursive`,
                    }}
                  >
                    {t('contactUs.ContactInfo')}{' '}
                  </Typography>
                  <br></br>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {t('contactUs.ourPhone')}{' '}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: 'gray',
                      fontWeight: 'normal',
                      direction: 'initial',
                    }}
                  >
                    <a
                      href={`tel:${environment.phoneNumber}`}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {environment.phoneNumber}
                    </a>
                  </Typography>
                  <br />
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {t('contactUs.ourLocation')}{' '}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: 'gray', fontWeight: 'normal' }}
                  >
                    <a
                      href={
                        environment?.restaurantId === 'ttJordanDev'
                          ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                          : environment?.restaurantId === 'istanbulDk'
                          ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                         : environment?.restaurantId === 'ttJordan'
                          ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                          : environment?.restaurantId === 'alyousef'
                          ? 'https://www.google.com/maps/place/Al-Yousef+Supermarket+%26+Restaurant/@42.9295555,-87.9399237,17z/data=!3m1!4b1!4m6!3m5!1s0x88051151327e486b:0x7c529f0a50e1089d!8m2!3d42.9295555!4d-87.9399237!16s%2Fg%2F11bw50pjkw?entry=ttu'
                          : 'https://www.google.com/maps/place/700+W+Wisconsin+Ave+%23A,+Milwaukee,+WI+53233,+EUA/@43.0389455,-87.9205169,17z/data=!3m1!4b1!4m6!3m5!1s0x8805199d8fc34fc9:0x19a0735cad76b699!8m2!3d43.0389455!4d-87.9205169!16s%2Fg%2F11qzdbhjb8'
                      }
                      target="_blank"
                    >
                      {environment.address}
                    </a>
                    </Typography>
                </Grid>{' '} */}
                    {/* {t('contactUs.locationW')}{' '} */}
              
                <Grid item lg={6} xs={12}>
                  <Typography
                    variant="h3"
                    style={{
                      color: environment.mainColor,
                      fontFamily: `Hanalei Fill, cursive`,
                    }}
                  >
                    {t('contactUs.stayInTouch')}
                  </Typography>
                  <br />
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {t('contactUs.SocialLinks')}{' '}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Grid item lg={1}>
                      <a href={environment.facebookLink} target="_blank">
                        <FacebookIcon style={{ color: 'gray' }} />
                      </a>
                    </Grid>
                    <Grid item lg={1}>
                      <a href={environment.instagramLink} target="_blank">
                        <InstagramIcon style={{ color: 'gray' }} />
                      </a>
                    </Grid>
                    <Grid item lg={1}>
                      <a href={environment.twitterLink} target="_blank">
                        <TwitterIcon style={{ color: 'gray' }} />
                      </a>
                    </Grid>
                    <Grid item lg={1} style={{ marginTop: '7px' }}>
                      <a href={environment.yelpLink} target="_blank">
                        <FaYelp style={{ color: 'gray' }} />
                      </a>
                    </Grid>
                  </Grid>
                  <br />
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {t('contactUs.emailAndSupport')}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ color: 'gray', fontWeight: 'normal' }}
                  >
                    <a
                      href={`mailto:${environment.email}`}
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                    >
                      {environment.email}
                    </a>
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container justify="center" xs={12}>
                <div style={{ height: '80vh', width: '100%' }}>
                  <GoogleMap />
                </div>
              </Grid>
            </Container>
          </>
        ))}

      {environment.restaurantId === 'ttJordanDev' && (
        <>
          <img
            alt="tesxt"
            height="90%"
            src={footer} // use normal <img> attributes as props
            width="100%"
          />
          <Container maxWidth={'lg'} style={{ marginTop: '20px' }}>
            <Grid
              container
              spacing={2}
              columns={18}
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              <Grid item lg={6} xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.workingHours')}
                </Typography>

                <br></br>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.Daily')}{' '}
                </Typography>

                {stores[0]?.timing.map((c,index) => (
                  <Typography
                  key={index}
                    variant="body1"
                    style={{ color: 'gray', fontWeight: 'normal' }}
                  >
                    {c.slots.map((slot, slotIndex) => {
                      // Combine seconds and nanoseconds to get the timestamp in milliseconds
                      const timestamp =
                        slot.open_time.seconds * 1000 +
                        slot.open_time.nanoseconds / 1e6;
                      const openTime = new Date(timestamp);
                      const timestampclose =
                        slot.close_time.seconds * 1000 +
                        slot.close_time.nanoseconds / 1e6;
                      const close_time = new Date(timestampclose);

                      return (
                        <div key={slotIndex}>
                          {c.day}
                          {''}: {moment(openTime).format(' h:mm A')} -{' '}
                          {moment(close_time).format(' h:mm A')}
                        </div>
                      );
                    })}
                  </Typography>
                ))}
              </Grid>
              {/* <Grid item lg={6} xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.ContactInfo')}{' '}
                </Typography>
                <br></br>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.ourPhone')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: 'gray',
                    fontWeight: 'normal',
                    direction: 'initial',
                  }}
                >
                  <a
                    href={`tel:${environment.phoneNumber}`}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {environment.phoneNumber}
                  </a>
                </Typography>
                <br />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.ourLocation')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: 'gray', fontWeight: 'normal' }}
                >
                  <a
                    href={
                      environment?.restaurantId === 'ttJordanDev'
                        ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                       :  environment?.restaurantId === 'ttJordan'
                        ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                        : environment?.restaurantId === 'istanbulDk'
                        ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                        : environment?.restaurantId === 'alyousef'
                        ? 'https://www.google.com/maps/place/Al-Yousef+Supermarket+%26+Restaurant/@42.9295555,-87.9399237,17z/data=!3m1!4b1!4m6!3m5!1s0x88051151327e486b:0x7c529f0a50e1089d!8m2!3d42.9295555!4d-87.9399237!16s%2Fg%2F11bw50pjkw?entry=ttu'
                        : 'https://www.google.com/maps/place/700+W+Wisconsin+Ave+%23A,+Milwaukee,+WI+53233,+EUA/@43.0389455,-87.9205169,17z/data=!3m1!4b1!4m6!3m5!1s0x8805199d8fc34fc9:0x19a0735cad76b699!8m2!3d43.0389455!4d-87.9205169!16s%2Fg%2F11qzdbhjb8'
                    }
                    target="_blank"
                  >
                    {environment.address}
                  </a>
                </Typography>
              </Grid>{' '} */}
              <Grid item lg={6} xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.stayInTouch')}
                </Typography>
                <br />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.SocialLinks')}{' '}
                </Typography>
                <Grid
                  container
                  direction="row"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Grid item lg={1}>
                    <a href={environment.facebookLink} target="_blank">
                      <FacebookIcon style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                  <Grid item lg={1}>
                    <a href={environment.instagramLink} target="_blank">
                      <InstagramIcon style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                  <Grid item lg={1}>
                    <a href={environment.twitterLink} target="_blank">
                      <TwitterIcon style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                  <Grid item lg={1} style={{ marginTop: '7px' }}>
                    <a href={environment.yelpLink} target="_blank">
                      <FaYelp style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                </Grid>
                <br />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.emailAndSupport')}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: 'gray', fontWeight: 'normal' }}
                >
                  <a
                    href={`mailto:${environment.email}`}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  >
                    {environment.email}
                  </a>
                </Typography>
              </Grid>
            </Grid>

            <Grid item container justify="center" xs={12}>
              <div style={{ height: '80vh', width: '100%' }}>
                <GoogleMap />
              </div>
            </Grid>
          </Container>
        </>
      )}
         {/* {environment.restaurantId === 'ttJordan' && (
        <>
          <img
            alt="tesxt"
            height="90%"
            src={footer} // use normal <img> attributes as props
            width="100%"
          />
          <Container maxWidth={'lg'} style={{ marginTop: '20px' }}>
            <Grid
              container
              spacing={2}
              columns={18}
              style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              <Grid item lg={6} xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.workingHours')}
                </Typography>

                <br></br>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.Daily')}{' '}
                </Typography>

                {stores[0]?.timing.map((c,index) => (
                  <Typography
                  key={index}
                    variant="body1"
                    style={{ color: 'gray', fontWeight: 'normal' }}
                  >
                    {c.slots.map((slot, slotIndex) => {
                      // Combine seconds and nanoseconds to get the timestamp in milliseconds
                      const timestamp =
                        slot.open_time.seconds * 1000 +
                        slot.open_time.nanoseconds / 1e6;
                      const openTime = new Date(timestamp);
                      const timestampclose =
                        slot.close_time.seconds * 1000 +
                        slot.close_time.nanoseconds / 1e6;
                      const close_time = new Date(timestampclose);

                      return (
                        <div key={slotIndex}>
                          {c.day}
                          {''}: {moment(openTime).format(' h:mm A')} -{' '}
                          {moment(close_time).format(' h:mm A')}
                        </div>
                      );
                    })}
                  </Typography>
                ))}
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.ContactInfo')}{' '}
                </Typography>
                <br></br>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.ourPhone')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    color: 'gray',
                    fontWeight: 'normal',
                    direction: 'initial',
                  }}
                >
                  <a
                    href={`tel:${environment.phoneNumber}`}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    {environment.phoneNumber}
                  </a>
                </Typography>
                <br />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.ourLocation')}{' '}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: 'gray', fontWeight: 'normal' }}
                >
                  <a
                    href={
                      environment?.restaurantId === 'ttJordanDev'
                        ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                       : environment?.restaurantId === 'ttJordan'
                        ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                        : environment?.restaurantId === 'istanbulDk'
                        ? 'https://www.google.com/maps/place/Taqwas+Bakery+and+Restaurant/@42.960375,-87.949907,1748m/data=!3m1!1e3!4m15!1m8!3m7!1s0x880511411c31a553:0x77dc00e4b73e5783!2sTaqwas+Bakery+and+Restaurant!8m2!3d42.9603751!4d-87.9499074!10e1!16s%2Fg%2F11jrkzlvs2!3m5!1s0x880511411c31a553:0x77dc00e4b73e5783!8m2!3d42.9603751!4d-87.9499074!16s%2Fg%2F11jrkzlvs2?hl=en'
                        : environment?.restaurantId === 'alyousef'
                        ? 'https://www.google.com/maps/place/Al-Yousef+Supermarket+%26+Restaurant/@42.9295555,-87.9399237,17z/data=!3m1!4b1!4m6!3m5!1s0x88051151327e486b:0x7c529f0a50e1089d!8m2!3d42.9295555!4d-87.9399237!16s%2Fg%2F11bw50pjkw?entry=ttu'
                        : 'https://www.google.com/maps/place/700+W+Wisconsin+Ave+%23A,+Milwaukee,+WI+53233,+EUA/@43.0389455,-87.9205169,17z/data=!3m1!4b1!4m6!3m5!1s0x8805199d8fc34fc9:0x19a0735cad76b699!8m2!3d43.0389455!4d-87.9205169!16s%2Fg%2F11qzdbhjb8'
                    }
                    target="_blank"
                  >
                    {environment.address}
                  </a>
                </Typography>
              </Grid>{' '}
              <Grid item lg={6} xs={12}>
                <Typography
                  variant="h3"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.stayInTouch')}
                </Typography>
                <br />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.SocialLinks')}{' '}
                </Typography>
                <Grid
                  container
                  direction="row"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Grid item lg={1}>
                    <a href={environment.facebookLink} target="_blank">
                      <FacebookIcon style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                  <Grid item lg={1}>
                    <a href={environment.instagramLink} target="_blank">
                      <InstagramIcon style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                  <Grid item lg={1}>
                    <a href={environment.twitterLink} target="_blank">
                      <TwitterIcon style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                  <Grid item lg={1} style={{ marginTop: '7px' }}>
                    <a href={environment.yelpLink} target="_blank">
                      <FaYelp style={{ color: 'gray' }} />
                    </a>
                  </Grid>
                </Grid>
                <br />
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {t('contactUs.emailAndSupport')}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: 'gray', fontWeight: 'normal' }}
                >
                  <a
                    href={`mailto:${environment.email}`}
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                  >
                    {environment.email}
                  </a>
                </Typography>
              </Grid>
            </Grid>

            <Grid item container justify="center" xs={12}>
              <div style={{ height: '80vh', width: '100%' }}>
                <GoogleMap />
              </div>
            </Grid>
          </Container>
        </>
      )} */}
      <Container maxWidth={'md'}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            message: '',
            email: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationScheme}
        >
          {({
            handleBlur,
            handleChange,
            values,
            errors,
            handleSubmit,
            touched,
          }) => (
            <div>
              <Divider />           
              <div style={{ height: '110px' }} />
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: environment.mainColor,
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('contactUs.getQuastion')}
                </Typography>
              </Grid>
              <br />
              <Grid
                item
                md={12}
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography
                  variant="h4"
                  style={{ color: 'black', fontWeight: 'bold' }}
                >
                  {t('contactUs.getInTouch')}
                </Typography>
              </Grid>
              <div style={{ height: '15px' }} />
              <Grid container spacing={3}>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={
                      i18n.language === 'ar' || i18n.language === 'he'
                        ? ''
                        : `${t('contactUs.firstName')}`
                    }
                    placeholder={`${t('contactUs.firstName')}`}
                    style={{ textAlign: 'right' }}
                    name="firstName"
                    multiline
                    required
                    onChange={handleChange('firstName')}
                    onBlur={handleBlur('firstName')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.firstName}
                    error={errors.firstName && touched.firstName}
                    variant="outlined"
                  />
                  {errors.firstName && touched.firstName ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.firstName}
                    </span>
                  ) : null}
                </Grid>
                <div style={{ height: '30px' }} />
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    helperText=""
                    required
                    label={`${t('contactUs.LastName')}`}
                    name="lastName"
                    multiline
                    onChange={handleChange('lastName')}
                    onBlur={handleBlur('lastName')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.lastName}
                    error={errors.lastName && touched.lastName}
                    variant="outlined"
                  />
                  {errors.lastName && touched.lastName ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.lastName}
                    </span>
                  ) : null}
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={
                      i18n.language === 'ar' || i18n.language === 'he'
                        ? ''
                        : `${t('contactUs.email')}`
                    }
                    placeholder={`${t('contactUs.email')}`}
                    name="email"
                    style={{ textAlign: 'right' }}
                    required
                    multiline
                    type="email"
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.email}
                    error={errors.email && touched.email}
                    variant="outlined"
                  />
                  {errors.email && touched.email ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.email}
                    </span>
                  ) : null}
                  {emailErr?.length > 0 && (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {emailErr}
                    </span>
                  )}
                </Grid>
                <Grid item md={6} xs={6}>
                
                  <PhoneInternational
                    phoneNumber={values.phoneNumber}
                    setPhoneNumber={(value) =>
                      handleChange({ target: { name: 'phoneNumber', value } })
                    }
                    backgroundColor="white"
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.phoneNumber}
                    </span>
                  )}
                </Grid>{' '}
                <div style={{ height: '30px' }} />
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    helperText=""
                    label={`${t('contactUs.message')}`}
                    name="message"
                    multiline
                    required
                    rows={4}
                    onChange={handleChange('message')}
                    onBlur={handleBlur('message')}
                    sx={{
                      '& label.Mui-focused': {
                        color: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray',
                        },
                      },
                    }}
                    value={values.message}
                    error={errors.message && touched.message}
                    variant="outlined"
                  />
                  {errors.message && touched.message ? (
                    <span
                      style={{
                        marginTop: '10px',
                        color: 'red',
                        fontSize: '10px',
                      }}
                    >
                      {errors.message}
                    </span>
                  ) : null}
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2,
                }}
                style={{ marginLeft: '20px' }}
              >
                <Button
                  style={{
                    color: 'white',
                    backgroundColor: !loading ? environment.mainColor : 'gray',
                    width: '150px',
                  }}
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={pattern.test(values.email) != true}
                >
                  {loading
                    ? `${t('contactUs.loading')}`
                    : `${t('contactUs.send')}`}
                </Button>
              </Box>
              <div style={{ height: '30px' }} />
            </div>
          )}
        </Formik>
      </Container>
    </>
  );
}
export default Contactus;
