import React, { useEffect, useState } from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { firestore } from '../../../../../../libs/firebase-service/src';
import ChangeItem from './ChangeItem';
import OnePermitted from './OnePermitted';
import ChildOfChild from './ChildOfChild';
import ChangeItemChild from './ChangeItemChild';


function ChildItemModifier({
  
  // handleChange,
  // modifiersGroup,
  // handleChooseModifires,
  // setExtraQuantity,
  // extraQuantity,
  // setOptionsPrice,
  // zeros,
  // setZeros,
  // optionsPrice,
  // showResults,
  // checkedModifiers,
  // setCheckedModifiers,
  // d,
  // data,
  // handleChangeChild,
  modifier,
  handleChange,
//   handleChangeChild,
  modifiersGroup,
  handleChooseModifires,
  setExtraQuantity,
  extraQuantity,
  zeros,
  setZeros,
  optionsPrice,
  showResults,
  checkedModifiers,
  setCheckedModifiers,
  childPriceOption,
  errReq,
  summErr,
  setOptionsPrice,
  checkSumAdd,
  checkSumM,
  checkedOptions,
  setMaxErr,
  d,
  data,
  modid,
  optionid,
  setCalculatedPrice,
  setShowChildP,
  handleChangeChild,
  optionsPriceChild,
  setOptionsPriceChild,
  checkBoxchangeChild
}) {
  return (
    <div>

  
        {d?.minPermitted === 1 && d?.maxPermittedUnique === 1 && 
        <>
          {' '}
          <div>
            <ChildOfChild
               d={d}
               id={d?.id}
               modifier={d}
               handleChange={handleChange}
               setOptionsPrice={setOptionsPrice}
               zeros={zeros}
               setZeros={setZeros}
               modifiersGroup={modifiersGroup}
               showResults={showResults}
               setExtraQuantity={setExtraQuantity}
               extraQuantity={extraQuantity}
               optionsPrice={optionsPrice}
               handleChooseModifires={handleChooseModifires}
               checkedModifiers={checkedModifiers}
               setCheckedModifiers={setCheckedModifiers}
               checkedOptions={checkedOptions}
               errReq={errReq}
               checkSumAdd={checkSumAdd}
               checkSumM={checkSumM}
               setMaxErr={setMaxErr}
               summErr={summErr}
               modid={modid}
               optionid={optionid}
               setCalculatedPrice={setCalculatedPrice}
               setShowChildP={setShowChildP}
               handleChangeChild={handleChangeChild}
            />
          </div>
        </>}
     { d?.minPermitted === 0 && d?.maxPermittedUnique !== 1 && (
        <ChangeItemChild
          id={d?.id}
          zeros={zeros}
          setZeros={setZeros}
          modifiersGroup={data}
          modifier={d}
          showResults={showResults}
          setExtraQuantity={setExtraQuantity}
          extraQuantity={extraQuantity}
          optionsPrice={optionsPrice}
          handleChooseModifires={handleChooseModifires}
          setOptionsPrice={setOptionsPrice}
          checkedModifiers={checkedModifiers}
          setCheckedModifiers={setCheckedModifiers}
          checkedOptions={checkedOptions}
          errReq={errReq}
          checkSumAdd={checkSumAdd}
          checkSumM={checkSumM}
          setMaxErr={setMaxErr}
          summErr={summErr}
          modid={modid}
          optionid={optionid}
          setShowChildP={setShowChildP}
          optionsPriceChild={optionsPriceChild}
          setOptionsPriceChild={setOptionsPriceChild}
          checkBoxchangeChild={checkBoxchangeChild}
        />
      )}
        {d?.minPermitted === 0 && d?.maxPermittedUnique === 1  && (
         <ChangeItemChild
          id={d?.id}
          zeros={zeros}
          setZeros={setZeros}
          modifiersGroup={data}
          modifier={d}
          showResults={showResults}
          setExtraQuantity={setExtraQuantity}
          extraQuantity={extraQuantity}
          optionsPrice={optionsPrice}
          handleChooseModifires={handleChooseModifires}
          setOptionsPrice={setOptionsPrice}
          checkedModifiers={checkedModifiers}
          setCheckedModifiers={setCheckedModifiers}
          checkedOptions={checkedOptions}
          errReq={errReq}
          checkSumAdd={checkSumAdd}
          checkSumM={checkSumM}
          setMaxErr={setMaxErr}
          summErr={summErr}
          modid={modid}
          optionid={optionid}
          setShowChildP={setShowChildP}
          optionsPriceChild={optionsPriceChild}
          setOptionsPriceChild={setOptionsPriceChild}
          checkBoxchangeChild={checkBoxchangeChild}
        />
      )}
        {d?.minPermitted > 0 && d?.maxPermittedUnique !== 1 && (
         <ChangeItemChild
          id={d?.id}
          zeros={zeros}
          setZeros={setZeros}
          modifiersGroup={data}
          modifier={d}
          showResults={showResults}
          setExtraQuantity={setExtraQuantity}
          extraQuantity={extraQuantity}
          optionsPrice={optionsPrice}
          handleChooseModifires={handleChooseModifires}
          setOptionsPrice={setOptionsPrice}
          checkedModifiers={checkedModifiers}
          setCheckedModifiers={setCheckedModifiers}
          checkedOptions={checkedOptions}
          errReq={errReq}
          checkSumAdd={checkSumAdd}
          checkSumM={checkSumM}
          setMaxErr={setMaxErr}
          summErr={summErr}
          modid={modid}
          optionid={optionid}
          setShowChildP={setShowChildP}
          optionsPriceChild={optionsPriceChild}
          setOptionsPriceChild={setOptionsPriceChild}
          checkBoxchangeChild={checkBoxchangeChild}

        />
      )}
    </div>
  );
}

export default ChildItemModifier;
