import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from '../src/app/components/local/en.json';
import translationAr from '../src/app/components/local/ar.json';
import translationCh from '../src/app/components/local/ch.json';
import translationEs from '../src/app/components/local/es.json';
import translationHe from '../src/app/components/local/he.json';

const resources = {
  en: { translation: translationEn },
  ar: { translation: translationAr },
  zh: { translation: translationCh },
  es: { translation: translationEs },
  he: { translation: translationHe },
};

const initializeI18n = (defaultLanguage = 'en') => {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      lng: defaultLanguage,
      fallbackLng: 'en',
      ns: ['translation'],
      interpolation: { escapeValue: false },
    });
};

export { initializeI18n };
export default i18n;
