import InputMask from 'react-input-mask';

const ExpiryInput = ({ field, form }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    const formattedValue = value.replace(/\D/g, '').substring(0, 4);
    const month = formattedValue.substring(0, 2);
    const year = formattedValue.substring(2, 4);

    // Restrict the year to not accept values greater than 23
    const restrictedYear = Math.max(parseInt(year, 10), 23);
    const formattedExpiry = month + (restrictedYear > 0 ? '/' + restrictedYear : '');
    form.setFieldValue(field.name, formattedExpiry);
  };

  return (
    <InputMask
      {...field}
      mask="99/99"
      maskPlaceholder="MM/YY"
      placeholder="Expiry Date"
      onChange={handleChange}
      style={{ width: '100%', height: '3.3rem', border: '1px solid lightgray', padding: '15px' }}
    />
  );
};

export default ExpiryInput;
