import React, { useState } from "react";
import { Typography, useTheme } from "@mui/material";

const ReadMore = ({ children, maxLength = 150, disableShowMore = false, showGradient = true }) => {
  const [readMore, setReadMore] = useState(false);
  const theme = useTheme();
  return (
    <span>
      { !readMore ?
      <span>
              {children?.toString().substr(0, maxLength)}
              <Typography variant={"body2"} component={"span"} style={{ position: "relative" }}>
                <span style={{ background: "none", position: "absolute", left: 0, top: 0, transform: "translateX(-100%)", height: "20px", width: "100px" }} />
                {!disableShowMore && (
                  <span
                    // variant={"secondary"}
                    onClick={() => {
                      setReadMore((prevState) => !prevState);
                    }}
                    style={{ color: 'black',cursor:"pointer" }}
                  >
                    {!showGradient ? "...more" : "more"}
                  </span>
                )}
              </Typography>
            </span> : 
            <span>
            
            <Typography variant={"body2"} component={"span"} style={{ position: "relative" }}>
              {children}
            </Typography>
          </span>
            }
    </span>
  );
};

export default ReadMore;
