// import {
//   Box,
//   Container,
//   InputAdornment,
//   Paper,
//   TextField,
//   Typography,
// } from '@mui/material';
// import RestaurantCard from '../../components/Card/RestaurantCard';
// import { useEffect, useState } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
// import algoliasearch from 'algoliasearch';
// import { firestore } from '../../../../../../libs/firebase-service/src';

// function Restaurants() {
 
//   return (
//     <>
//       <div style={{ height: '110px' }} />
//       <Container
//         maxWidth="lg"
//         style={{
//           border: '2px solid #ccc',
//           padding: '20px',
//           boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
//         }}
//       >
//         {' '}
//         <Paper
//           elevation={0}
//           style={{
//             backgroundColor: '#f5f5f5',
//             padding: '10px',
//             marginBottom: '20px',
//           }}
//         >
//           <Typography
//             variant="h5"
//             align="left"
//             fontWeight="bold"
//             sx={{ fontFamily: 'DMSans, GEDinarOne' }}
//           >
//             All Restaurants
//           </Typography>
//         </Paper>
//         <Box display="flex" justifyContent="flex-end" alignItems="center">
//           <Box p={1} borderRadius={8}>
//             <TextField
//               size="small"
//               variant="outlined"
//               placeholder="Search Restaurant"
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <SearchIcon />
//                   </InputAdornment>
//                 ),
//               }}
//             />
//           </Box>
//         </Box>
//         {currentLocation?.lat
//           ? stores?.map((store) => <RestaurantCard store={store} />)
//           : storesFirebase?.map((store) => <RestaurantCard store={store} />)}
//       </Container>
//     </>
//   );
// }

// export default Restaurants;
