import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import SearchAddress from './SearchAddress';
import { CustomButton } from '@tabletuck/components';
import { Formik } from 'formik';
import { Grid, TextField } from '@mui/material';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import AddressPickerWithGoogle from './GoogleAutoComplete';
import { useEffect, useState } from 'react';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userAddresses } from '../../../../../../libs/redux-slices/src';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
const AddressHandlerDialog = ({ open, handleClose, setAdressvalue }) => {
  const [googleAddress, setGoogleAddress] = useState('');
  const user = useSelector((state) => state.authReducer.userData);
  const adresses = useSelector((state) => state.authReducer.userAddresses);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const [zz, setZipCode] = useState('');
  const [err, setErr] = useState();

  const getGoogleAddress = ({
    country,
    state,
    city,
    zipCode,
    placeId,
    displayName,
    lat,
    lng,
    streetNumber,
    route,
  }) => {
    setGoogleAddress({
      country,
      state,
      city,
      zipCode,
      placeId,
      displayName,
      lat,
      lng,
      streetNumber,
      route,
    });
  };
  const handleSubmit = async (values) => {
    setZipCode(values.zipCode);
    setErr('');
    setLoading(true);
    

    if (Object.values(googleAddress)?.length > 0) {
    googleAddress['zipCode'] = googleAddress.zipCode
      ? googleAddress.zipCode
      : values.zipCode;

    if (!(googleAddress || googleAddress?.country || googleAddress?.city))
      return setErr(t('profilee.pleaseEnterValidAddrees'));
      if (!(googleAddress?.country)) {
        return setErr(t('profilee.pleaseEnterValidAddrees'));
      }
      console.log("google address in add",googleAddress)
    if (googleAddress.zipCode != '' ? googleAddress.zipCode : values.zipCode) {
      const doc = await firestore
        .collection('users')
        .doc(user?.uid)
        .collection('addresses')
        .doc();
      const updateDoc = await firestore.collection('restaurant').doc();
      await firestore
        .collection('users')
        .doc(user?.uid)
        .collection('addresses')
        .doc(doc.id)
        .set({
          ...googleAddress,
          additionalDetails: values.additionalDetails,
          nickName: values.nickName,
          id: doc.id,
          deliveryInstructions: values.deliveryInstructions,
          createdAt: new Date(),
          validated_on: new Date(),
          primaryLine: googleAddress.displayName,
          is_deliverable: true,
          delivery_store_id: null,
        })
        .then(() => {
          // setKey(Date.now());
          values.additionalDetails = '';
          setGoogleAddress('');
          const newObject = {
            ...googleAddress,
            additionalDetails: values.additionalDetails,
            nickName: values.nickName,
            id: doc.id,
            deliveryInstructions: values.deliveryInstructions,
            createdAt: new Date(),
            validated_on: new Date(),
            primaryLine: googleAddress.displayName,
            is_deliverable: true,
            delivery_store_id: null,
          };
          let update = [];
          if (adresses == null) {
            update = [newObject];
          } else update = [...adresses, newObject];

          dispatch(userAddresses(update));
          console.log(update, 'update');
          handleClose();
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      await firestore
        .collection('restaurant')
        .doc('Restaurant')
        .set(
          {
            update_items_id: updateDoc.id,
          },
          { merge: true }
        )
        .then(() => {
          console.log('updated');
          handleClose();
        })
        .catch((e) => {
          console.log('err:', e);
        });
    } else setErr(t('profilee.pleaseAddZipcode'));
  } else {
    setErr(t('profilee.pleaseAddValidAddress'));
  }

  };
  const [t, i18n] = useTranslation()
  const validationSchema = yup.object().shape({
    nickName: yup.string().required(t('profilee.addressNameRequired'))
    .test('no-leading-trailing-spaces', t('profilee.addressNameCannotHaveLeadingTrailingSpaces'), (value) => {
      if (value) {
        const trimmedValue = value.trim();
        return value === trimmedValue;
      }
      return true;
    })
    .test('no-multiple-spaces',t('profilee.addressNameCannotHaveMultipleConsecutiveSpaces'), (value) => {
      if (value) {
        return !/\s{2,}/.test(value);
      }
      return true;
    }),
    zipCode: yup
    .string()
    .trim()
    .matches(/^\d{5}(-\d{4})?$/, t('profilee.Zipcodemustdigits'))
    .required(t('profilee.Ziprequired'))
  });
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div
          style={{
            backgroundColor: environment.mainColor,
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          {' '}
          <CancelRoundedIcon
            style={{
              color: 'white',
              cursor: 'pointer',
              marginLeft: '15px',
            }}
            onClick={handleClose}
          />
        </div>

        <DialogTitle
          style={{
            color: 'white',
            backgroundColor: environment.mainColor,
            display: 'grid',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {t('profilee.addressDelivery')}
          </div>
        </DialogTitle>

        <DialogContent dividers>
          {/* <Typography
            component="p"
            variant="h5"
            style={{
              fontWeight: 'bold',
              marginBottom: '5px',
              color: environment.mainColor,
            }}
          >
            We Deliver There.
          </Typography>
          <Typography
            component="p"
            variant="body1"
            style={{ marginBottom: '20px' }}
          >
            2266 North Prospect Avenue, Milwaukee, WI, United States
          </Typography> */}
          <Typography
            component="p"
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            {t('profilee.addressDetails')}
          </Typography>
          <Formik
            initialValues={{
              additionalDetails: '',
              deliveryInstructions: '',
              nickName: '',
              zipCode: '',
              address: {
                additionalDetails: '',
                deliveryInstructions: '',
              },
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleBlur,
              handleChange,
              values,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
            }) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <AddressPickerWithGoogle
                      getValues={getGoogleAddress}
                      style={{ cursor: 'pointer !important' }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="zipcode" style={{transform: 'translate(0, -1.5px) scale(1)'}}>
                      {t('profilee.zipCode')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      helperText=""
                      // label="Zip Code"
                      placeholder={t('profilee.zipCode')}
                      name="zipCode"
                      required
                      onChange={handleChange('zipCode')}
                      onBlur={handleBlur('zipCode')}
                      value={
                        values.zipCode ? values.zipCode : googleAddress.zipCode
                      }
                      onClick={() => setZipCode(values.zipCode)}
                      variant="outlined"
                      id="zipcode" // Add an id to associate with the InputLabel
                      sx={{
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'gray',
                          },
                        },
                      }}
                    />
                    {errors.zipCode && touched.zipCode ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.zipCode}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="additionalDetails" style={{transform: 'translate(0, -1.5px) scale(1)'}}>
                      {t('profilee.additionalDetails')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      helperText=""
                      label={''}
                      placeholder={t('profilee.additionalDetails')}
                      name="additionalDetails"
                      onChange={handleChange('additionalDetails')}
                      onBlur={handleBlur('additionalDetails')}
                      value={values.additionalDetails}
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'gray',
                          },
                        },
                      }}
                    />
                    {errors.additionalDetails && touched.additionalDetails ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.additionalDetails}
                      </span>
                    ) : null}
                  </Grid>
                  <div style={{ height: '30px' }} />

                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="nickName" style={{transform: 'translate(0, -1.5px) scale(1)'}}>
                      {t('profilee.addressName')}
                    </InputLabel>
                    <TextField
                      style={{
                        background: 'rgb(242, 240, 240)',
                        // marginTop: '20px',
                      }}
                      fullWidth
                      // label={`${t('profilee.enterAddressName')}`}
                      placeholder={`${t('profilee.enterAddressName')}`}
                      name="nickName"
                      // onChange={handleChange('type')}
                      // onBlur={handleBlur('type')}
                      required
                      value={values.nickName}
                      onChange={handleChange('nickName')}
                      onBlur={handleBlur('nickName')}
                      variant="outlined"
                      sx={{
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'gray',
                          },
                        },
                      }}
                    />
                    {errors.nickName && touched.nickName ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.nickName}
                      </span>
                    ) : null}
                    <Grid item lg={12} style={{ marginTop: '10px' }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#F2F0F0',
                          color: 'black',
                          marginRight: '10px',
                        }}
                        onClick={() => setFieldValue('nickName', 'Home')}
                      >
                        {t('profilee.home')}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#F2F0F0',
                          color: 'black',
                          marginRight: '10px',
                        }}
                        onClick={() => setFieldValue('nickName', 'Work')}
                      >
                        {t('profilee.work')}
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: '#F2F0F0',
                          color: 'black',
                          marginRight: '10px',
                        }}
                        onClick={() => setFieldValue('nickName', 'University')}
                      >
                        {t('profilee.university')}
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="deliveryInstructions" style={{transform: 'translate(0, -1.5px) scale(1)'}}>
                      {t('profilee.deliveryInstructions')}
                    </InputLabel>
                    <TextField
                      placeholder={`${t('profilee.deliveryInstructions')}`}
                      fullWidth
                      helperText=""
                      variant="outlined"
                      // label={'Delivery Instructions'}
                      name="deliveryInstructions"
                      onChange={handleChange('deliveryInstructions')}
                      onBlur={handleBlur('deliveryInstructions')}
                      sx={{
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'gray',
                          },
                        },
                      }}
                      value={values.deliveryInstructions}
                    />
                    {!errors.deliveryInstructions &&
                    touched.deliveryInstructions ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {errors.deliveryInstructions}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid
                    lg={12}
                    style={{
                      display: 'flex',
                      position: 'relative',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '50%',
                        alignSelf: 'center',
                        position: 'relative',
                        // left:'10',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Typography
                        style={{
                          color: 'red',
                          fontWeight: 'bold',
                          fontSize: 15,
                          margin: 5,
                        }}
                      >
                        {err}
                      </Typography>
                      <CustomButton
                        bg={environment.mainColor}
                        loading={loading}
                        buttonText={t('profilee.saveAddress')}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </Formik>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'center' }}
        ></DialogActions>
      </Dialog>
    </div>
  );
};

export default AddressHandlerDialog;
