import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useNavigate } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
// import ttJordenLogo from '../../../assets/ttJordenLogo.png';
import ttJordenLogo from '../../../assets/Logoinnavitem.png';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../../../libs/redux-slices/src';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
const links = [
  {
    title: 'restaurants',
    navigate: '/restaurants',
  },
  {
    title: 'careers',
    navigate: '/careers',
  },
  {
    title: 'contactUs',
    navigate: '/contactus',
  },
  {
    title: 'PrivacyPolicy',
    navigate: '/privacy-policy',
  },
  // {
  //   title: 'signIn',
  //   navigate: '/signup',
  // },
];
const TTJMainFooter = () => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const user = useSelector((state) => state.authReducer.userData);
  const dispatch = useDispatch();
 
  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'ar':
        return licenses?.licenses.lng?.ar;
      case 'en':
        return licenses?.licenses.lng?.en;
      case 'es':
        return licenses?.licenses.lng?.es;
      case 'he':
        return licenses?.licenses.lng?.he;
      case 'zh':
        return licenses?.licenses.lng?.zh;

      default:
        return false;
    }
  };

  const ChangeDefaultlanguage = async (language) => {
    try {
      await firestore

        .collection('users')
        .doc(auth?.currentUser?.uid)
        .set(
          {
            defaultLanguage: language,
          },
          { merge: true }
        )
        .then(() => {
          const updatedUser = { ...user, defaultLanguage: language };
          dispatch(setUser({ userData: updatedUser }));
          i18n.changeLanguage(language);

        });
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <div style={{ backgroundColor: environment.footerColor }}>
      <Container
        maxWidth={'lg'}
        style={{
          paddingTop: '30px',
          paddingBottom: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <img
          alt="ttJordenLogo"
          src={ttJordenLogo}
          style={{
            display: 'flex',

            width: '7rem',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: '30px 0',
            justifyContent: 'center',
          }}
        >
          {links.map((item, index) => (
            <Typography
              key={index}
              gutterBottom
              variant="body1"
              component="div"
              style={{
                fontFamily: 'Poppins',
                color: 'white',
                width: 'fit-content',
                paddingLeft: '20px',
                paddingRight: '20px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(item.navigate);
              }}
            >
              {t(`footer.${item.title}`)}
            </Typography>
          ))}
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '53px',
            borderBottom: '0.8px solid #3B3B3B',
            width: '228px',
          }}
        >
          <a href={environment.facebookLink} target="_blank">
            <FacebookSharpIcon style={{ color: environment.mainColor }} />
          </a>
          <a href={environment.twitterLink} target="_blank">
            <TwitterIcon style={{ color: environment.mainColor }} />
          </a>
          <a href={environment.instagramLink} target="_blank">
            <InstagramIcon style={{ color: environment.mainColor }} />
          </a>
        </div> */}
        <Grid
          item
          lg={5}
          xl={3}
          md={12}
          sm={12}
          style={{ marginTop: '20px', marginBottom: '10px' }}
          display="flex"
          gap={5}
        >
          {checkLicenses('ar') && i18n.language === 'en' && (
            <Typography
              variant="body1"
              style={{
                color: 'white',
                opacity: '0.8',
                cursor: 'pointer',
              }}
              onClick={() => ChangeDefaultlanguage('ar')}
              >
              العربية
            </Typography>
          )}

          {checkLicenses('en') && i18n.language === 'ar' && (
            <Typography
              variant="body1"
              style={{
                color: 'white',
                opacity: '0.8',
                cursor: 'pointer',
              }}
              onClick={() => ChangeDefaultlanguage('en')}

            >
              English
            </Typography>
          )}
          {checkLicenses('es') && (
            <Typography
              variant="body1"
              style={{
                color: 'white',
                opacity: '0.8',
                cursor: 'pointer',
              }}
              onClick={() => {
                i18n.changeLanguage('en');
              }}
            >
              {/* EN */}
              English (US)
            </Typography>
          )}
          {checkLicenses('zh') && (
            <Typography
              variant="body1"
              style={{
                color: 'white',
                opacity: '0.8',
                cursor: 'pointer',
              }}
              onClick={() => {
                i18n.changeLanguage('zh');
              }}
            >
              {/* CH */}
              中文
            </Typography>
          )}
          {checkLicenses('es') && (
            <Typography
              variant="body1"
              style={{
                color: 'white',
                opacity: '0.8',
                cursor: 'pointer',
              }}
              onClick={() => {
                i18n.changeLanguage('es');
              }}
            >
              {/* ES */}
              Español
            </Typography>
          )}
          {checkLicenses('he') && (
            <Typography
              variant="body1"
              style={{
                color: 'white',
                opacity: '0.8',
                cursor: 'pointer',
              }}
              onClick={() => {
                i18n.changeLanguage('he');
              }}
            >
              {/* HE */}
              עִברִית
            </Typography>
          )}
        </Grid>
        <Typography
          variant={'body2'}
          style={{
            fontWeight: 'Segoe UI',
            color: '#92989F',
            textAlign: 'center',
            fontSize: '0.75rem',
            lineHeight: '1rem',
            letterSpacing: '0.016rem',
            marginTop: '12px',
          }}
        >
          {t('footer.tableTuckAllRightsReserved')}
          {/* Copyright ©{environment?.headerTitle} All Rights Reserved. */}
        </Typography>
      </Container>
    </div>
  );
};

export default TTJMainFooter;
