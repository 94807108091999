import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Container,
  useTheme,
  CardContent,
  CardMedia,
  Card,
  Hidden,
  Divider,
  Dialog,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { firestore } from '@tabletuck/firebase-services';
import { useNavigate, useParams } from 'react-router-dom';
import banner from '../../../assets/banner.png';
import MenuTTdemo from '../../../assets/MenuTTdemo.png';
import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import KeyboardDoubleArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowLeftTwoTone';
import { FiMinusSquare } from 'react-icons/fi';
import { Box } from '@mui/system';
import { Link } from 'react-scroll';
import './index.css';
import { useSelector } from 'react-redux';
import CustomizeDialoge from '../../components/CustomizeDialoge/CustomizeDialoge';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function MenuItems() {
  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [cat, setCat] = useState([]);
  const [custm, setcustom] = useState('');
  const [extraquantity, setExtraquantity] = useState({});
  const [extraQuantity, setExtraQuantity] = useState({});
  const [defs, setDefs] = useState([]);
  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const [data, setData] = React.useState([]);
  const [openn, setOpenn] = useState(false);
  const params = useParams();
  const [enableAdd, setEnableAdd] = useState(false);
  const menuDocs = useSelector((state) => state.authReducer.menuDocs);

console.log(menuDocs,"menuDocs")
  const menues = useSelector((state) => state.authReducer.menues);
  console.log("menues in menu item",menues);

  const activeMenu = useSelector((state) => state.authReducer.activeMenu);
  const [type, setType] = useState(activeMenu);
  const allactiveMenues = useSelector(
    (state) => state.authReducer.allactiveMenues
  );
  const activeMenusData = menues?.filter((menu) =>
  allactiveMenues?.includes(menu.id)
  );
  const inactiveMenusData = menues?.filter(
    (menu) => !allactiveMenues?.includes(menu.id)
    );
    const mergedArray = [...activeMenusData, ...inactiveMenusData];
    const navRef = useRef(null);
    console.log(allactiveMenues,"allactiveMenues");

  const handleNav = (direction) => {
    if (navRef.current) {
      if (direction === 'left') {
        navRef.current.scrollLeft -= 200;
      } else {
        navRef.current.scrollLeft += 200;
      }
    }
  };

  const handleOpenn = () => {
    setId(id);
    setOpenn(true);
    setItem(item);
  };

  const handleClosee = () => {
    setOpenn(false);
  };
  const items = useSelector((state) => state.shopReducer.currentItem);
  const [option, setOption] = useState(null);

  const categories = useSelector(
    (state) => state.shopReducer.currentCategories
  );
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [item, setItem] = useState(null);
  const [optionsPrice1, setOptionsPrice1] = useState(0);
  const [optionsPrice, setOptionsPrice] = useState(0);
  const user = useSelector((state) => state.authReducer.userData);
  const userAddresses = useSelector((state) => state.authReducer.userAddresses);
  const [storeId, setStoreId] = useState('');
  const [outofStockDelivery, setOutOfStockDelivery] = useState('');
  const stores = useSelector((state) => state.shopReducer.stores);
  const getStoreDelivery = async () => {
    if (user?.uid && user?.defaultDeliveryAddress != '')
      userAddresses?.map(async (a) => {
        if (a?.id === user?.defaultDeliveryAddress) {
          setStoreId(a?.delivery_store_id);
          stores?.filter((store) => {
            if (store?.id === a?.delivery_store_id) {
              setOutOfStockDelivery(store?.out_of_stock);
            }
          });
        }
      });
  };
  useEffect(() => {
    getStoreDelivery();
  }, [outofStockDelivery]);
  const getDefaults = async () => {
    try {
      setcustom('');
      setExtraquantity({});
      setDefs([]);
      // let modifiersRef = firestore.collection('modifier_groups');
      // const modifiersList = await modifiersRef.get();
      const item = items.filter((item) => item.id === id)[0];
      const mods = modifiers?.filter((mod) =>
        item.modifier_group_ids?.includes(mod?.id)
      );

      const perimits = mods?.filter((opt) => opt?.minPermitted != 0);
      if (perimits) {
        let p = 0;
        let o = 0;
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setOption(opt);
              setExtraquantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt?.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              if (
                perimite?.minPermitted == 1 &&
                perimite?.maxPermittedUnique == 1
              ) {
                p = p + perimite?.options?.find((o) => o.isDefault)?.price;
              }
              if (
                perimite?.minPermitted > 0 &&
                perimite?.maxPermittedUnique != 1
              ) {
                o = o + opt?.price;
              }
              setcustom((c) => (c ? c + `, ${opt.title}` : opt.title));
              setDefs((defs) =>
                defs.length
                  ? [...defs, [perimite?.title, opt.title]]
                  : [[perimite?.title, opt.title]]
              );
            }
          }
        }
        setOptionsPrice1(p > 0 ? p : 0);
        setOptionsPrice(o > 0 ? o : 0);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (items?.length) {
      getDefaults();
    }
  }, [id, items?.length]);
  const getModifiers = async () => {
    setData(modifiers);
  };

  useEffect(() => {
    getModifiers();
  }, []);
  const handleOpen = (id, item) => {
    setId(id);
    setOpen(true);
    setItem(item);
  };
  useEffect(() => {
    (async () => {
      if (params.id == null) {
        setOpen(false);
      } else {
        let item = items?.find((item) => item?.id === params?.id);
        handleOpen(item?.id, item);
      }
    })();
  }, [params.id]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = async (value) => {
    setType(value);
  };
  return (
    <>
      {environment?.restaurantId === 'istanbulDk' && (
        <Helmet>
          <title>Taqwa's Bakery and Restaurant | Menu</title>
        </Helmet>
      )}

      {environment?.restaurantId === 'ttJordanDev' && (
        <Helmet>
          <title>Taqwa's Bakery and Restaurant | Menu</title>
        </Helmet>
      )}
         {environment?.restaurantId === 'ttJordan' && (
        <Helmet>
          <title>Filfil | Menu</title>
        </Helmet>
      )}
      {environment.restaurantId === 'ttDemo' ? (
        <img
          alt="tesxt"
          loading="lazy"
          height="100%"
          src={MenuTTdemo}
          width="100%"
        />
      ) : (
        <img
          alt="tesxt"
          loading="lazy"
          height="100%"
          src={banner}
          width="100%"
        />
      )}

      <Container maxWidth={'lg'}>
        <Grid
          container
          style={{
            display: smDown ? 'unset' : 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant={'h4'}
            style={{
              marginTop: '30px',
              fontFamily: 'Abel',
              marginBottom: '20px',
            }}
          >
            {t('ourMenus')}
          </Typography>
        </Grid>
      </Container>
      <div className="menu-container">
        <div>
          {menues?.length > 5 ? (
            <button onClick={() => handleNav('left')}>
              <p className="fa fa-lg fa-chevron-left">
                <KeyboardDoubleArrowLeftTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
        </div>
        <div
          className="nav-items"
          ref={navRef}
          style={{
            width: '100%',
            display: smDown ? 'unset' : 'flex',
            justifyContent: 'center',
          }}
        >
          {mergedArray?.map((c) => (
            <Grid
              item
              lg={4}
              style={{
                display: 'inline-block',
                color: 'white',
                textAalign: 'center',
                padding: '14px',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              {allactiveMenues?.includes(c.id) ? (
                <Card
                  style={{
                    border: '2px solid lightgray',
                    borderRadius: '20px',
                    borderColor: type === c.id ? environment.mainColor : 'gray',
                    width: smDown ? '120px' : '180px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleChangeType(c.id);
                    if (allactiveMenues?.includes(c.id)) {
                      setEnableAdd(false);
                    } else setEnableAdd(true);
                  }}
                >
                  <CardContent
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={c.image}
                      alt={c.image}
                      style={{ width: '60px', height: '60px' }}
                    />
                    {c.name
                      .split(' ')
                      .filter((_, index) => index % 2 === 0)
                      .map((word, index) => (
                        <Typography
                          key={index}
                          variant="h6"
                          style={{
                            fontWeight: 'bold',
                            color: allactiveMenues.includes(c.id)
                              ? 'black'
                              : '#96999C',
                          }}
                        >
                          {`${word} ${c.name.split(' ')[index * 2 + 1] || ''}`}{' '}
                        </Typography>
                      ))}
                  </CardContent>
                </Card>
              ) : (
                ''
              )}{' '}
            </Grid>
          ))}
          {/* // ========   ============ */}
          {menues?.map((c) => (
            <Grid
              item
              lg={4}
              style={{
                display: 'inline-block',
                color: 'white',
                textAalign: 'center',
                padding: '14px',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              {!allactiveMenues?.includes(c.id) ? (
                <Card
                  style={{
                    border: '2px solid lightgray',
                    borderRadius: '20px',
                    borderColor: type === c.id ? environment.mainColor : 'gray',
                    width: smDown ? '120px' : '180px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleChangeType(c?.id);
                    if (allactiveMenues?.includes(c?.id)) {
                      setEnableAdd(false);
                    } else setEnableAdd(true);
                  }}
                >
                  <CardContent
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={c.image}
                      alt={c.image}
                      style={{ width: '60px', height: '60px' }}
                    />
                    {c.name
                      .split(' ')
                      .filter((_, index) => index % 2 === 0)
                      .map((word, index) => (
                        <Typography
                          key={index}
                          variant="h6"
                          style={{
                            fontWeight: 'bold',
                            color: allactiveMenues.includes(c.id)
                              ? 'black'
                              : '#96999C',
                          }}
                        >
                          {`${word} ${c.name.split(' ')[index * 2 + 1] || ''}`}{' '}
                        </Typography>
                      ))}
                  </CardContent>
                </Card>
              ) : (
                ''
              )}{' '}
            </Grid>
          ))}
          {/* // ========   ============ */}
        </div>
        <div>
          {menues?.length > 5 ? (
            <button onClick={() => handleNav('right')}>
              <p className="fa fa-lg fa-chevron-right">
                <KeyboardDoubleArrowRightTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* ---------------------- */}
      {menues?.map(
        (c) =>
          c.id === type && (
            <Container maxWidth={'lg'}>
              <Grid
                container
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Typography
                  variant={'h4'}
                  style={{ marginTop: '30px', fontFamily: 'Abel' }}
                >
                  {c.name}
                </Typography>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '60%',
                    }}
                  >
                    <span
                      className="dot"
                      style={{ backgroundColor: environment.mainColor }}
                    ></span>

                    <Divider
                      style={{
                        marginBottom: '5px',
                        marginTop: '5px',
                        height: '2px',
                        backgroundColor: environment.mainColor,
                        width: '30%',
                        marginLeft: '8px',
                        marginRight: '8px',
                      }}
                    />
                    <span
                      className="dot"
                      style={{ backgroundColor: environment.mainColor }}
                    ></span>
                  </div>
                </div>
              </Grid>
              <div
                className="horizantal_slider"
                style={{
                  marginTop: '8px',
                }}
              >
                <div className="slider_container">
                  {c.categories?.length > 0 &&
                    c.categories
                      ?.filter(
                        (category) =>
                          category.enable_items === true ||
                          category.id === 'Popular'
                      )
                      ?.map((category, index) => (
                        <div className="item" key={index}>
                          <Typography
                            style={{
                              marginTop: '15px',
                              marginBottom: '15px',
                              fontSize: '20px',
                            }}
                          >
                            <Link
                              activeClass="active"
                              spy
                              to={category.id}
                              smooth={true}
                              offset={-70}
                              duration={500}
                            >
                              {category.name}{' '}
                              <span style={{ marginLeft: '20px' }}></span>
                            </Link>
                          </Typography>
                        </div>
                      ))}
                </div>
              </div>
              {c.categories?.length > 0 &&
                c.categories
                  ?.filter(
                    (category) =>
                      category.enable_items === true ||
                      category.id === 'Popular'
                  )
                  .map((category, index) => (
                    <div id={category?.id} key={index}>
                      {
                        <>
                          {' '}
                          <Typography
                            variant={'h5'}
                            style={{ marginTop: '30px' }}
                          >
                            {category.name.toUpperCase()}
                          </Typography>
                          <Typography
                            variant={'body1'}
                            style={{ marginTop: '0px', color: 'gray' }}
                          >
                            {category.description}
                          </Typography>
                          <br />
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            sx={{
                              display: { sm: 'block', lg: 'flex' },
                            }}
                          >
                            {category.items?.length > 0 &&
                              category.items?.map(
                                (item, index) =>
                                  // item.parent_menu_id === category.id &&
                                  item.item_state === 'available' && (
                                    <>
                                      <Hidden smDown>
                                        <Grid item lg={6}>
                                          <div
                                            className="menu-item-card"
                                            onClick={() =>
                                              handleOpen(item?.id, item)
                                            }
                                          >
                                            <div className="menu-item-details">
                                              <Grid item lg={8} xl={8}>
                                                <Typography
                                                  variant={'body1'}
                                                  style={{
                                                    fontWeight: 'bold',
                                                    color: '#833720',
                                                    display: 'flex',
                                                  }}
                                                >
                                                  {' '}
                                                  {item.isCatering ? (
                                                    <>
                                                      <FiMinusSquare
                                                        style={{
                                                          marginTop: '4px',
                                                          marginRight: '3px',
                                                        }}
                                                      />
                                                      {'Catering'}
                                                    </>
                                                  ) : (
                                                    ''
                                                  )}
                                                </Typography>
                                              </Grid>
                                              <h6 className="menu-item-title">
                                                {item?.name}
                                              </h6>
                                              <p className="menu-item-description">
                                                {item?.description.length > 99
                                                  ? item?.description.slice(
                                                      0,
                                                      99
                                                    )
                                                  : item?.description}
                                                {item?.description.length > 99
                                                  ? '...'
                                                  : ''}
                                              </p>
                                              {/* <p className="menu-item-price">
                                        ${item?.price}
                                      </p> */}
                                            </div>
                                            <div className="menu-item-image-container">
                                              <img
                                                src={item?.item_image_url}
                                                alt="Menu Item"
                                                className="menu-item-image"
                                              />
                                              <button className="menu-item-button">
                                                <p style={{ fontSize: '12px' }}>
                                                  {t('menu.add')}
                                                </p>
                                              </button>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Hidden>
                                      <Hidden smUp>
                                        <Container
                                          maxWidth="lg"
                                          onClick={() => {
                                            if (menuDocs?.length === 0) {
                                              navigate(`/menu/${item.id}`);
                                            } else {
                                              navigate(
                                                `/menu-items/${item.id}`
                                              );
                                            }
                                          }}
                                        >
                                          <Grid item lg={8} xl={8}>
                                            <Typography
                                              variant={'body1'}
                                              style={{
                                                fontWeight: 'bold',
                                                color: '#833720',
                                                display: 'flex',
                                              }}
                                            >
                                              {item.isCatering ? (
                                                <>
                                                  <FiMinusSquare
                                                    style={{
                                                      marginTop: '4px',
                                                      marginRight: '3px',
                                                    }}
                                                  />
                                                  {'Catering'}
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={8} xl={8}>
                                            <Typography
                                              variant={'body1'}
                                              style={{
                                                fontWeight: 'bold',
                                                paddingTop: '20px',
                                              }}
                                            >
                                              {item.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item lg={12} xl={12}>
                                            <Typography
                                              variant={'body1'}
                                              style={{
                                                color: 'gray',
                                                paddingTop: '20px',
                                                paddingBottom: '18px',
                                              }}
                                            >
                                              {item?.description.length > 80
                                                ? item?.description.slice(0, 80)
                                                : item?.description}
                                              {item?.description.length > 80
                                                ? '...'
                                                : ''}
                                            </Typography>
                                          </Grid>

                                          <Grid item lg={12} xl={12} style={{}}>
                                            <Typography
                                              variant={'h6'}
                                              style={{
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              ${item.price}
                                            </Typography>
                                          </Grid>
                                          <br />
                                          <Grid
                                            item
                                            lg={4}
                                            xl={4}
                                            style={{
                                              paddingBottom: '10px',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            <img
                                              src={item.item_image_url}
                                              width="220px"
                                              loading="lazy"
                                              alt="none "
                                            ></img>
                                          </Grid>
                                          <Grid
                                            item
                                            xl={12}
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                            md={12}
                                          >
                                            <Divider />
                                          </Grid>
                                        </Container>
                                      </Hidden>
                                      {item?.id === id ? (
                                        <CustomizeDialoge
                                          open={open}
                                          handleClose={handleClose}
                                          option={option}
                                          c={item}
                                          priceToShow={
                                            item?.price + optionsPrice1
                                          }
                                          data={data}
                                          defs={defs}
                                          defaultPrices={optionsPrice1}
                                          extraQuantity={extraQuantity}
                                          setExtraQuantity={setExtraQuantity}
                                          outofStockDelivery={
                                            outofStockDelivery
                                          }
                                          openn={openn}
                                          handleClosee={handleClosee}
                                          handleOpenn={handleOpenn}
                                          enableAdd={enableAdd}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )
                              )}
                          </Grid>
                        </>
                      }
                    </div>
                  ))}
            </Container>
          )
      )}
    </>
  );
}
export default MenuItems;
