import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Licenese from '../../../assets/licenses.png';
export function Licenses() {
  const navigate = useNavigate();

  return (
    //no access name of component
    <Container maxWidth="lg">
      <div style={{ marginTop: '200px', color: 'black' }}>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item container justify="center" md={8} lg={8}>
            <img src={Licenese} alt="Licenese" />
          </Grid>
          <Grid item container justify="center" md={8} lg={8}>
            <Typography
              component="h1"
              variant="h3"
              style={{ fontWeight: 'bold' }}
            >
              Sorry, you are not allowed to access this page!
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate('/')}
              style={{ background: 'black', marginTop: '20px' }}
            >
              Go back to home page
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
export default Licenses;
