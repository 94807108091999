import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { environment } from '../../../environments/environment';

const ReusableDialog = ({
  open,
  onClose,
  title,
  description,
  buttonText,
  mainColor,
}) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" aria-describedby="dialog-description">
    <DialogTitle id="dialog-title">
      <Typography component="p" variant="h6" style={{ display: 'flex', justifyContent: 'center' }}>
        {title}
      </Typography>
    </DialogTitle>
    <Divider />
    <DialogContent>
      <DialogContentText id="dialog-description">
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={onClose}
        color="primary"
        style={{
          backgroundColor: environment.mainColor,
          color: 'white',
        }}
      >
        {buttonText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ReusableDialog;
