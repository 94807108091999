export const appearance = {
  theme: 'flat',
  // labels:'floating',
  rules: {
    '.Tab': {
      border: '1px solid red',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
      backgroundColor: 'var(--colorBackground)',
    },
    '.Input': {
      backgroundColor: 'red',
      border: '1px solid green',
    },
    '.Input:focus': {
      backgroundColor: 'red',
    },
    '.Input:hover': {
      color: 'var(--colorText)',
      backgroundColor: 'var(--colorBackground)',
    },

    '.Tab--selected': {
      border: '1px solid',
      borderColor: 'red',
      backgroundColor: 'var(--colorBackground)',
      // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
    },

    '.Input--invalid': {
      boxShadow:
        '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
    },

    // See all supported class names and selector syntax below
  },
  variables: {
    colorPrimary: 'red',
    colorBackground: 'grey',
    colorText: '#30313d',
    colorDanger: '#df1b41',
    fontFamily: 'Ideal Sans, system-ui, sans-serif',
    spacingUnit: '2px',
    borderRadius: '4px',
    // See all possible variables below
  },
};
