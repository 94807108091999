import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Container,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { MenuList, Paper } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonalInfo from '../../components/Profie/personalInfo';
import OrderHistory from '../../components/OrderHistory/OrderHistory';
import Address from '../../components/Address/Address';
import Payment from '../../components/PaymentMethod/Payment';
import footer from '../../../assets/background.png';
import { environment } from '../../../environments/environment';
import { useSelector } from 'react-redux';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteItems from '../../components/Profie/FavoriteItems';
import { useTranslation } from 'react-i18next';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Profile = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const token = useSelector((state) => state.authReducer.token);
  const query = useQuery();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [active, setActive] = useState(false);
  const [lastOrder, setLastOrder] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const sentinel = useRef(null);
  const env = useSelector((state) => state.authReducer.env);
  const [cancelOrderget, setCancelOrderget] = useState(false);
  const location = useLocation();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const [t, i18n] = useTranslation();
  const docid = localStorage.getItem('docid');

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'address':
        return licenses?.licenses.frontend?.profile_details?.address;
      case 'account_info':
        return licenses?.licenses.frontend?.profile_details?.account_info;
      case 'profile':
        return licenses?.licenses.frontend?.profile_details?.account_info;
      case 'payment':
        return licenses?.licenses.frontend?.profile_details?.payment;
      case 'orderhistory':
        return licenses?.licenses.frontend?.profile_details?.orderhistory;
      case 'favorites':
        return licenses.licenses.frontend?.profile_details?.favorites;

      default:
        return false;
    }
  };

  const checkLicensesdynamic = (pageName) => {
    return licenses?.frontend?.[pageName];
  };

  const handleClick = () => {
    setActive(!active);
  };
 
  const getOrders = async (isLoadMore = false) => {
    console.log(`Fetching orders: isLoadMore = ${isLoadMore}`);
  
    let query = firestore
      .collection('orders')
      .where('uid', '==', docid)
      .orderBy('created_at', 'desc')
      .limit(isLoadMore ? 5 : 10);
  
    if (isLoadMore && lastOrder) {
      query = query.startAfter(lastOrder);
      console.log('Starting after last order:', lastOrder);
    }
  
    try {
      const snapshot = await query.get();
      console.log('Fetched snapshot:', snapshot);
  
      if (snapshot.size > 0) {
        const array = isLoadMore ? [...data] : [];
        let lastdoc = null;
        snapshot.forEach((doc) => {
          array.push(doc.data());
          lastdoc = doc;
        });
        setLastOrder(lastdoc);
        setData(array);
        setHasMore(true);
        console.log('Updated data array:', array);
        console.log('New last document:', lastdoc);
      } else if (isLoadMore) {
        setHasMore(false);
        console.log('No more orders to fetch');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };
  

  useEffect(() => {
      getOrders();
    
  }, [cancelOrderget]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 400) {
        console.log('User has scrolled to the bottom');
        if (hasMore) {
          console.log('Fetching more orders');
          getOrders(true);
        } else {
          console.log('No more orders to fetch');
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, lastOrder, data]);
  
  

  return (
    <>
      <div
        style={{
          marginTop:
            environment?.restaurantId != 'elmbrookschools' &&
            environment?.restaurantId != 'alyousef' &&
            environment?.restaurantId != 'filfil' &&
            '80px',
        }}
      >
        {/* <Typography variant="h2">My Profile</Typography>
      <Divider style={{ marginBottom: '60px' }} /> */}
        <Box
          sx={{
            zIndex: 0,
            display: { xs: 'grid', md: 'flex', lg: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={2}
            style={{
              background:
                environment?.restaurantId === 'elmbrookschools' ||
                environment?.restaurantId === 'filfil' ||
                environment?.restaurantId === 'alyousef'
                  ? environment.mainColor
                  : '',
            }}
          >
            {' '}
            <MenuList
              style={{
                padding: '40px 1.875rem',
                background: environment.mainColor,
                paddingTop: '90px',
                height: '100%',
              }}
            >
              <MenuItem
                onClick={() =>
                  navigate(
                    `/profile/${auth?.currentUser?.uid}?type=personalInfo`
                  )
                }
                style={{
                  marginTop: '15px',
                  marginBottom: '45px',
                }}
              >
                <Typography
                  component="p"
                  variant="h6"
                  style={{
                    color:
                      query.get('type') === 'personalInfo' ||
                      location.pathname == '/profile'
                        ? environment.primaryColor
                        : 'white',
                  }}
                >
                  <PersonIcon
                    style={{
                      color:
                        query.get('type') === 'personalInfo' ||
                        location.pathname == '/profile'
                          ? environment.primaryColor
                          : 'white',
                    }}
                  />{' '}
                  {t('account')}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate(`/profile/${auth?.currentUser?.uid}?type=order`)
                }
                style={{
                  marginTop: '15px',
                  marginBottom: '45px',
                }}
              >
                <Typography
                  component="p"
                  variant="h6"
                  style={{
                    color:
                      query.get('type') === 'order'
                        ? environment.primaryColor
                        : 'white',
                  }}
                >
                  <ShoppingBasketIcon
                    style={{
                      color:
                        query.get('type') === 'order'
                          ? environment.primaryColor
                          : 'white',
                    }}
                  />{' '}
                  {t('ordersHistory')}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate(`/profile/${auth?.currentUser?.uid}?type=address`)
                }
                style={{
                  marginTop: '15px',
                  marginBottom: '45px',
                }}
              >
                <Typography
                  component="p"
                  variant="h6"
                  style={{
                    color:
                      query.get('type') === 'address'
                        ? environment.primaryColor
                        : 'white',
                  }}
                >
                  <EditLocationIcon
                    style={{
                      color:
                        query.get('type') === 'address'
                          ? environment.primaryColor
                          : 'white',
                    }}
                  />{' '}
                  {t('savedAddresses')}
                </Typography>
              </MenuItem>
              {checkLicensesdynamic('payment') && (
                <MenuItem
                  onClick={() =>
                    navigate(`/profile/${auth?.currentUser?.uid}?type=payment`)
                  }
                  style={{
                    marginTop: '15px',
                    marginBottom: '15px',
                  }}
                >
                  <Typography
                    component="p"
                    variant="h6"
                    style={{
                      color:
                        query.get('type') === 'payment'
                          ? environment.primaryColor
                          : 'white',
                    }}
                  >
                    <PaymentIcon
                      style={{
                        color:
                          query.get('type') === 'payment'
                            ? environment.primaryColor
                            : 'white',
                      }}
                    />{' '}
                    {t('paymentMethods')}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() =>
                  navigate(
                    `/profile/${auth?.currentUser?.uid}?type=favoriteItems`
                  )
                }
                style={{
                  marginTop: '35px',
                  marginBottom: '45px',
                }}
              >
                <Typography
                  component="p"
                  variant="h6"
                  style={{
                    color:
                      query.get('type') === 'favoriteItems'
                        ? environment.primaryColor
                        : 'white',
                  }}
                >
                  <FavoriteIcon
                    style={{
                      color:
                        query.get('type') === 'favoriteItems'
                          ? environment.primaryColor
                          : 'white',
                    }}
                  />{' '}
                  {t('favoriteItems')}{' '}
                </Typography>
              </MenuItem>
            </MenuList>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={2}
            style={{
              maxWidth: !smDown && '75%',
              flexBasis: '75%',
              marginTop: smDown ? 20 : '60px',
              paddingLeft: '20px',
            }}
          >
            <div
              style={{
                position: 'relative',
                backgroundImage: `url(${footer})`,
              }}
            >
              <Typography
                component="h2"
                variant="h4"
                style={{
                  fontWeight: 'bold',
                  marginRight: '10px',
                  paddingTop: '60px',
                }}
              >
                {query.get('type') === 'personalInfo'
                  ? ` ${t('editProfile')}`
                  : query.get('type') === 'order'
                  ? `${t('ordersHistory')}`
                  : query.get('type') === 'address'
                  ? `${t('savedAddresses')}`
                  : query.get('type') === 'payment'
                  ? `${t('paymentMethods')}`
                  : query.get('type') === 'favoriteItems'
                  ? `${t('favoriteItems')}`
                  : ` ${t('editProfile')}`}
              </Typography>
              <Divider />

              <div>
                <Typography component="p" variant="h6">
                  {query.get('type') === 'order' ? (
                    <>
                      {data.length > 0 ? (
                        data.map((order, index) => (
                          <>
                            {checkLicenses('orderhistory') && (
                              <>
                                <OrderHistory
                                  order={order}
                                  setCancelOrderget={setCancelOrderget}
                                />
                                <div ref={sentinel} />
                              </>
                            )}
                          </>
                        ))
                      ) : (
                        <Typography
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {t('noOrder')}
                        </Typography>
                      )}
                    </>
                  ) : query.get('type') === 'address' ? (
                    <>{checkLicenses('address') && <Address />}</>
                  ) : query.get('type') === 'personalInfo' ? (
                    <> {checkLicenses('account_info') && <PersonalInfo />}</>
                  ) : query.get('type') === 'payment' ? (
                    <> {checkLicenses('payment') && <Payment />}</>
                  ) : query.get('type') === 'favoriteItems' ? (
                    <Grid item lg={4}>
                      {checkLicenses('favorites') && <FavoriteItems />}
                    </Grid>
                  ) : (
                    <PersonalInfo />
                  )}
                </Typography>
              </div>
            </div>
          </Grid>
        </Box>
      </div>
    </>
  );
};
export default Profile;
