import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  Grid,
  AppBar,
  Toolbar,
  Divider,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container } from '@mui/system';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { environment } from '../../../environments/environment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const OrderSummary = ({ open, handleClose, Transition, storeName, order }) => {
  const theme = useTheme();
  const [t, i18n] = useTranslation();
  const xsDown = useMediaQuery(theme.breakpoints.down('md'));
  const {
    uid,
    order_id,
    deliveryOption,
    store,
    order_total,
    item_total,
    order_items,
    created_at,
    order_status,
    cancellation_time,
    completed_at,
    scheduled_at,
    order_delivery_status,
    order_delivery_charge,
    order_discount,
    order_tips,
    order_tax,
    service_charges,
  } = order;
  let storeAddress = store?.address?.displayName;
  const [cancelledTime, setCancelledTime] = useState(
    new Date(cancellation_time?.seconds * 1000)
  );

  const [completed, setcompleted] = useState(
    new Date(completed_at?.seconds * 1000)
  );
  const [scheduled, setScheduled] = useState(
    new Date(scheduled_at?.seconds * 1000)
  );

  moment.locale(i18n.language);
  const checkItem = () => {
    if (deliveryOption === 'dinein') {
      if (order_status === 'delivered')
        return `${t('profilee.orderDineInOn')} ${moment(completed).format(
          'LLL'
        )}`;
      else if (order_status === 'ready_to_pickup')
        return `${t('profilee.orderReadyToDineIn')}`;
    } else if (deliveryOption === 'pickup') {
      if (order_status === 'delivered')
        return `${t('profilee.orderPickedupOn')} ${moment(completed).format(
          'LLL'
        )}`;
      else if (order_status === 'ready_to_pickup')
        return `${t('profilee.orderReadyToPickedup')}`;
    } else if (
      deliveryOption === 'delivery' ||
      deliveryOption === 'noDriverDelivery'
    ) {
      if (order_status === 'delivered' && order_delivery_status === 'delivered')
        return `${t('profilee.deliverdOn')} ${moment(completed).format('LLL')}`;
      else if (
        order_status == 'ready_to_pickup' &&
        order_delivery_status === 'pending'
      )
        return `${t('profilee.lookingForDriver')}`;
      else if (
        order_status === 'ready_to_pickup' &&
        order_delivery_status === 'assigned'
      )
        return `${t('profilee.driverOnHisWayToGetOrder')}`;
      else if (
        order_status == 'pickedup' &&
        order_delivery_status === 'pickedup'
      )
        return `${t('profilee.driverOnHisWayToYou')}`;
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: '#F8F7F7' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          ></IconButton>
          <Typography
            sx={{
              ml: 2,
              flex: 1,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              color: 'rgb(160, 25, 19)',
              fontSize: xsDown ? 25 : 33,
            }}
          >
            {t('profilee.order')} #{order_id}
          </Typography>
          <CancelRoundedIcon
            style={{ color: 'rgb(160, 25, 19)', cursor: 'pointer' }}
            onClick={handleClose}
          />{' '}
        </Toolbar>
      </AppBar>

      <Container maxWidth={'xl'}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: '50px', marginLeft: '0px', marginBottom: '20px' }}
        >
          <StorefrontIcon
            fontSize="large"
            style={{
              color: 'white',
              backgroundColor: environment.mainColor,

              borderRadius: '5px',
            }}
          />{' '}
          <Typography
            variant="body2"
            style={{
              color: 'gray',
              marginLeft: '10px',
            }}
          >
            <span style={{ color: 'black' }}>{storeName}</span>
            <br />
            {storeAddress ? storeAddress : ''}
          </Typography>
          <Grid xs={12} style={{ marginTop: '10px' }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          style={{ marginTop: '30px', marginLeft: '0px', marginBottom: '10px' }}
        >
          <Grid xs={12} style={{ marginBottom: '10px' }}>
            <Divider />
          </Grid>
          {order_status === 'cancelled' && (
            <Typography
              variant="body2"
              style={{
                color: 'black',
                marginLeft: '10px',
              }}
            >
              <ErrorIcon
                fontSize="small"
                style={{
                  color: 'red',
                }}
              />{' '}
              {t('profilee.orderCancelledOn')}{' '}
              {moment(cancelledTime).format('LLL')}
            </Typography>
          )}

          {order_status === 'new' && (
            <Typography
              variant="body2"
              style={{
                color: 'black',
                marginLeft: '10px',
              }}
            >
              <DoneIcon
                fontSize="small"
                style={{
                  color: 'green',
                }}
              />{' '}
              {t('profilee.orderWaitingForConfirmation')}
            </Typography>
          )}
          {order_status === 'confirmed' && (
            <Typography
              variant="body2"
              style={{
                color: 'black',
                marginLeft: '10px',
              }}
            >
              <DoneIcon
                fontSize="small"
                style={{
                  color: 'green',
                }}
              />{' '}
              {t('profilee.orderIsBeingPrepared')}
            </Typography>
          )}
          <Typography
            variant="body2"
            style={{
              color: 'black',
              marginLeft: '10px',
            }}
          >
            {checkItem()}
          </Typography>
          {order_status === 'scheduled' && (
            <Typography
              variant="body2"
              style={{
                color: 'black',
                marginLeft: '10px',
              }}
            >
              {t('profilee.orderScheduledOn')} {moment(scheduled).format('LLL')}
            </Typography>
          )}
          <Grid xs={12} style={{ marginTop: '10px' }}>
            <Divider />
          </Grid>
        </Grid>

        <Card style={{ borderRadius: '20px', padding: '12px' }}>
          <Grid container>
            <Typography
              variant="body2"
              style={{
                color: 'gray',
                marginLeft: '10px',
                fontWeight: 'bold',
              }}
            >
              {t('profilee.billDetails')}
            </Typography>
            <Grid xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Box>
            <Grid container justifyContent="center" alignItems="center">
              {' '}
              {Array.isArray(order_items) &&
                order_items.map((items) => (
                  <Grid container direction="row">
                    {' '}
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        {items.title ? items.title : items.name} x{' '}
                        {items.quantity}
                        <br />
                        {Object.keys(items.customization).map((key) => (
                          <div key={key}>
                            {items.customization[key].map((item, index) => (
                              <div key={index}>{item.modifierTitle}</div>
                            ))}
                          </div>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  color="text.secondary"
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('profilee.itemTotal')}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  {' '}
                  {environment.currency} {item_total.toFixed(2)}
                </Typography>
              </Grid>
              {/* ================ */}
              {deliveryOption === 'pickup' || deliveryOption === 'dinein' ? (
                <>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('basket.serviceFees')} 
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      {environment.currency}
                      {parseFloat(service_charges)?.toFixed(2)}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                      }}
                    >
                      {t('profilee.deliveryFees')} :
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      {environment.currency}
                      {parseFloat(
                        order_delivery_charge + service_charges
                      )?.toFixed(2)}
                    </Typography>
                  </Grid>{' '}
                </>
              )}
              {/* ========================= */}
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('profilee.tips')}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  {environment.currency} {order_tips}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {deliveryOption === 'pickup' ||
                  deliveryOption === 'dinein' ? (
                    <span>{t('basket.estTaxesAndFees')}</span>
                  ) : (
                    <span>{t('basket.estTaxes')}</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                

                  {environment.currency}
                  {/* {deliveryOption === 'pickup' ||
                  deliveryOption === 'dinein' ?
                  parseFloat(
                    service_charges + order_tax
                  )?.toFixed(2)
                  //  order.service_charges + order_tax.toFixed(2)
                  :
               order_tax.toFixed(2)
                }
                 */}
                  {order_tax.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('profilee.discount')}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  {environment.currency} {order_discount}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('profilee.paidViaCard')}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  {environment.currency} {order_total}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </Dialog>
  );
};
export default OrderSummary;
