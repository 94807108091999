import React, { useState, useEffect } from 'react';
import { FormControl, Container } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { firestore } from '@tabletuck/firebase-services';
import { useSelector } from 'react-redux';
import visa from '../../../assets/visa.png';
import Google from '../../../assets/google_icon.png';
import Apple from '../../../assets/Apple_logo_PNG1.png';
import CreditCard from '../../../assets/credit-card.png';
import { CustomButton } from '@tabletuck/components';
import { browserName } from './browserName';
import { red } from '@mui/material/colors';
import { auth } from '../../../../../../libs/firebase-service/src';
import amex from '../../../assets/amex.png';
import discover from '../../../assets/dicover.png';
import dinars from '../../../assets/dinars.png';
import geniric from '../../../assets/geniric.png';
import jbc from '../../../assets/geniric.png';
import mastercard from '../../../assets/mastercard.png';
import unionpay from '../../../assets/unionpay.png';
import cashimage from '../../../assets/cash.png';
import { useTranslation } from 'react-i18next';

var isSafari = browserName == 'Apple Safari';
var isChrome = browserName == 'Google Chrome';

function PaymentsDialog({
  orderType,
  cards,
  setCards,
  handleChange,
  onSelect,
  setCredit,
  cash,
  setCash,
}) {
  // const [cards, setCards] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [cashSelected, setCashSelected] = useState(false);
  const [t, i18n] = useTranslation();
  console.log('selectedPaymentMethod', selectedPaymentMethod);
  console.log('setSelectedCardIndex', selectedCardIndex);

  const token = useSelector((state) => state.authReducer.token);

  let getData = async () => {
    try {
      firestore
        .collection(
          `users/${auth?.currentUser?.uid}/private_data/cards/sources`
        )
        .get()
        .then((docs) => {
          let arr = [];
          docs.forEach((doc) => {
            arr.push(doc.data());
          });
          setCards(arr);
        });
    } catch (e) {
      console.log('error in payment', e.message);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, fetch data
        getData();
      }
    });
    setSelectedPaymentMethod('');
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const hasCards = cards.length > 0;
    const hasCashOnDelivery = checkLicenses('cod');
    if (hasCards) {
      setSelectedPaymentMethod('0');
    } else if (hasCashOnDelivery) {
      setSelectedPaymentMethod('cash');
      setCashSelected(true);
      setCash(true);
    } else {
      setSelectedPaymentMethod('0');
    }
  }, [cards]);

  const handleClose = () => {
    setSelectedCardIndex(0);
    setCredit(false);
  };

  useEffect(() => {
    const selectedCard = cards[selectedCardIndex];
    if (selectedCard) {
      if (selectedCardIndex === cards.length) {
        onSelect('newCard');
        setCredit(true);
      } else {
        onSelect(selectedCardIndex.toString(), selectedCard);
        setCredit(false);
      }
    }
  }, [selectedCardIndex, onSelect, setCredit]);

  const handlePaymentChange = (event) => {
    const value = event.target.value;

    if (value === 'cash') {
      setSelectedPaymentMethod('cash');
      setCashSelected(true);
      setCash(true);
      setCredit(false);
      onSelect('cash');
    } else {
      setSelectedPaymentMethod(value);
      setCashSelected(false);
      setCash(false);
      setSelectedCardIndex(parseInt(value));
      setCredit(false);
    }
  };

  const licenses = useSelector((state) => state.licenReducer.licenses);

  const checkLicenses = (pageName) => {
    return licenses?.licenses.frontend?.[pageName];
  };

  return (
    <Container open={true} style={{ padding: 0 }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={cashSelected ? 'cash' : selectedPaymentMethod}
        onChange={(e) => handlePaymentChange(e)}
      >
        <div>
          <FormControl>
            {checkLicenses('cod') && (
              <>
                <FormControlLabel
                  value={'cash'}
                  control={
                    <Radio
                      sx={{
                        color: environment.mainColor,
                        '&.Mui-checked': {
                          color: environment.mainColor,
                        },
                      }}
                    />
                  }
                  sx={{
                    color: environment.mainColor,
                    '&.Mui-checked': {
                      color: environment.mainColor,
                    },
                  }}
                  label={
                    <LableForm
                      img={cashimage}
                      onChange={() => {
                        setSelectedPaymentMethod('cash');
                        setCashSelected(true);
                        setCash(true);
                        setCredit(false);
                        onSelect('cash');
                      }}
                      text={
                        orderType === 'delivery'
                          ? t('confirmPage.CashOnDeilvery')
                          : t('confirmPage.Cash')
                      }
                    />
                  }
                />
              </>
            )}
            {checkLicenses('payment') && (
              <>
                {cards.map((item, indx) => (
                  <FormControlLabel
                    key={item.id}
                    id={item.id}
                    value={indx.toString()}
                    control={
                      <Radio
                        sx={{
                          color: environment.mainColor,
                          '&.Mui-checked': {
                            color: environment.mainColor,
                          },
                        }}
                      />
                    }
                    sx={{
                      color: environment.mainColor,
                      '&.Mui-checked': {
                        color: environment.mainColor,
                      },
                    }}
                    label={
                      <LableForm
                        img={
                          item?.brand === 'American Express'
                            ? amex
                            : item?.brand === 'Diners Club'
                            ? dinars
                            : item?.brand === 'Discover'
                            ? discover
                            : item?.brand === 'JCB'
                            ? jbc
                            : item?.brand === 'Visa'
                            ? visa
                            : item?.brand === 'MasterCard'
                            ? mastercard
                            : item?.brand === 'UnionPay'
                            ? unionpay
                            : geniric
                        }
                        text={`****${item.last4}`}
                      />
                    }
                  />
                ))}
                <FormControlLabel
                  value={cards.length.toString()}
                  control={
                    <Radio
                      sx={{
                        color: environment.mainColor,
                        '&.Mui-checked': {
                          color: environment.mainColor,
                        },
                      }}
                    />
                  }
                  sx={{
                    color: environment.mainColor,
                    '&.Mui-checked': {
                      color: environment.mainColor,
                    },
                  }}
                  label={
                    <LableForm
                      img={CreditCard}
                      onChange={() => {
                        setSelectedCardIndex(cards.length);
                        setCredit(true);
                        onSelect('newCard');
                      }}
                      text={t('confirmPage.AddPayment')}
                    />
                  }
                />
              </>
            )}
          </FormControl>
        </div>
      </RadioGroup>
    </Container>
  );
}

export const LableForm = ({ img, text, onChange }) => {
  return (
    <div
      style={{ margin: 10, width: '100%', cursor: 'pointer' }}
      onClick={onChange}
    >
      <div
        className="flex flex-col md:flex-row  rounded-lg bg-white shadow-lg"
        style={{
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '5px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={img}
            alt="google"
            style={{
              color: 'white',
              width: '40px',
              height: '40px',
              borderRadius: '5px',
            }}
          />
          <div className="p-2 flex flex-col justify-start">
            <h5
              className="text-gray-100 text-lg font-small"
              style={{ color: 'gray' }}
            >
              {text}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsDialog;
