import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import Food2 from '../../../assets/Food2.png';
export default function HomeCard() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        marginTop: 10,
      }}
      onClick={() => navigate('/restaurants')}
    >
      <Card sx={{ width: '40%', display: 'flex', justifyContent: 'center' }}>
        <CardMedia
          component="img"
          sx={{
            width: 250,
            backgroundColor: `#00b68d`,
          }}
          image={Food2}
          alt="Live from space album cover"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography
              component="div"
              variant="h5"
              fontWeight="800"
              sx={{ fontFamily: 'DMSans, GEDinarOne' }}
              fontSize={24}
            >
              Groceries
            </Typography>
            <Typography
              variant="subtitle1"
              color="#323232"
              component="div"
              fontSize={16}
              sx={{ fontFamily: 'DMSans, GEDinarOne' }}
            >
              Find deals, free delivery, and more from our restaurant partners.
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              color="#FF5A00"
              sx={{ fontFamily: 'DMSans, GEDinarOne', pt: 3, pb: 1 }}
              fontWeight="500"
              fontSize={20}
            >
              Explore
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
