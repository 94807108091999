import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
export const fetchData = async (url, method, body, customHeaders = {}) => {
  let axiosConfig = {
    method: method,
    url: url,
    data: body,
    headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
  };

  const result = await axios(axiosConfig);
  return result;
};


export const handleToaster = (status, phrase) => {
    const toasterConfig = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: false,
    };

    if (status === 'success') {
      toast.success(`${phrase}`, toasterConfig);
    } else if (status === 'error') {
      toast.error(`${phrase}!`, toasterConfig);
    }
  };



  export const getLocation = async () => {
    try {
      const res = await axios.get("http://ip-api.com/json");
  
      if (res.status === 200) {
        return res?.data?.countryCode;
      } else {
        const fallbackRes = await axios.get("https://get.geojs.io/v1/ip/geo.json");
  
        if (fallbackRes.status === 200) {
          return fallbackRes?.data?.country_code;
        } else {
          console.log('Failed to fetch location data from geojs.io');
          return null;
        }
      }
    } catch (error) {
  
      try {
        const fallbackRes = await axios.get("https://get.geojs.io/v1/ip/geo.json");
  
        if (fallbackRes.status === 200) {
          return fallbackRes?.data?.country_code;
        } else {
          console.log('Failed to fetch location data from geojs.io');
          return null;
        }
      } catch (fallbackError) {
        console.log('Error fetching user country from geojs.io:', fallbackError);
        return null;
      }
    }
  };