import React, { useState } from 'react';
import { Grid, Container, useTheme, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CategorySearchBar } from '@tabletuck/components';
import { MenuCard } from '@tabletuck/components';
import { firestore } from '@tabletuck/firebase-services';
import { useDispatch } from 'react-redux';
import FulPageDialoge from '../../components/Dialouge/FullpageDialoge';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import footer from '../../../assets/background.png';
import { useParams } from 'react-router-dom';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useSelector } from 'react-redux';

function MenuDisplay() {
  const theme = useTheme();
  const { id } = useParams();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  // const [items, setItems] = useState([]);
  const items = useSelector((state) => state.shopReducer.currentItem);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const getItems = async () => {
  //   let itemsRef = firestore.collection('items');
  //   let items = [];
  //   const itemsList = await itemsRef.get();
  //   for (let index = 0; index < itemsList.docs.length; index++) {
  //     let element = itemsList.docs[index].data();
  //     element.id = itemsList.docs[index].id;
  //     items.push(element);
  //   }
  //   setItems(items);
  // };

  // React.useEffect(() => {
  //   getItems();
  // }, []);

  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {' '}
        <Grid
          item
          lg={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CategorySearchBar
            env={environment}
            admin={false}
          
          />
        </Grid>
      </Container>
      <Container maxWidth={'lg'}>
        <div style={{ display: 'flex', direction: 'row' }}>
          <Grid item lg={4}>
            <Typography
              variant="h4"
              style={{
                color: environment.mainColor,
                padding: '10px',
                cursor: 'pointer',
              }}
              onClick={handleClickOpen}
            >
              Menu <KeyboardArrowDownIcon style={{ height: '18px' }} />
            </Typography>
          </Grid>
          {/* <div style={{ borderLeft: '.2rem solid #ebebeb' }} /> */}
          <Grid item lg={4}></Grid>
        </div>
        <br></br>
        <Divider />
      </Container>
      <FulPageDialoge open={open} handleClose={handleClose} />
      <Container>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          {items.map((item, index) => (
            <>
              {item.parent_menu_id === id && item?.item_state === 'available' && (
                <Grid key={index + 'md'} item lg={4}>
                  <MenuCard
                    key={index + 'mdd'}
                    color={environment.mainColor}
                    item={item}
                    restaurantId={environment.restaurantId}
                    // key={index}
                    click={() => navigate(`/details/${item.id}`)}
                  />
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </Container>
    </>
  );
}
export default MenuDisplay;
