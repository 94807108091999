import React, { useState } from 'react';
import { Divider, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import InnerItem from './InnerItem';
import { useEffect } from 'react';
import InnerItemChild from './InnerItemChild';
function ChangeItemChild({
  modifiersGroup,
  modifier,
  handleChooseModifires,
  setExtraQuantity,
  extraQuantity,
  errReq,
  summErr,
  setOptionsPrice,
  setZeros,
  optionsPrice,
  checkSumAdd,
  checkSumM,
  showResults,
  errors,
  optionsPriceChild,
  setOptionsPriceChild,
  checkBoxchangeChild
}) {
  const [maxPerimit, setMaxPeremit] = useState(0);
  const [err, setErr] = useState('');
  useEffect(() => {
    setZeros((z) => ({
      ...z,
      [modifier?.title]: false,
    }));
  }, []);

  return (
    <div>
      <div
        style={{
          // backgroundColor: '#E6E6E6',
        //   padding: '1rem 2.5rem 1rem 1rem',
        marginTop:'25px'
        }}
      >
        <Typography
          component="p"
          variant="body1"
          style={{ fontWeight: 'bold' }}
        >
          {modifier?.title}
          <br />
          <span style={{ color: 'gray', fontWeight: 'normal' }}>
            (Select up to {modifier?.maxPermittedUnique})
          </span>
        </Typography>
      </div>
      <Grid
        container
      >
        {modifier?.options?.map((option, index) => (
          <>
           
            <InnerItemChild
              //   id={d.id}
              modifier={modifier}
              modifiersGroup={modifiersGroup}
              index={index}
              option={option}
              showResults={showResults}
              handleChooseModifires={handleChooseModifires}
              setOptionsPrice={setOptionsPrice}
              optionsPrice={optionsPrice}
              setErr={setErr}
              extraQuantity={extraQuantity}
              setExtraQuantity={setExtraQuantity}
              setZeros={setZeros}
              max={maxPerimit}
              setMax={setMaxPeremit}
              checkSumAdd={checkSumAdd}
              checkSumM={checkSumM}
              errors={errors}
              optionsPriceChild={optionsPriceChild}
              setOptionsPriceChild={setOptionsPriceChild}
              checkBoxchangeChild={checkBoxchangeChild}
            />
              <Divider style={{  width: '100%' }} />
          </>
        ))}
        {errReq?.length > 0 && (
          <Typography style={{ margin: 15 }} color={'red'}>
            {errReq}
          </Typography>
        )}
        {summErr && (
          <Typography style={{ margin: 15 }} color={'red'}>
            The options quantity is more than the allowed.
          </Typography>
        )}
        {errors && (
          <Typography style={{ margin: 15 }} color={'red'}>
            You can't select more than {modifier?.maxPermittedUnique}
          </Typography>
        )}
      </Grid>
    </div>
  );
}

export default ChangeItemChild;
