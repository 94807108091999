import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/auth-slice';
import { licenReducer } from './slices/licensesSlice';
import {shopReducer} from './slices/shop-slice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import persistCombineReducers from "redux-persist/es/persistCombineReducers";

import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

// const persistedReducer = persistReducer(persistConfig, allReducers)

const persistedReducer = persistCombineReducers(persistConfig, { authReducer: authReducer, shopReducer: shopReducer, licenReducer: licenReducer});

export const store = configureStore({ reducer: persistedReducer, devTools: process.env.NODE_ENV !== "production", middleware: [thunk], });
export const persistor = persistStore(store);