import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { CardActionArea } from '@mui/material';
import { setDefaultStore } from '../CartDrawer/utils';
import { useSelector } from 'react-redux';
import {
  activeMenu,
  allactiveMenues,
  checkStore,
  menues,
  setUser,
} from '../../../../../../libs/redux-slices/src';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { firestore } from '../../../../../../libs/firebase-service/src';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarPurple500SharpIcon from '@mui/icons-material/StarPurple500Sharp';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TwoWheelerSharpIcon from '@mui/icons-material/TwoWheelerSharp';
import { environment } from '../../../environments/environment';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
// const useStyles = makeStyles((theme) => ({
//   icon: {
//     fill: 'url(linear-gradient(180deg, #FE724C 0%, #FCA892 100%)',
//   },
// }));
export default function RestaurantHomeCard({ store }) {
  const token = useSelector((state) => state.authReducer.token);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.userData);
  const cart = useSelector((state) => state.shopReducer.cart);
  const items = useSelector((state) => state.shopReducer.currentItem);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const displayRestaurantName =
    store.restaurant_name.length > 15
      ? `${store.restaurant_name.substring(0, 15)}...`
      : store.restaurant_name;

  return (
    <>
      {/* <Grid
        item
        key={store.id}
        xs={12}
        sm={6}
        md={3}
        style={{
          cursor: 'pointer',
        }}
      > */}
      <Card
        style={{
          borderRadius: '15px',
          padding: '14px',
        }}
        onClick={async () => {
          setDefaultStore(
            token,
            store.id,
            store?.restaurant_name,
            store?.pickup_enabled,
            store?.pickup_enabled,
            store.dinein_enable,
            store.dinein_enable,
            store.address.displayName,
            store?.address?.displayName
          );
          if (userData?.bagItems?.length > 0 && cart?.length > 0) {
            dispatch(checkStore(true));
          } else dispatch(checkStore(false));

          const updatedUser = {
            ...userData,
            defaultStore: store.id,
          };
          dispatch(setUser({ userData: updatedUser }));

          dispatch(activeMenu(store?.active_menu_now));
          const selectedStoreMenusRef = firestore
            .collection('menus')
            .where('store_id', '==', store.id);
          selectedStoreMenusRef.get().then((snapshot) => {
            const selectedStoreMenusData = snapshot.docs.map((menuDoc) => {
              const menuData = menuDoc.data();
              return { ...menuData };
            });
            const popularItems = [];
            if (selectedStoreMenusData?.length > 0) {
              selectedStoreMenusData.forEach((menu) => {
                menu.category_id.forEach((category) => {
                  category.items_id.forEach((itemId) => {
                    const item = items.find((item) => item.id === itemId);
                    if (item && item.is_popular === true) {
                      // If yes, add its ID to the popularItems array
                      popularItems.push(itemId);
                    }
                  });
                });
              });
              // Check if the "Popular" category already exists in the first menu
              const popularCategoryIndex =
                selectedStoreMenusData[0].category_id.findIndex(
                  (category) => category.id === 'Popular'
                );
              // If "Popular" category exists, update its items_id
              if (popularCategoryIndex !== -1) {
                selectedStoreMenusData[0].category_id[
                  popularCategoryIndex
                ].items_id = popularItems;
              } else {
                selectedStoreMenusData[0].category_id.unshift({
                  id: 'Popular',
                  items_id: popularItems,
                });
              }
            }
            dispatch(menues(selectedStoreMenusData));
            dispatch(
              allactiveMenues(
                selectedStoreMenusData
                  .filter((item) => item.off_menu === false)
                  .map((item) => item.id)
              )
            );
          });

          navigate('/menu');
        }}
      >
        <CardActionArea>
          <Paper
            sx={{
              width: '100%',
              height: 159,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <CardMedia
              component="img"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              image={store.image}
              alt="Restaurant Image"
            />
            <div
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                background: 'white',
                boxShadow:
                  '0px 5.847456932067871px 23.389999389648438px 0px #FE724C1A',
                borderRadius: '100px',
                width: '77px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                gutterBottom
                variant="caption"
                style={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  color: environment.darkgrayBlueColor,
                  lineHeight: '0.844rem',
                  marginBottom: '0',
                }}
              >
                {store?.rating?.aggregate_rating === ''
                  ? 0.0
                  : store?.rating?.aggregate_rating}
              </Typography>
              <StarPurple500SharpIcon style={{ color: '#FFC529' }} />{' '}
              <Typography
                gutterBottom
                variant="caption"
                style={{
                  fontFamily: 'Arial',
                  fontWeight: 700,
                  color: environment.grayBlueColor,
                  lineHeight: '0.719rem',
                  fontSize: '0.625rem',
                  marginBottom: '0',
                }}
              >
                {store?.rating?.reviews >= 25
                  ? `(25+)`
                  : `(${store?.rating?.reviews})`}
              </Typography>
            </div>
          </Paper>
          <CardContent sx={{ paddingLeft: 0, paddingBottom: 0 }}>
            <Typography
              gutterBottom
              variant="body1"
              component="div"
              style={{
                fontFamily: 'Poppins',
                fontWeight: 700,
                color: environment.darkgrayBlueColor,
              }}
            >
              {displayRestaurantName}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontFamily: 'Poppins',
                lineHeight: '1.313rem',
                color: environment.grayBlueColor,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LocationOnIcon
                sx={{
                  color: environment.mainColor,
                }}
              />
              {store.address.displayName.split(',').slice(0, 2).join(',')}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontFamily: 'Poppins',
                lineHeight: '1.313rem',
                color: environment.grayBlueColor,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* <span
                style={{
                  marginRight:
                    i18n.language === 'ar' || i18n.language === 'he'
                      ? 'unset'
                      : '8px',
                  marginLeft:
                    i18n.language === 'ar' || i18n.language === 'he'
                      ? '8px'
                      : 'unset',
                }}
              >
                <TwoWheelerSharpIcon
                  sx={{
                    color: environment.mainColor,
                  }}
                />{' '}
                {t('freeDelivery')}{' '}
              </span> */}
              <span>
                <AccessTimeFilledIcon
                  sx={{
                    color: environment.mainColor,
                  }}
                />
                {t('10-15mins')}
              </span>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      {/* </Grid> */}
    </>
  );
}
