import React from 'react';
import { environment } from '../../../environments/environment';
import {
  Container,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import mapIcon from '../../../assets/icons/Icons-map.png';
import payIcon from '../../../assets/icons/Icons-pay.png';
import CheckIcon from '../../../assets/icons/Icons-check.png';
import donutIcon from '../../../assets/icons/Icons-donut.png';
const content = [
  {
    id: '1',
    icon: mapIcon,
    title: 'selectLocation',
    discription: 'chooseLocationWhereFoodDelivered',
  },
  {
    id: '2',
    icon: CheckIcon,
    title: 'chooseOrder',
    discription: 'checkOverHundredsOfMenus',
  },
  {
    id: '3',
    icon: payIcon,
    title: 'payAdvanced',
    discription:
      "quickSafeSimpleSelectMethodsOfPayment",
  },
  {
    id: '4',
    icon: donutIcon,
    title: 'enjoyMeals',
    discription: 'foodMadeAndDeliveredDirectlyToHome',
  },
];
const HowRestaurantWork = () => {
  const [t, i18n] = useTranslation();
  return (
    <Container>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h1"
            style={{
              fontFamily: 'Playfair Display',
              fontWeight: 900,
              fontSize: '2rem',
              lineHeight: '2.625rem',
              color: '#212121',
              textAlign: 'center',
            }}
          >
            {t('work.howDoesIt')}{' '}
            <span style={{ color: environment.mainColor }}>{t('work.work')}</span>{' '}
          </Typography>
        </Grid>
        {content.map((content) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={content.id}
            flexDirection="column"
          >
            <Box
              style={{
                boxShadow: '0px 0px 76px 0px #00000008',
                color: 'white',
                borderRadius: '16px',
                marginTop: '40px',
                padding: '22px 0',
              }}
            >
              <img
                alt="mapIcon"
                src={content.icon}
                style={{ margin: 'auto' }}
              />
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  fontSize: '1.125rem',
                  lineHeight: '1.344rem',
                  textAlign: 'center',
                  color: '#5B5B5B',
                }}
              >
              {t(`work.${content.title}`)}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  lineHeight: '1.225rem',
                  textAlign: 'center',
                  color: '#9E9E9E',
                  padding: '0 22px 16px ',
                }}
              >
                {t(`work.${content.discription}`)}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HowRestaurantWork;
