import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  useMediaQuery,
  Container,
} from '@mui/material';
import { environment } from '../../../environments/environment';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import TTJordan from '../../../assets/background-ttj.png';
import TTJordan2 from '../../../assets/background-ttj2.png';
import mobileStores from '../../../assets/mobile-stores.png';
import SearchIcon from '@mui/icons-material/Search';
const LandingTTj = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [t, i18n] = useTranslation();
  return (
    <Grid
      container
      style={{
        position: 'relative',
        backgroundImage: `url(${TTJordan2})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        alignItems: 'center',
        height: '783px',
        boxShadow: '0px 4px 50px 0px #0000000D',
        backgroundColor: '#0000000D',
        transform:
          i18n.language === 'ar' || i18n.language === 'he'
            ? 'scaleX(-1)'
            : 'unset',
      }}
    >
      <Container
        style={{
          transform:
            i18n.language === 'ar' || i18n.language === 'he'
              ? 'scaleX(-1)'
              : 'unset',
          position: 'relative',
        }}
      >
        {!mdDown && (
          <img
            style={{
              position: 'absolute',
              top: '-90px',
              right:
                i18n.language === 'ar' || i18n.language === 'he'
                  ? 'unset'
                  : '80px',
              left:
                i18n.language === 'ar' || i18n.language === 'he'
                  ? '80px'
                  : 'unset',
            }}
            src={mobileStores}
            alt="mobileStores"
          />
        )}
        <Grid
          container
          rowSpacing={5}
          flexDirection="column"
          //   style={{ padding: smDown ? '0 20px' : '0 100px' }}
        >
          <Grid item xs={12} md={6} style={{ padding: '0px' }}>
            <Box
              style={{
                width: '160px',
                height: '45px',
                borderRadius: '15px',
                backgroundColor: environment.secoundaryColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '1.125rem',
                  lineHeight: '1.688rem',
                  textAlign: 'center',
                  color: environment.mainColor,
                }}
              >
                {t('ideateFood')} 🍔
              </Typography>
            </Box>
            <Typography
              variant="h1"
              style={{
                fontFamily: 'Playfair Display',
                fontWeight: 900,
                fontSize: '3.735rem',
                lineHeight: '4.904rem',
                color: environment.darkGreyColor,
              }}
            >
              {t('quickest')}{' '}
              <span style={{ color: environment.mainColor }}>
                {t('foodDelivery')}
              </span>{' '}
              {t('inTown')}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: 'Poppins',
                lineHeight: '1.417rem',
                color: environment.darkGreyColor,
              }}
            >
              {t('deliveredhotandfresh')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              style={{
                boxShadow: '0px 22px 40px 0px #FF683830',
                backgroundColor: environment.mainColor,
                color: 'white',
                borderRadius: '8px',
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
                fontWeight: 700,
                lineHeight: '1.313rem',
                textAlign: 'center',
                marginRight:
                  i18n.language === 'ar' || i18n.language === 'he'
                    ? 'unset'
                    : '1rem',
                marginLeft:
                  i18n.language === 'ar' || i18n.language === 'he'
                    ? '1rem'
                    : 'unset',
              }}
              onClick={() => navigate('/restaurants')}
            >
              {' '}
              {t('seeRestaurants')}
              <SearchIcon style={{ marginLeft: '5px' }} />
            </Button>
            {/* <Button
              variant="contained"
              style={{
                backgroundColor: environment.secoundaryColor,
                color: environment.darkGreyColor,
                borderRadius: '8px',
                textTransform: 'capitalize',
                fontFamily: 'Poppins',
                fontWeight: 700,
                lineHeight: '1.313rem',
              }}
            >
              {t('howToOrder')}
            </Button> */}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LandingTTj;
