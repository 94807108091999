import React, { useEffect, useState } from 'react';
import { Typography, Grid, TextField, InputAdornment } from '@mui/material';
import registerFooterBg from '../../../assets/registerFooterBg.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { environment } from '../../../environments/environment';
import SearchIcon from '@mui/icons-material/Search';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';
const LandingRestaurants = ({
  setLoading,
  setStores,
  setStoresFirebase,
  getStores,
  nearMe,
}) => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const searchStoresMethod = async (text) => {
    const formattedText = text?.trim()?.toLowerCase();
    setSearchText(formattedText);

    // setLoading(true);
    if (formattedText === '' || formattedText.length === 0) {
      getStores();
      nearMe();
      // setLoading(false);
      return;
    } else {
      let ref = firestore.collection('stores');
      ref = ref.where('is_deleted', '==', false);
      const resultData = await ref.get();
      const mappedItems = resultData.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
      const result = mappedItems?.filter((d) => {
        const menuName = d?.restaurant_name?.trim()?.toLowerCase();
        return menuName?.includes(formattedText);
      });
      // setLoading(false);
      setStores(result);
      setStoresFirebase(result);
    }
  };
  useEffect(() => {
    searchStoresMethod(searchText);
  }, [searchText]);
  return (
    <div
      style={{
        backgroundImage: `url(${registerFooterBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: 'white',
        padding: '55px 0',
        marginTop: '64px',
      }}
    >
      <Grid
        container
        spacing={3}
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Grid item xs={12} lg={4}>
          <Typography
            variant="h3"
            style={{
              fontFamily: 'Playfair Display',
              textAlign: 'center',
              fontWeight: 700,
              fontSize: '2.5rem',
              lineHeight: '2.5rem',
            }}
          >
            {t('restaurants')}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography
            variant="body1"
            style={{
              fontFamily: 'Poppins',
              textAlign: 'center',
              lineHeight: '2rem',
            }}
          >
            <span
              style={{ color: '#EDEDED', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            >
              {t('home')}
            </span>{' '}
            <span style={{ fontWeight: 500 }}>
              {' '}
              {i18n.language === 'ar' || i18n.language === 'he' ? (
                <ArrowBackIosIcon
                  style={{
                    fontSize: 'inherit',
                    marginLeft: '6px',
                    marginRight: '-5px',
                    marginTop: '-4px'
                  }}
                />
              ) : (
                <ArrowForwardIosIcon style={{ fontSize: 'inherit' }} />
              )}{' '}
              {t('restaurants')}{' '}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={10} md={6} lg={8} style={{ width: '100%' }}>
          <Formik
            initialValues={{
              searchValue: '',
            }}
            onSubmit={(values) => {
              searchStoresMethod(values.searchValue);
            }}
          >
            {({ handleBlur, handleChange, errors, values, isValid }) => (
              <TextField
                size="small"
                variant="outlined"
                placeholder={t('search')}
                fullWidth
                onBlur={handleBlur('searchValue')}
                onChange={(e) => {
                  handleChange(e); // Update Formik's state
                  searchStoresMethod(e.target.value); // Call searchMethod with the new value
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: 'white' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& input': {
                    height: '48px',
                    border: 'none',
                  },
                  '& .css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                  '& .MuiOutlinedInput-root': {
                    color: '#BBC1CE',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: environment.mainColor,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: environment.mainColor,
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#EAECEE',
                  },
                }}
              />
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingRestaurants;
