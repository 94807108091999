import {
  Button,
  Typography,
  useTheme,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dataset } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import FeesDialog from '../Dialouge/FeesDailoge';
import ReusableDialog from '../Dialouge/ReusableDailoge';

const BillDetails = ({
  type,
  data,
  total,
  addressStore,
  store,
  dataStore,
  deliveryFee,
}) => {
  const cart = useSelector((state) => state.shopReducer.cart);
  const user = useSelector((state) => state.authReducer.userData);
  const [serviceFees, setServiceFees] = useState(0);
  const { code } = useSelector((state) => state.shopReducer.cartData);
  const { discount, isVerify } = useSelector(
    (state) => state.shopReducer.cartData
  );

  const [open, setOpen] = React.useState(false);
  const [opentaxes, setOpentaxes] = React.useState(false);
  const [dataSet, setDataSet] = useState(data);
  const [address, setAddress] = useState(addressStore);
  const [storee, setStore] = useState(store);
  useEffect(() => {
    if (user?.pickupOrDelivery === 'dinein') {
      setServiceFees(dataStore?.service_fee?.dineIn);
    } else if (user?.pickupOrDelivery === 'pickup') {
      setServiceFees(dataStore?.service_fee?.pickUp);
    } else if (user?.pickupOrDelivery === 'delivery') {
      setServiceFees(dataStore?.service_fee?.delivery);
    }
  }, [user]);

  useEffect(() => {
    setDataSet(data);
    setAddress(addressStore);
  }, [addressStore, data]);
  useEffect(() => {
    setStore(store);
  }, [store, dataStore, data]);

  const handleCloseTaxes = () => {
    setOpentaxes(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [t, i18n] = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        padding: '20px 10%',
        display: 'contents',
      }}
      dir={i18n.language === 'ar' || i18n.language === 'he' ? 'rtl' : 'ltr'}
    >
      <Divider style={{ border: '3px solid #C4C4C4' }} />
      {!!cart && !!cart.length && cart.length > 0 && (
        <>
          {' '}
          <Box
            sx={{
              width: '100%',
              padding: '12px 2%',
            }}
          >
            {' '}
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                color: environment.mainColor,
                fontWeight: 700,
                display: 'flex',
              }}
            >
              {t('basket.billDetails')}
            </Typography>
          </Box>
          <Divider />
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 2%',
            }}
          >
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: 'gray',
              }}
            >
              {t('basket.subtotal')}
            </Typography>
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
              }}
            >
              {environment.currency}
              {isVerify && code != '' ? (
                <>
                  <span>
                    {parseFloat(
                      (total - discount).toString().replaceAll(',', '')
                    )?.toFixed(2)}
                  </span>
                  <br />
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: 'gray',
                      marginLeft: '5px',
                    }}
                  >
                    {environment.currency}
                    {parseFloat(
                      total?.toString()?.replaceAll(',', '')
                    )?.toFixed(2)}
                  </span>
                </>
              ) : (
                parseFloat(total?.toString()?.replaceAll(',', ''))?.toFixed(2)
              )}
              <br />
            </Typography>
          </div>
          {type === 'delivery' && (
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 2%',
              }}
            >
              <Typography
                component="a"
                variant="h6"
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  display: 'flex',
                  color: 'gray',
                }}
              >
                {t('basket.deliveryFees')}{' '}
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{
                    marginTop: '5px',
                    marginleft: '4px',
                    paddingTop: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpen(true)}
                />
              </Typography>

              <Typography
                component="a"
                variant="h6"
                sx={{
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  display: 'flex',
                }}
              >
                <p key={'key'}>
                  {dataSet?.id === address ? (
                    <p>
                      {environment.currency}{' '}
                      {/* {parseFloat(
                        deliveryFee + total * dataSet?.tax.miscellaneous
                      )?.toFixed(2)}
                      "num"
                      {parseFloat(
                        (
                          dataSet?.tax?.tax +
                          (total - discount) +
                          deliveryFee +
                          (total - discount * dataSet?.tax?.miscellaneous)
                        )
                          .toString()
                          .replaceAll(',', '')
                      ).toFixed(2)}
                      "num3"
                      {parseFloat(
                        (
                          dataStore.tax.tax * total +
                          total +
                          deliveryFee +
                          total * dataStore?.tax?.miscellaneous
                        )
                          .toString()
                          .replaceAll(',', '')
                      ).toFixed(2)}
                      "num4" */}
                      {deliveryFee &&
                        parseFloat(
                          deliveryFee + total * dataStore?.tax?.miscellaneous
                        )?.toFixed(2)}
                    </p>
                  ) : (
                    ''
                  )}
                </p>
              </Typography>
            </div>
          )}
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 2%',
            }}
          >
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: 'gray',
              }}
            >
              {/* not the dialog */}
              {type === 'pickup' || type === 'dinein' ? (
                <span>{t('basket.estTaxesAndFees')}</span>
              ) : (
                <span>{t('basket.estTaxes')}</span>
              )}

              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  marginTop: '5px',
                  marginleft: '4px',
                  paddingTop: '4px',
                  cursor: 'pointer',
                }}
                style={{ direction: 'initial' }}
                onClick={() => setOpentaxes(true)}
              />
            </Typography>

            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
              }}
              style={{ direction: 'initial' }}
            >
              <FeesDialog
                opentaxes={opentaxes}
                setOpentaxes={setOpentaxes}
                dataStore={dataStore}
                orderType={type}
                serviceFees={serviceFees}
                total={total}
                data={data}
              />
              {/* finish dialog */}
              {type === 'dinein' && (
                <p>
                  {dataStore?.restaurant_name === storee ? (
                    <p>
                      {' '}
                      {dataStore?.tax?.type === 'amount' ? (
                        <>
                          {''}
                          {environment.currency}
                          {isVerify
                            ? parseFloat(
                                (
                                  dataStore?.tax.tax -
                                  discount +
                                  dataStore?.service_fee?.dineIn
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (
                                  dataStore?.tax.tax +
                                  dataStore?.service_fee?.dineIn
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {''} {environment.currency}
                          {isVerify
                            ? parseFloat(
                                (
                                  dataStore?.tax.tax * (total - discount) +
                                  dataStore?.service_fee?.dineIn
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (
                                  dataStore?.tax.tax * total +
                                  dataStore?.service_fee?.dineIn
                                ).toFixed(2)
                              )}
                        </>
                      )}{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </p>
              )}
              {type === 'delivery' && (
                <p>
                  {dataSet?.id === address ? (
                    <p>
                      {dataStore?.tax?.type === 'amount' ? (
                        <>
                          {''} {environment.currency}{' '}
                          {isVerify
                            ? parseFloat(
                                dataSet?.tax.tax.toString().replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                dataSet?.tax.tax.toString().replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {''} {environment.currency}{' '}
                          {isVerify
                            ? parseFloat(
                                (dataSet?.tax.tax * (total - discount))
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (dataSet?.tax.tax * total)
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      )}
                    </p>
                  ) : (
                    ''
                  )}
                </p>
              )}

              {type === 'pickup' && (
                <p>
                  {dataStore?.restaurant_name === storee ? (
                    <p>
                      {dataStore?.tax?.type === 'amount' ? (
                        <>
                          {''} {environment.currency}{' '}
                          {isVerify
                            ? parseFloat(
                                (
                                  dataStore?.tax.tax +
                                  dataStore?.service_fee?.pickUp
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (
                                  dataStore?.tax.tax +
                                  dataStore?.service_fee?.pickUp
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {''} {environment.currency}
                          {isVerify
                            ? parseFloat(
                                (
                                  dataStore?.tax.tax * (total - discount) +
                                  dataStore?.service_fee?.pickUp
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)
                            : parseFloat(
                                (
                                  dataStore?.tax.tax * total +
                                  dataStore?.service_fee?.pickUp
                                )
                                  .toString()
                                  .replaceAll(',', '')
                              ).toFixed(2)}
                        </>
                      )}{' '}
                    </p>
                  ) : (
                    ''
                  )}
                </p>
              )}
            </Typography>
          </div>
          <Divider />
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 2%',
            }}
          >
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: environment.mainColor,
                fontWeight: 'bold',
              }}
            >
              {t('basket.total')}
            </Typography>
            <Typography
              component="a"
              variant="h6"
              sx={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                display: 'flex',
                color: environment.mainColor,
                fontWeight: 'bold',
              }}
            >
              {type === 'pickup' && (
                <div>
                  <p>
                    {dataStore?.restaurant_name === store ? (
                      <p>
                        {' '}
                        {dataStore?.tax?.type === 'amount' ? (
                          <>
                            {''} {environment.currency}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax.tax +
                                    dataStore?.service_fee?.pickUp +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax.tax +
                                    dataStore?.service_fee?.pickUp +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {''} {environment.currency}{' '}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax.tax * (total - discount) +
                                    dataStore?.service_fee?.pickUp +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax.tax * total +
                                    dataStore?.service_fee?.pickUp +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>{' '}
                </div>
              )}
              {type === 'dinein' && (
                <div>
                  <p>
                    {dataStore?.restaurant_name === storee ? (
                      <p>
                        {dataStore?.tax?.type === 'amount' ? (
                          <>
                            {''} {environment.currency}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax.tax +
                                    dataStore?.service_fee?.dineIn +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax.tax +
                                    dataStore?.service_fee?.dineIn +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {environment.currency}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataStore?.tax.tax * (total - discount) +
                                    dataStore?.service_fee?.dineIn +
                                    (total - discount)
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataStore?.tax.tax * total +
                                    dataStore?.service_fee?.dineIn +
                                    total
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>{' '}
                </div>
              )}
              {type === 'delivery' && (
                <div>
                  <p>
                    {dataSet?.id === address ? (
                      <p>
                        {' '}
                        {dataStore?.tax?.type === 'amount' ? (
                          <>
                            {''} {environment.currency}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataSet?.tax.tax +
                                    (total - discount) +
                                    deliveryFee +
                                    total * dataSet?.tax.miscellaneous
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataSet?.tax.tax +
                                    total +
                                    deliveryFee +
                                    total * dataSet?.tax.miscellaneous
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        ) : (
                          <>
                            {} {environment.currency}
                            {isVerify
                              ? parseFloat(
                                  (
                                    dataSet?.tax.tax * (total - discount) +
                                    (total - discount) +
                                    deliveryFee
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)
                              : parseFloat(
                                  (
                                    dataSet?.tax.tax * total +
                                    total +
                                    total * dataSet?.tax.miscellaneous +
                                    deliveryFee
                                  )
                                    .toString()
                                    .replaceAll(',', '')
                                ).toFixed(2)}
                          </>
                        )}
                      </p>
                    ) : (
                      ''
                    )}
                  </p>{' '}
                </div>
              )}
            </Typography>
          </div>
        </>
      )}

      <ReusableDialog
        open={open}
        onClose={handleClose}
        title={t('basket.deliveryFees')}
        description={t(
          'basket.deliveryFeesAreCalculatedBasedOnYourLocationAndOtherFactors'
        )}
        buttonText={t('basket.oK')}
      />
    </Box>
  );
};
export default BillDetails;
