// import React, { useEffect, useState } from 'react';
// import { environment } from '../../../environments/environment';

// const Sitemap = () => {
//   const [sitemapContent, setSitemapContent] = useState(null);

//   useEffect(() => {
//     fetch(`http://localhost:8080/sitemap.xml?restaurantId=${environment?.restaurantId}`)
//       .then(response => {
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         return response.text();
//       })
//       // .then(content => {
//       //   setSitemapContent(content);
//       .then(xmlText => {
//         // Use DOMParser to parse XML text
//         const parser = new DOMParser();
//         const xmlDoc = parser.parseFromString(xmlText, 'application/xml');

//         // Process the xmlDoc as needed
//         console.log(xmlDoc);
//         setSitemapContent(xmlDoc)
//       })
//       .catch(error => {
//         console.error('Fetch error:', error);
//       });
//   }, []);

//   return (
//     <>
//       {sitemapContent ? (
//         <pre>{sitemapContent}</pre>
//       ) : (
//         <p>Loading sitemap content...</p>
//       )}
//     </>
//   );
// };

// export default Sitemap;

import React, { useEffect, useState } from 'react';
import { environment } from '../../../environments/environment';
import XMLViewer from 'react-xml-viewer';
import { Box } from '@mui/material';

const Sitemap = () => {
  const [sitemapContent, setSitemapContent] = useState(null);

  useEffect(() => {
    fetch(
      `${environment?.apiUrl}/sitemap.xml?restaurantId=${environment?.restaurantId}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((xmlText) => {
     
        setSitemapContent(xmlText);
      })
      .catch((error) => {
        console.error('Fetch error:', error);
      });
  }, []);

  return sitemapContent ? (
    <Box style={{ margin: 30 }}>
      <XMLViewer xml={sitemapContent} collapsible />
    </Box>
  ) : (
    <p>Loading sitemap content...</p>
  );
};

export default Sitemap;
