import React from 'react';
import {
  Typography,
  useMediaQuery,
} from '@mui/material';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import iPhoneHands from '../../../assets/iPhoneHands.png';
import Framefooter from '../../../assets/Framefooter.png';
import googlePlayLogin from '../../../assets/googlePlayLogin.png';
import appleStoreLogin from '../../../assets/appleStoreLogin.png';
import { useTranslation } from 'react-i18next';

const DownloadApp = () => {
  const [t, i18n] = useTranslation();
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  return (
    <div
      style={{
        backgroundImage: `url(${Framefooter})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: 'white',
        padding: '94px 0',
        position: 'relative',
      }}
    >
      {isLGScreen && (
        <img
          src={iPhoneHands}
          alt="iPhoneHands"
          style={{ position: 'absolute', bottom: '0', left: '100px' }}
        />
      )}
      <Typography
        variant="h4"
        style={{
          fontFamily: 'Playfair Display',
          textAlign: 'center',
          fontWeight: 900,
          fontSize: '2.5rem',
          lineHeight: '3.283rem',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '30px',
        }}
      >
        <span style={{ color: environment.mainColor }}>
          {t('footer.download')}
        </span>
        <span style={{ color: environment.darkgrayBlueColor }}>
          {t('footer.theApp')}
        </span>{' '}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          margin: 'auto',
          justifyContent: 'center',
        }}
      >
        <a href={environment.appGooglePlayLink} target="_blank">
          <img
            src={googlePlayLogin}
            alt="googlePlayLogin"
            style={{
              marginRight:
                i18n.language === 'ar' || i18n.language === 'he'
                  ? '0px'
                  : '12px',
              marginLeft:
                i18n.language === 'ar' || i18n.language === 'he'
                  ? '12px'
                  : '0px',
            }}
          />
        </a>
        <a href={environment.appappleStoreLink} target="_blank">
          <img src={appleStoreLogin} alt="appleStoreLogin" />
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
