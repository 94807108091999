import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  Button,
  DialogActions,
} from '@mui/material';
import { Field, Formik, FormikHelpers } from 'formik';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useSelector } from 'react-redux';
import { setUser } from '../../../../../../libs/redux-slices/src';
import { useDispatch } from 'react-redux';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';

const ChangeName = ({ open, onClose, setName, name }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authReducer.token);
  const user = useSelector((state) => state.authReducer.userData);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    setUserData(user);
  }, [user]);
  const handleSubmit = async (values) => {
    setLoading(true);
    let formattedName = values.fullName;
    const formattedNameResult =
      formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
    const updateDoc = await firestore.collection('restaurant').doc();
    setName(formattedName);

    if (formattedName.length < 20) {
      await firestore
        .collection('users')
        .doc(userData?.uid)
        .set(
          {
            fullName: formattedNameResult,
          },
          { merge: true }
        )
        .then(() => {
          onClose();
          setLoading(false);
          const updatedUser = { ...userData, fullName: formattedNameResult };
          dispatch(setUser({ userData: updatedUser }));
          onClose();
        })
        .catch((e) => {
          setLoading(false);
          onClose();
        });
      await firestore
        .collection('restaurant')
        .doc('Restaurant')
        .set(
          {
            update_items_id: updateDoc.id,
          },
          { merge: true }
        )
        .then(() => {
          onClose();
        })
        .catch((e) => {
          console.log('err:', e);
        });
      onClose();
    }
  };

  const [t, i18n] = useTranslation();

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id={'title'} style={{ padding: '30px' }}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {t("profilee.changeName")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogActions>
            <Formik
              enableReinitialize
              initialValues={{
                fullName: userData?.fullName,
              }}
              onSubmit={handleSubmit}
            >
              {({
                handleBlur,
                handleChange,
                values,
                errors,
                handleSubmit,
                touched,
              }) => (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      helperText=""
                      label={ t("profilee.name")}
                      name="fullName"
                      onChange={handleChange('fullName')}
                      onBlur={handleBlur('fullName')}
                      required
                      value={values?.fullName}
                      sx={{
                        '& label.Mui-focused': {
                          color: 'black',
                        },
                        '& .MuiOutlinedInput-root': {
                          '&.Mui-focused fieldset': {
                            borderColor: 'gray',
                          },
                        },
                      }}
                      variant="outlined"
                    />
                    {(errors.fullName && touched.fullName) ||
                      (values?.fullName?.length > 0 &&
                        values?.fullName?.replace(/ /g, '')?.length === 0) ? (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {values?.fullName?.length > 0 &&
                          values?.fullName?.replace(/ /g, '')?.length === 0
                          ? `${t("profilee.pleaseEnterAValidName")}`
                          : errors.fullName}
                      </span>
                    ) : null}
                    {values?.fullName?.length > 20 && (
                      <span
                        style={{
                          marginTop: '10px',
                          color: 'red',
                          fontSize: '10px',
                        }}
                      >
                        {t("profilee.fullNameShouldnotBeMore20")}
                      </span>
                    )}
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          width: '100%',
                          padding: '20px',
                          paddingRight: '0',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          onClick={() => onClose()}
                          style={{ color: 'black' }}
                        >
                          {t("profilee.cancel")}
                        </Button>
                        <div style={{ width: '30px' }} />
                        <Button
                          loading={loading}
                          type="submit"
                          variant="contained"
                          onClick={handleSubmit}
                          disabled={
                            loading ||
                            values?.fullName?.replace(/ /g, '')?.length === 0 ||
                            values?.fullName?.length > 20
                          }
                          style={{
                            backgroundColor:
                              values?.fullName?.replace(/ /g, '')?.length === 0
                                ? 'grey'
                                : environment.mainColor,
                          }}
                        >
                          {t("profilee.saveInfo")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangeName;
