import React, { useState, useEffect } from 'react';
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Divider,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Checkbox from '@mui/material/Checkbox';

function InnerItemChild({
  modifier,
  modifiersGroup,
  handleChooseModifires,
  option,
  index,
  setOptionsPrice,
  optionsPrice,
  showResults,
  max,
  setMax,
  setErr,
  extraQuantity,
  setExtraQuantity,
  setZeros,
  checkSumM,
  checkSumAdd,
  optionsPriceChild,
  setOptionsPriceChild,
  checkBoxchangeChild,
}) {
  // const [value, setValue] = useState(option?.defaultQuantity);
  const [value, setValue] = useState(option?.defaultQuantity);
  const [state, setState] = useState({});
  const [showChild, setShowChild] = useState(false);
  const [sum, setSum] = useState({});

  return (
    <>
      <Grid item lg={6}>
        <FormControlLabel
          value={option?.title}
          control={
            <Checkbox
              name={option?.title}
              onChange={(e) => {
                checkBoxchangeChild(
                  e,
                  state,
                  setState,
                  option,
                  modifier,
                  value,
                  setValue,
                  setMax,
                  max
                );
                handleChooseModifires(
                  e,
                  option,
                  modifier?.maxPermittedUnique,
                  index,
                  modifier
                );
              }}
              sx={{
                color: green[800],
                '&.Mui-checked': {
                  color: green[600],
                },
              }}
            />
          }
          label={option?.title }
        />
      </Grid>
      {/* quantity of options */}
      {state[option?.title] &&
        modifier?.maxPermitted > modifier?.maxPermittedUnique && (
          <Grid
            item
            lg={6}
            style={{
              display: 'flex',
              justifyContent: 'end',
              // padding: '1rem 2.5rem 1rem 1rem',
            }}
          >
            <Typography
              component="p"
              variant="h5"
              style={{ fontWeight: 'bold' }}
            >
              <div
                style={{
                  display: 'flex',
                  border: '2px solid green',
                  borderRadius: '20px',
                  width: '100%',
                }}
              >
                <div
                  style={{ padding: '5px 10px 5px' }}
                  onClick={() => {
                    if (value > option?.defaultQuantity) {
                      sum[option?.id] = value - 1;
                      checkSumM(sum, option.id, modifier);
                      setValue((v) => v - 1);
                      setOptionsPriceChild((old) =>
                        old - option?.price > 0 ? old - option?.price : 0
                      );

                      if (
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id === option?.id
                        )[0]
                      ) {
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id == option?.id
                        )[0].quantity -= 1;
                        setExtraQuantity(extraQuantity);
                      }
                    }
                  }}
                >
                  <RemoveCircleRoundedIcon
                    style={{
                      color: value > option?.defaultQuantity ? 'green' : 'gray',
                    }}
                  />
                </div>

                <Typography
                  component="a"
                  variant="h6"
                  sx={{
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    display: 'flex',
                    fontWeight: 'bold',
                    marginTop: '3px',
                  }}
                >
                  {value}
                </Typography>
                <div
                  style={{ padding: '5px 10px 5px' }}
                  onClick={() => {
                    if (
                      value < option?.maxPermitted &&
                      modifier?.maxPermitted > value
                    ) {
                      sum[option?.id] = value + 1;
                      checkSumAdd(sum, option.id, modifier);
                      setValue((v) => v + 1);

                      setOptionsPriceChild((old) => old + option?.price);

                      if (
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id === option?.id
                        )[0]
                      ) {
                        extraQuantity[modifier?.id].filter(
                          (opt) => opt?.id === option?.id
                        )[0].quantity += 1;
                        setExtraQuantity(extraQuantity);
                      }
                    }
                  }}
                >
                  <AddCircleOutlinedIcon
                    style={{
                      color:
                        value < option?.maxPermitted &&
                        modifier?.maxPermitted > value
                          ? 'green'
                          : 'gray',
                    }}
                  />
                </div>
              </div>
            </Typography>
          </Grid>
        )}
      {/* <Divider style={{ border: '3px solid #C4C4C4' }} /> */}
      <Grid item lg={12} xl={12} xs={12}>
        {showResults ? (
          <div>
            {option?.childModifiers !== '' && (
              <div>
                {modifiersGroup?.map((m) =>
                  m?.id === option?.childModifiers ? (
                    <div>
                      {m?.minPermitted === 1 && (
                        <div>
                          {' '}
                          <div
                            style={{
                              // backgroundColor: '#E6E6E6',
                              padding: '1rem 2.5rem 1rem 1rem',
                            }}
                          >
                            <Typography
                              component="p"
                              variant="body1"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {m?.title}
                              <br />
                              <span
                                style={{
                                  color: 'gray',
                                  fontWeight: 'normal',
                                }}
                              >
                                (Select 1)
                              </span>
                            </Typography>
                          </div>
                        </div>
                      )}
                      {m?.options?.map((i) => (
                        <div
                          style={{
                            borderBottom: '2px solid lightgray',
                          }}
                        >
                          <FormControl
                            style={{
                              padding: '1rem 2.5rem 1rem 1rem',
                              display: 'flex',
                            }}
                          >
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={value}
                              onChange={(ev) => {
                                setValue(ev.target.value);
                                if (i.childModifiers !== '') {
                                  setShowChild(true);
                                }
                              }}
                            >
                              <FormControlLabel
                                value={i?.title}
                                control={
                                  <Radio
                                    sx={{
                                      color: green[900],
                                      '&.Mui-checked': {
                                        color: green[900],
                                      },
                                    }}
                                  />
                                }
                                label={i?.title}
                              />
                            </RadioGroup>
                          </FormControl>
                          {showChild && (
                            <div>
                              {' '}
                              {modifiersGroup?.map((h) =>
                                h?.id === i?.childModifiers ? (
                                  <div>
                                    {h?.minPermitted === 1 && (
                                      <div>
                                        {' '}
                                        <div
                                          style={{
                                            // backgroundColor: '#E6E6E6',
                                            padding: '1rem 2.5rem 1rem 1rem',
                                          }}
                                        >
                                          <Typography
                                            component="p"
                                            variant="body1"
                                            style={{
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {h.title}
                                            <br />
                                            <span
                                              style={{
                                                color: 'gray',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              (Select 1)
                                            </span>
                                          </Typography>
                                        </div>
                                      </div>
                                    )}
                                    {h?.options?.map((j) => (
                                      <div
                                        style={{
                                          borderBottom: '2px solid lightgray',
                                        }}
                                      >
                                        <FormControl
                                          style={{
                                            padding: '1rem 2.5rem 1rem 1rem',
                                            display: 'flex',
                                          }}
                                        >
                                          <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={handleChange}
                                          >
                                            <FormControlLabel
                                              value={j.title}
                                              control={
                                                <Radio
                                                  sx={{
                                                    color: green[900],
                                                    '&.Mui-checked': {
                                                      color: green[900],
                                                    },
                                                  }}
                                                />
                                              }
                                              label={j.title}
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  ''
                                )
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )
                )}{' '}
              </div>
            )}
          </div>
        ) : null}
      </Grid>
    </>
  );
}

export default InnerItemChild;
