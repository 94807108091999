import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Container,
  useTheme,
  CardContent,
  Card,
  Hidden,
  Divider,
  Button,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import reviewschool from '../../../assets/reviewschool.png';
import { useParams } from 'react-router-dom';
import banner from '../../../assets/banner.png';
import MenuTTdemo from '../../../assets/MenuTTdemo.png';
import menuAlyosef from '../../../assets/alyousef/Menu Image.png';
import TableTuck from '../../../assets/menufilfil.png';
import ttjordanbanner from '../../../assets/ttjordanbanner2.png';

import KeyboardDoubleArrowRightTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowRightTwoTone';
import KeyboardDoubleArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowLeftTwoTone';

import './index.css';
import { useSelector } from 'react-redux';
import CustomizeDialoge from '../../components/CustomizeDialoge/CustomizeDialoge';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import {
  menues,
  res,
  setModifiers,
  setUser,
} from '../../../../../../libs/redux-slices/src';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Utils/UtilsFunctions';
import Loading from 'react-fullscreen-loading';
import { Helmet } from 'react-helmet';
import { FiMinusSquare } from 'react-icons/fi';
import popularImage from './../../../assets/popular.png';
function MenuItems2() {
  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [extraquantity, setExtraquantity] = useState({});
  const [extraQuantity, setExtraQuantity] = useState({});
  const [defs, setDefs] = useState([]);
  const [data, setData] = React.useState([]);
  const [openn, setOpenn] = useState(false);
  const [enableAdd, setEnableAdd] = useState(false);
  const [type, setType] = useState(
    environment?.restaurantId === 'ttJordanDev' ||environment?.restaurantId === 'ttJordan' ? '' : 'Popular'
  );
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [item, setItem] = useState(null);
  const [optionsPrice1, setOptionsPrice1] = useState(0);
  const [storeId, setStoreId] = useState('');
  const [outofStockDelivery, setOutOfStockDelivery] = useState('');
  const navRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();

  const modifiers = useSelector((state) => state.shopReducer.modifiers);
  const userData = useSelector((state) => state.authReducer.userData);
  const menuess = useSelector((state) => state.authReducer.menues);
  const storess = useSelector((state) => state.shopReducer.stores);
  const items = useSelector((state) => state.shopReducer.currentItem);
  const categories = useSelector(
    (state) => state.shopReducer.currentCategories
  );
  const user = useSelector((state) => state.authReducer.userData);
  const userAddresses = useSelector((state) => state.authReducer.userAddresses);
  const stores = useSelector((state) => state.shopReducer.stores);

  const getMenues = async () => {
    try {
      setLoading(true);
      const apiUrl = `${environment?.apiUrl}/getMenu?restaurantId=${environment?.restaurantId}`;

      const response = await fetchData(apiUrl, 'get');
      dispatch(res(response.data));

      if (stores?.length === 1) {
        const updatedUser = {
          ...userData,
          defaultStore: stores[0].id,
        };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(menues(response.data[stores[0]?.id]?.menus));
      } else {
        const updatedUser = {
          ...userData,
          defaultStore: userData.defaultStore,
        };
        dispatch(setUser({ userData: updatedUser }));
        dispatch(menues(response.data[userData.defaultStore]?.menus));
      }
    } catch (error) {
      console.log('message Error:', error.response?.data?.message);
      console.log('FetchData Error:', error);
    }
    setLoading(false);
  };
  const getMenuFirebase = async () => {
    try {
      setLoading(true);
      let menusRef = firestore.collection('menus');
      menusRef = menusRef.where('is_deleted', '==', false);
      // Use the get method to retrieve all documents in the collection
      const querySnapshot = await menusRef.get();

      const mappedItems = querySnapshot.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
      if (stores?.length === 1) {
        const updatedUser = {
          ...userData,
          defaultStore: stores[0].id,
        };
        dispatch(setUser({ userData: updatedUser }));
        const matchMenu = mappedItems.find(
          (item) => item.store_id === stores[0]?.id
        );
        dispatch(menues([...matchMenu]));
      } else {
        const updatedUser = {
          ...userData,
          defaultStore: userData.defaultStore,
        };
        dispatch(setUser({ userData: updatedUser }));
        const matchMenu = mappedItems.find(
          (item) => item.store_id === userData.defaultStore
        );
        dispatch(menues([...matchMenu]));
      }
    } catch (error) {
      console.log('FetchData Error:', error);
    }
    setLoading(false);
  };
  const getModifierss = async () => {
    try {
      let modifiersRef = firestore.collection('modifier_groups');
      modifiersRef = modifiersRef.where('is_deleted', '==', false);
      let list = [];
      const modifierList = await modifiersRef.get();
      for (let index = 0; index < modifierList?.docs?.length; index++) {
        let element = modifierList?.docs[index]?.data();
        element.id = modifierList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setModifiers(list));
    } catch (err) {
      console.log(err);
    }
  };

  const handleNav = (direction) => {
    if (navRef.current) {
      if (direction === 'left') {
        navRef.current.scrollLeft -= 500;
      } else {
        navRef.current.scrollLeft += 500;
      }
    }
  };

  const handleOpenn = () => {
    setId(id);
    setOpenn(true);
    setItem(item);
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  const getStoreDelivery = async () => {
    if (user?.uid && user?.defaultDeliveryAddress != '')
      userAddresses?.map(async (a) => {
        if (a?.id === user?.defaultDeliveryAddress) {
          setStoreId(a?.delivery_store_id);
          storess?.filter((store) => {
            if (store?.id === a?.delivery_store_id) {
              setOutOfStockDelivery(store?.out_of_stock);
            }
          });
        }
      });
  };
  const getDefaults = async () => {
    try {
      setExtraquantity({});
      setDefs([]);
      const item = items.filter((item) => item.id === id)[0];
      const mods = modifiers?.filter((mod) =>
        item?.modifier_group_ids?.includes(mod?.id)
      );

      const perimits = mods?.filter((opt) => opt?.minPermitted != 0);
      if (perimits) {
        let p = 0;
        let o = 0;
        for (const perimite of perimits) {
          for (const opt of perimite?.options) {
            if (opt.isDefault) {
              setOption(opt);
              setExtraquantity((ex) => ({
                ...ex,
                [perimite?.id]: [
                  {
                    customizationMeta: {},
                    defaultQuantity: opt?.minPermitted,
                    id: opt.id,
                    modifierTitle: opt.title,
                    price: opt.price,
                    quantity: 1,
                    title: perimite?.title,
                  },
                ],
              }));
              if (
                perimite?.minPermitted == 1 &&
                perimite?.maxPermittedUnique == 1
              ) {
                p = p + perimite?.options?.find((o) => o.isDefault)?.price;
              }
              if (
                perimite?.minPermitted > 0 &&
                perimite?.maxPermittedUnique != 1
              ) {
                o = o + opt?.price;
              }
              setDefs((defs) =>
                defs?.length
                  ? [...defs, [perimite?.title, opt.title]]
                  : [[perimite?.title, opt.title]]
              );
            }
          }
        }
        setOptionsPrice1(p > 0 ? p : 0);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getModifiers = async () => {
    setData(modifiers);
  };

  const handleOpen = (id, item) => {
    setId(id);
    setOpen(true);
    setItem(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = async (value) => {
    setType(value);
  };

  useEffect(() => {
    getStoreDelivery();
  }, [outofStockDelivery]);

  useEffect(() => {
    if (items?.length) {
      getDefaults();
    }
  }, [id, items?.length]);

  useEffect(() => {
    getModifiers();
    getModifierss();
  }, []);

  useEffect(() => {
    (async () => {
      if (params.id == null) {
        setOpen(false);
      } else {
        let item = items?.find((item) => item?.id === params?.id);
        handleOpen(item?.id, item);
      }
    })();
  }, [params.id]);

  useEffect(() => {
    if (environment?.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan' ) {
      
      const popularItems = categories
        .filter(
          (category) =>
            category.store_id === user.defaultStore || category.id === 'Popular'
        )
        .filter((category) =>
          items.find(
            (item) => category.id === item.category_id && item.is_popular
          )
        );
      const categoryItems = categories
        .filter((category) => category.store_id === user.defaultStore)
        .filter((category) =>
          items.find((item) => category.id === item.category_id)
        );
      if (popularItems.length > 0) {
        setType('Popular');
      } else {
        for (const category of categoryItems) {
          setType(category.id);
          break; // Exit the loop after setting the type
        }
      }
      // }
    } else {
      menuess?.map((c) =>
        c.category_id?.map((category, index) => {
          if (index == 1 && c?.category_id[0]?.items_id?.length == 0) {
            setType(c?.category_id[1]?.id);
          }
        })
      );
    }
  }, [menuess, categories, items]);

  useEffect(() => {
    if (menuess === undefined) {
      setLoading(true);
      if (environment?.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan') {
        getMenuFirebase();
      } else {
        getMenues();
      }
    }
  }, [storess, menuess]);

  const filteredCategories = categories.filter(
    (category) => category.store_id === user.defaultStore
  );
  const filteredItems = items.filter((item) =>
    filteredCategories.some((category) => item.category_id === category.id)
  );
  const popularItems = filteredItems.filter((item) => item.is_popular);
  return (
    <>
      {environment?.restaurantId === 'istanbulDk' && (
        <Helmet>
          <title>Taqwa's Bakery and Restaurant | Menu</title>
        </Helmet>
      )}
      {environment?.restaurantId === 'ttJordanDev' && (
        <Helmet>
          <title>Taqwa's Bakery and Restaurant | Menu</title>
        </Helmet>
      )}
          {environment?.restaurantId === 'ttJordan' && (
        <Helmet>
          <title>Filfil jordan</title>
        </Helmet>
      )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '450px',
          backgroundImage:
            environment.restaurantId === 'alyousef'
              ? `url(${menuAlyosef})`
              : environment.restaurantId === 'filfil'
              ? `url(${TableTuck})`
              : environment.restaurantId === 'ttDemo'
              ? `url(${MenuTTdemo})`
              : environment.restaurantId === 'istanbulDk'
              ? `url(${banner})`
              : environment.restaurantId === 'ttJordanDev'
              ? `url(${ttjordanbanner})`
              : environment.restaurantId === 'ttJordan'
              ? `url(${ttjordanbanner})`
              : `url(${reviewschool})`,

          backgroundRepeat: 'no-repeat',
        }}
      ></div>

      <Container maxWidth={'lg'}>
        <Grid
          container
          style={{
            display: smDown ? 'unset' : 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant={smDown ? 'h4' : 'h2'}
            style={{
              marginTop: '30px',
              fontFamily: `Hanalei Fill, cursive`,
              marginBottom: '20px',
              color: environment.mainColor,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {t('menu.ChooseOurMenu')}
          </Typography>
        </Grid>
      </Container>
      <div className="menu-container">
        <div>
          {filteredCategories?.length > 5 ? (
            <button onClick={() => handleNav('left')}>
              <p className="fa fa-lg fa-chevron-left">
                <KeyboardDoubleArrowLeftTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
          {/* {menuess?.length > 5 ? (
            <button onClick={() => handleNav('left')}>
              <p className="fa fa-lg fa-chevron-left">
                <KeyboardDoubleArrowLeftTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )} */}
        </div>
        <div
          className="nav-items"
          ref={navRef}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: smDown ? 'start' : 'center',
          }}
        >
          {filteredItems?.length > 0 ? (
            categories
              .filter(
                (category) =>
                  category.store_id === user.defaultStore ||
                  category.name === 'Popular'
              )
              .map((category, index) => (
                <Grid
                  item
                  lg={4}
                  style={{
                    display: 'inline-block',
                    color: 'white',
                    textAalign: 'center',
                    padding: '14px',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <Card
                    key={category.id}
                    style={{
                      border: '2px solid lightgray',
                      borderRadius: '20px',
                      borderColor:
                        type === category?.id &&
                        category?.items_id?.length !== 0
                          ? environment.mainColor
                          : 'gray',
                      width: smDown ? '120px' : '140px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleChangeType(category.id);
                      setEnableAdd(false);
                    }}
                  >
                    <CardContent
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {/* {categories
                      .filter((c) => c?.id === category?.id)
                      .map((dataCategory) => ( */}
                      <>
                        <img
                          src={
                            category?.category_image_thumb_url ===
                            'popularImage'
                              ? popularImage
                              : category?.category_image_thumb_url
                          }
                          alt={category?.category_image_thumb_url}
                          style={{ width: '60px', height: '60px' }}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: 'bold',
                            color: 'black',
                          }}
                        >
                          {category.name?.length > 12
                            ? category.name.slice(0, 12)
                            : category.name}
                          {category.name?.length > 12 ? '...' : ''}
                        </Typography>
                      </>
                      {/* // ))} */}
                    </CardContent>
                  </Card>
                </Grid>
              ))
          ) : (
            <Typography
              variant="body1"
              style={{
                color: '#302F3C',
                marginTop: '30px',
                fontFamily: `Hanalei Fill, cursive`,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {t('menu.noCategoryAvaliable')}
            </Typography>
          )}
          {/* {menuess?.map((c) => (
            <div>
              {c.category_id?.length > 0 &&
                c.category_id?.map((category, index) => (
                  <Grid
                    item
                    lg={4}
                    style={{
                      display: 'inline-block',
                      color: 'white',
                      textAalign: 'center',
                      padding: '14px',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <Card
                      key={category.id}
                      style={{
                        border: '2px solid lightgray',
                        borderRadius: '20px',
                        borderColor:
                          type === category?.id && category?.items_id?.length !== 0
                            ? environment.mainColor
                            : 'gray',
                        width: smDown ? '120px' : '140px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleChangeType(category.id);
                        setEnableAdd(false);
                      }}
                    >
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                      {categories.filter((c) => c?.id === category?.id).map((dataCategory=>(
                        <>
                        <img
                            src={dataCategory?.category_image_thumb_url === "popularImage" ? popularImage : dataCategory?.category_image_thumb_url}
                            alt={dataCategory?.category_image_thumb_url}
                            style={{ width: '60px', height: '60px' }}
                            />
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: 'bold',
                                color: 'black',
                              }}
                            >
                              {dataCategory.name?.length > 12
                                ? dataCategory.name.slice(0, 12)
                                : dataCategory.name}
                              {dataCategory.name?.length > 12 ? '...' : ''}
                            </Typography>
                            </>
                          )))}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </div>
          ))} */}
        </div>
        <div>
          {filteredCategories?.length > 5 ? (
            <button onClick={() => handleNav('right')}>
              <p className="fa fa-lg fa-chevron-right">
                <KeyboardDoubleArrowRightTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )}
          {/* {menuess?.length > 5 ? (
            <button onClick={() => handleNav('right')}>
              <p className="fa fa-lg fa-chevron-right">
                <KeyboardDoubleArrowRightTwoToneIcon />
              </p>
            </button>
          ) : (
            ''
          )} */}
        </div>
      </div>
      {filteredItems?.length > 0
        ? categories
            .filter(
              (category) =>
                category.store_id === user.defaultStore ||
                category.id === 'Popular'
            )
            .map((category, index) => (
              <div>
                {category.id === type && (
                  <Container maxWidth={'lg'}>
                    <Grid
                      key={category.id}
                      container
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: smDown ? '-10px' : '0px',
                        marginBottom: smDown ? '30px' : '60px',
                      }}
                    >
                      <Typography
                        variant={'h4'}
                        style={{
                          marginTop: '50px',
                          fontFamily: 'Abel',
                        }}
                      >
                        {category.name}
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '60%',
                          }}
                        >
                          <span
                            className="dot"
                            style={{ backgroundColor: environment.mainColor }}
                          ></span>

                          <Divider
                            style={{
                              marginBottom: '5px',
                              marginTop: '5px',
                              height: '2px',
                              backgroundColor: environment.mainColor,
                              width: '30%',
                              marginLeft: '8px',
                              marginRight: '8px',
                            }}
                          />
                          <span
                            className="dot"
                            style={{ backgroundColor: environment.mainColor }}
                          ></span>
                        </div>
                      </div>
                    </Grid>

                    <div id={category?.id} key={index}>
                      {
                        <>
                          {' '}
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            sx={{
                              display: { sm: 'block', lg: 'flex' },
                            }}
                          >
                            {category?.id === 'Popular'
                              ? popularItems?.length === 0 && (
                                  <Typography
                                    variant="body1"
                                    style={{
                                      textAlign: 'center',
                                      color: '#302F3C',
                                      fontWeight: 'bold',
                                      margin: 'auto',
                                    }}
                                  >
                                    No Popular items available
                                  </Typography>
                                )
                              : items.filter(
                                  (item) =>
                                    category.id === item.category_id ||
                                    (item.store_id === user.defaultStore &&
                                      item.is_popular &&
                                      category.id === 'Popular')
                                )?.length === 0 && (
                                  <Typography
                                    variant="body1"
                                    style={{
                                      textAlign: 'center',
                                      color: '#302F3C',
                                      fontWeight: 'bold',
                                      margin: 'auto',
                                    }}
                                  >
                                    No items available
                                  </Typography>
                                )}
                            {/* {items
                              .filter(
                                (item) =>
                                  category.id === item.category_id ||
                                  (item.store_id === user.defaultStore &&
                                    // category.id === item.category_id &&
                                    item.is_popular &&
                                    category.id === 'Popular')
                              ) */}
                            {category?.id === 'Popular'
                              ? popularItems.map(
                                  (item, index) =>
                                    item.item_state === 'available' && (
                                      <>
                                        {environment?.restaurantId ===
                                        'elmbrookschools' ? (
                                          <Grid
                                            key={item.id}
                                            item
                                            lg={3}
                                            sm={6}
                                            style={{
                                              marginTop: '30px',
                                              marginBottom: '50px',
                                              display: 'grid',
                                            }}
                                          >
                                            <div
                                              onClick={() =>
                                                handleOpen(item?.id, item)
                                              }
                                            ></div>
                                            <div className="menu-item-image-container">
                                              <div
                                                className="menu-item-image-circle"
                                                style={{
                                                  backgroundColor: '#fadcbb',
                                                  borderRadius: '100px',
                                                  width: '200px',
                                                  backgroundImage:
                                                    ' linear-gradient(to top, #FBF1EA, #FBF1EA, #FFFDFC, #FFFDFC, #FFFDFC)',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <img
                                                    src={item?.item_image_url}
                                                    alt="Menu Item"
                                                    style={{
                                                      height: '80%',
                                                      width: '80%',
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '10px',
                                                  }}
                                                >
                                                  <Button
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor: 'white',
                                                      color:
                                                        environment.primaryColor,
                                                      borderRadius: '30px',
                                                    }}
                                                    onClick={() =>
                                                      handleOpen(item?.id, item)
                                                    }
                                                  >
                                                    Add to cart
                                                  </Button>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  width: '64%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                <Typography
                                                  variant="h5"
                                                  style={{
                                                    color:
                                                      environment.primaryColor,
                                                  }}
                                                >
                                                  {item?.name}
                                                </Typography>
                                              </div>
                                            </div>
                                          </Grid>
                                        ) : (
                                          <>
                                            {' '}
                                            <Hidden smDown>
                                              <Grid item sm={6} lg={6}>
                                                <div
                                                  className="menu-item-card"
                                                  onClick={() =>
                                                    handleOpen(item?.id, item)
                                                  }
                                                >
                                                  <div className="menu-item-details">
                                                    <Grid item lg={8} xl={8}>
                                                      <Typography
                                                        variant={'body1'}
                                                        style={{
                                                          fontWeight: 'bold',
                                                          color: '#833720',
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        {item.isCatering ? (
                                                          <>
                                                            <FiMinusSquare
                                                              style={{
                                                                marginTop:
                                                                  '4px',
                                                                marginRight:
                                                                  '3px',
                                                              }}
                                                            />
                                                            {'Catering'}
                                                          </>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </Typography>
                                                    </Grid>
                                                    <h6 className="menu-item-title">
                                                      {item?.name}
                                                    </h6>
                                                    <p className="menu-item-description">
                                                      {item?.description
                                                        ?.length > 99
                                                        ? item?.description.slice(
                                                            0,
                                                            99
                                                          )
                                                        : item?.description}
                                                      {item?.description
                                                        ?.length > 99
                                                        ? '...'
                                                        : ''}
                                                    </p>
                                                  </div>
                                                  <div className="menu-item-image-container">
                                                    <img
                                                      src={item?.item_image_url}
                                                      alt="Menu Item"
                                                      className="menu-item-image"
                                                    />
                                                    <button className="menu-item-button">
                                                      <p
                                                        style={{
                                                          fontSize: '12px',
                                                        }}
                                                      >
                                                        {t('menu.add')}
                                                      </p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </Hidden>
                                            {/* //in mobile */}
                                            {/* Text content */}
                                            <Hidden smUp>
                                              <Grid item sm={12} lg={12}>
                                                <div
                                                  className="menu-item-card2"
                                                  onClick={() =>
                                                    handleOpen(item?.id, item)
                                                  }
                                                  style={{
                                                    paddingBottom: '0px',
                                                  }}
                                                >
                                                  <div className="menu-item-details">
                                                    <h6
                                                      className="menu-item-title"
                                                      style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '.9rem',
                                                        marginTop: '-12px',
                                                      }}
                                                    >
                                                      {item?.name}
                                                    </h6>
                                                    <p
                                                      className="menu-item-description"
                                                      style={{
                                                        marginTop: '10px',
                                                        fontSize: '.6rem',
                                                        paddingBottom: '0px',
                                                        // ====
                                                      }}
                                                    >
                                                      {item?.description
                                                        .length > 99
                                                        ? item?.description.slice(
                                                            0,
                                                            99
                                                          )
                                                        : item?.description}
                                                      {item?.description
                                                        .length > 99
                                                        ? '...'
                                                        : ''}
                                                    </p>
                                                  </div>
                                                  <div className="menu-item-image-container2">
                                                    <img
                                                      src={item?.item_image_url}
                                                      alt="Menu Item"
                                                      className="menu-item-image"
                                                    />
                                                    <button
                                                      className="menu-item-button"
                                                      style={{
                                                        paddingRight: '10px',
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          fontSize: '11px',
                                                        }}
                                                      >
                                                        {t('menu.add')}
                                                      </p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </Hidden>
                                            {/* Image */}
                                            {/* Divider */}
                                            {/* ====== */}
                                          </>
                                        )}

                                        {item?.id === id ? (
                                          <CustomizeDialoge
                                            open={open}
                                            handleClose={handleClose}
                                            option={option}
                                            c={item}
                                            priceToShow={
                                              item?.price + optionsPrice1
                                            }
                                            data={data}
                                            defs={defs}
                                            defaultPrices={optionsPrice1}
                                            extraQuantity={extraQuantity}
                                            setExtraQuantity={setExtraQuantity}
                                            outofStockDelivery={
                                              outofStockDelivery
                                            }
                                            openn={openn}
                                            handleClosee={handleClosee}
                                            handleOpenn={handleOpenn}
                                            enableAdd={enableAdd}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )
                                )
                              : items
                                  .filter(
                                    (item) => category.id === item.category_id
                                  )
                                  .map(
                                    (item, index) =>
                                      // console.log(item,"item")
                                      item.item_state === 'available' && (
                                        <>
                                          {environment?.restaurantId ===
                                          'elmbrookschools' ? (
                                            <Grid
                                              key={item.id}
                                              item
                                              lg={3}
                                              sm={6}
                                              style={{
                                                marginTop: '30px',
                                                marginBottom: '50px',
                                                display: 'grid',
                                              }}
                                            >
                                              <div
                                                onClick={() =>
                                                  handleOpen(item?.id, item)
                                                }
                                              ></div>
                                              <div className="menu-item-image-container">
                                                <div
                                                  className="menu-item-image-circle"
                                                  style={{
                                                    backgroundColor: '#fadcbb',
                                                    borderRadius: '100px',
                                                    width: '200px',
                                                    backgroundImage:
                                                      ' linear-gradient(to top, #FBF1EA, #FBF1EA, #FFFDFC, #FFFDFC, #FFFDFC)',
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                    }}
                                                  >
                                                    <img
                                                      src={item?.item_image_url}
                                                      alt="Menu Item"
                                                      style={{
                                                        height: '80%',
                                                        width: '80%',
                                                      }}
                                                    />
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      marginTop: '10px',
                                                    }}
                                                  >
                                                    <Button
                                                      variant="contained"
                                                      style={{
                                                        backgroundColor:
                                                          'white',
                                                        color:
                                                          environment.primaryColor,
                                                        borderRadius: '30px',
                                                      }}
                                                      onClick={() =>
                                                        handleOpen(
                                                          item?.id,
                                                          item
                                                        )
                                                      }
                                                    >
                                                      Add to cart
                                                    </Button>
                                                  </div>
                                                </div>
                                                <div
                                                  style={{
                                                    width: '64%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  <Typography
                                                    variant="h5"
                                                    style={{
                                                      color:
                                                        environment.primaryColor,
                                                    }}
                                                  >
                                                    {item?.name}
                                                  </Typography>
                                                </div>
                                              </div>
                                            </Grid>
                                          ) : (
                                            <>
                                              {' '}
                                              <Hidden smDown>
                                                <Grid item sm={6} lg={6}>
                                                  <div
                                                    className="menu-item-card"
                                                    onClick={() =>
                                                      handleOpen(item?.id, item)
                                                    }
                                                  >
                                                    <div className="menu-item-details">
                                                      <Grid item lg={8} xl={8}>
                                                        <Typography
                                                          variant={'body1'}
                                                          style={{
                                                            fontWeight: 'bold',
                                                            color: '#833720',
                                                            display: 'flex',
                                                          }}
                                                        >
                                                          {item.isCatering ? (
                                                            <>
                                                              <FiMinusSquare
                                                                style={{
                                                                  marginTop:
                                                                    '4px',
                                                                  marginRight:
                                                                    '3px',
                                                                }}
                                                              />
                                                              {'Catering'}
                                                            </>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </Typography>
                                                      </Grid>
                                                      <h6 className="menu-item-title">
                                                        {item?.name}
                                                      </h6>
                                                      <p className="menu-item-description">
                                                        {item?.description
                                                          ?.length > 99
                                                          ? item?.description.slice(
                                                              0,
                                                              99
                                                            )
                                                          : item?.description}
                                                        {item?.description
                                                          ?.length > 99
                                                          ? '...'
                                                          : ''}
                                                      </p>
                                                    </div>
                                                    <div className="menu-item-image-container">
                                                      <img
                                                        src={
                                                          item?.item_image_url
                                                        }
                                                        alt="Menu Item"
                                                        className="menu-item-image"
                                                      />
                                                      <button className="menu-item-button">
                                                        <p
                                                          style={{
                                                            fontSize: '12px',
                                                          }}
                                                        >
                                                          {t('menu.add')}
                                                        </p>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </Hidden>
                                              {/* //in mobile */}
                                              {/* Text content */}
                                              <Hidden smUp>
                                                <Grid item sm={12} lg={12}>
                                                  <div
                                                    className="menu-item-card2"
                                                    onClick={() =>
                                                      handleOpen(item?.id, item)
                                                    }
                                                    style={{
                                                      paddingBottom: '0px',
                                                    }}
                                                  >
                                                    <div className="menu-item-details">
                                                      <h6
                                                        className="menu-item-title"
                                                        style={{
                                                          fontWeight: 'bold',
                                                          fontSize: '.9rem',
                                                          marginTop: '-12px',
                                                        }}
                                                      >
                                                        {item?.name}
                                                      </h6>
                                                      <p
                                                        className="menu-item-description"
                                                        style={{
                                                          marginTop: '10px',
                                                          fontSize: '.6rem',
                                                          paddingBottom: '0px',
                                                          // ====
                                                        }}
                                                      >
                                                        {item?.description
                                                          .length > 99
                                                          ? item?.description.slice(
                                                              0,
                                                              99
                                                            )
                                                          : item?.description}
                                                        {item?.description
                                                          .length > 99
                                                          ? '...'
                                                          : ''}
                                                      </p>
                                                    </div>
                                                    <div className="menu-item-image-container2">
                                                      <img
                                                        src={
                                                          item?.item_image_url
                                                        }
                                                        alt="Menu Item"
                                                        className="menu-item-image"
                                                      />
                                                      <button
                                                        className="menu-item-button"
                                                        style={{
                                                          paddingRight: '10px',
                                                        }}
                                                      >
                                                        <p
                                                          style={{
                                                            fontSize: '11px',
                                                          }}
                                                        >
                                                          {t('menu.add')}
                                                        </p>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </Grid>
                                              </Hidden>
                                              {/* Image */}
                                              {/* Divider */}
                                              {/* ====== */}
                                            </>
                                          )}

                                          {item?.id === id ? (
                                            <CustomizeDialoge
                                              open={open}
                                              handleClose={handleClose}
                                              option={option}
                                              c={item}
                                              priceToShow={
                                                item?.price + optionsPrice1
                                              }
                                              data={data}
                                              defs={defs}
                                              defaultPrices={optionsPrice1}
                                              extraQuantity={extraQuantity}
                                              setExtraQuantity={
                                                setExtraQuantity
                                              }
                                              outofStockDelivery={
                                                outofStockDelivery
                                              }
                                              openn={openn}
                                              handleClosee={handleClosee}
                                              handleOpenn={handleOpenn}
                                              enableAdd={enableAdd}
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </>
                                      )
                                  )}
                          </Grid>
                        </>
                      }
                    </div>
                  </Container>
                )}
              </div>
            ))
        : ''}

      {/* {menuess?.map((c) => (
        <div>
          {c.category_id?.length > 0 &&
            c.category_id?.map(
              (category, index) =>
                category.id === type && (
                  <Container maxWidth={'lg'}>
                    <Grid
                      key={category.id}
                      container
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: smDown ? '-10px' : '0px',
                        marginBottom: smDown ? '30px' : '60px',
                      }}
                    >
                      {categories
                        .filter((c) => c?.id === category?.id)
                        .map((dataCategory) => (
                          <Typography
                            variant={'h4'}
                            style={{
                              marginTop: '50px',
                              fontFamily: 'Abel',
                            }}
                          >
                            {dataCategory.name}
                          </Typography>
                        ))}
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '60%',
                          }}
                        >
                          <span
                            className="dot"
                            style={{ backgroundColor: environment.mainColor }}
                          ></span>

                          <Divider
                            style={{
                              marginBottom: '5px',
                              marginTop: '5px',
                              height: '2px',
                              backgroundColor: environment.mainColor,
                              width: '30%',
                              marginLeft: '8px',
                              marginRight: '8px',
                            }}
                          />
                          <span
                            className="dot"
                            style={{ backgroundColor: environment.mainColor }}
                          ></span>
                        </div>
                      </div>
                    </Grid>

                    <div id={category?.id} key={index}>
                      {
                        <>
                          {' '}
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            sx={{
                              display: { sm: 'block', lg: 'flex' },
                            }}
                          >
                            {category.items_id?.length > 0 &&
                              items
                                .filter((item) =>
                                  category.items_id.includes(item.id)
                                )
                                .map(
                                  (item, index) =>
                                    item.item_state === 'available' && (
                                      <>
                                        {environment?.restaurantId ===
                                        'elmbrookschools' ? (
                                          <Grid
                                            key={item.id}
                                            item
                                            lg={3}
                                            sm={6}
                                            style={{
                                              marginTop: '30px',
                                              marginBottom: '50px',
                                              display: 'grid',
                                            }}
                                          >
                                            <div
                                              onClick={() =>
                                                handleOpen(item?.id, item)
                                              }
                                            ></div>
                                            <div className="menu-item-image-container">
                                              <div
                                                className="menu-item-image-circle"
                                                style={{
                                                  backgroundColor: '#fadcbb',
                                                  borderRadius: '100px',
                                                  width: '200px',
                                                  backgroundImage:
                                                    ' linear-gradient(to top, #FBF1EA, #FBF1EA, #FFFDFC, #FFFDFC, #FFFDFC)',
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <img
                                                    src={item?.item_image_url}
                                                    alt="Menu Item"
                                                    style={{
                                                      height: '80%',
                                                      width: '80%',
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '10px',
                                                  }}
                                                >
                                                  <Button
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor: 'white',
                                                      color:
                                                        environment.primaryColor,
                                                      borderRadius: '30px',
                                                    }}
                                                    onClick={() =>
                                                      handleOpen(item?.id, item)
                                                    }
                                                  >
                                                    Add to cart
                                                  </Button>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  width: '64%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                <Typography
                                                  variant="h5"
                                                  style={{
                                                    color:
                                                      environment.primaryColor,
                                                  }}
                                                >
                                                  {item?.name}
                                                </Typography>
                                              </div>
                                            </div>
                                          </Grid>
                                        ) : (
                                          <>
                                            {' '}
                                            <Hidden smDown>
                                              <Grid item sm={6} lg={6}>
                                                <div
                                                  className="menu-item-card"
                                                  onClick={() =>
                                                    handleOpen(item?.id, item)
                                                  }
                                                >
                                                  <div className="menu-item-details">
                                                    <Grid item lg={8} xl={8}>
                                                      <Typography
                                                        variant={'body1'}
                                                        style={{
                                                          fontWeight: 'bold',
                                                          color: '#833720',
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        {item.isCatering ? (
                                                          <>
                                                            <FiMinusSquare
                                                              style={{
                                                                marginTop:
                                                                  '4px',
                                                                marginRight:
                                                                  '3px',
                                                              }}
                                                            />
                                                            {'Catering'}
                                                          </>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </Typography>
                                                    </Grid>
                                                    <h6 className="menu-item-title">
                                                      {item?.name}
                                                    </h6>
                                                    <p className="menu-item-description">
                                                      {item?.description
                                                        ?.length > 99
                                                        ? item?.description.slice(
                                                            0,
                                                            99
                                                          )
                                                        : item?.description}
                                                      {item?.description
                                                        ?.length > 99
                                                        ? '...'
                                                        : ''}
                                                    </p>
                                                  </div>
                                                  <div className="menu-item-image-container">
                                                    <img
                                                      src={item?.item_image_url}
                                                      alt="Menu Item"
                                                      className="menu-item-image"
                                                    />
                                                    <button className="menu-item-button">
                                                      <p
                                                        style={{
                                                          fontSize: '12px',
                                                        }}
                                                      >
                                                        {t('menu.add')}
                                                      </p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </Hidden>
                                            {/* //in mobile 
                                            {/* Text content 
                                            <Hidden smUp>
                                              <Grid item sm={12} lg={12}>
                                                <div
                                                  className="menu-item-card2"
                                                  onClick={() =>
                                                    handleOpen(item?.id, item)
                                                  }
                                                  style={{
                                                    paddingBottom: '0px',
                                                  }}
                                                >
                                                  <div className="menu-item-details">
                                                    <h6
                                                      className="menu-item-title"
                                                      style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '.9rem',
                                                        marginTop: '-12px',
                                                      }}
                                                    >
                                                      {item?.name}
                                                    </h6>
                                                    <p
                                                      className="menu-item-description"
                                                      style={{
                                                        marginTop: '10px',
                                                        fontSize: '.6rem',
                                                        paddingBottom: '0px',
                                                        // ====
                                                      }}
                                                    >
                                                      {item?.description
                                                        .length > 99
                                                        ? item?.description.slice(
                                                            0,
                                                            99
                                                          )
                                                        : item?.description}
                                                      {item?.description
                                                        .length > 99
                                                        ? '...'
                                                        : ''}
                                                    </p>
                                                  </div>
                                                  <div className="menu-item-image-container2">
                                                    <img
                                                      src={item?.item_image_url}
                                                      alt="Menu Item"
                                                      className="menu-item-image"
                                                    />
                                                    <button
                                                      className="menu-item-button"
                                                      style={{
                                                        paddingRight: '10px',
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          fontSize: '11px',
                                                        }}
                                                      >
                                                        {t('menu.add')}
                                                      </p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </Hidden>
                                            {/* Image 
                                            {/* Divider 
                                            {/* ====== 
                                          </>
                                        )}

                                        {item?.id === id ? (
                                          <CustomizeDialoge
                                            open={open}
                                            handleClose={handleClose}
                                            option={option}
                                            c={item}
                                            priceToShow={
                                              item?.price + optionsPrice1
                                            }
                                            data={data}
                                            defs={defs}
                                            defaultPrices={optionsPrice1}
                                            extraQuantity={extraQuantity}
                                            setExtraQuantity={setExtraQuantity}
                                            outofStockDelivery={
                                              outofStockDelivery
                                            }
                                            openn={openn}
                                            handleClosee={handleClosee}
                                            handleOpenn={handleOpenn}
                                            enableAdd={enableAdd}
                                          />
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )
                                )}
                          </Grid>
                        </>
                      }
                    </div>
                  </Container>
                )
            )}
        </div>
      ))} */}

      <Loading
        loading={!!loading}
        background="rgba(86, 100, 210, 0.1)"
        loaderColor={environment.mainColor}
      />
    </>
  );
}
export default MenuItems2;
