import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const ProtectedRoutes = () => {
  const location = useLocation();
  const [userID, setUserID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const docid = localStorage.getItem('docid');

  useEffect(() => {
    setUserID(docid);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: '#F7F8FB',
          width: '100%',
          height: '100%',
          marginTop: '2000px',
        }}
      ></div>
    );
  }

  return userID ? (
    <Outlet />
  ) : (
    <Navigate to={'/signup'} state={{ from: location }} />
  );
};

export default ProtectedRoutes;


