import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  licenses: null,
};

const licenSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setlicences: (state, action) => {
      return {
      ...state,
      licenses : action.payload,
    }
  }
}
  
});

export const {
  setlicences
} = licenSlice.actions;
export const licenReducer = licenSlice.reducer;
