import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Container, Typography, Grid } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { environment } from '../../../environments/environment';
import algoliasearch from 'algoliasearch';
import { firestore } from '../../../../../../libs/firebase-service/src';
import RestaurentSearch from './RestaurentSearch';
import RestaurantHomeCard from '../../components/Card/RestaurantHomeCard';
import Loading from 'react-fullscreen-loading';
import { useTranslation } from 'react-i18next';

const PopularRestaurant = () => {
  const navigate = useNavigate();
  const [stores, setStores] = useState();
  const [storesFirebase, setStoresFirebase] = useState();
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [t, i18n] = useTranslation();

  const goToMyLocation = () => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          setCurrentLocation({
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
          console.log('Current Location:', {
            lat: coords?.latitude,
            lng: coords?.longitude,
          });
        },
        async (error) => {
          console.log(error);
          await getStores();
        },
        { enableHighAccuracy: true, timeout: 5000 }
      );
    }
  };

  const getStores = async () => {
    try {
      let storesRef = firestore.collection('stores');
      storesRef = storesRef.where('is_deleted', '==', false);
      const resultData = await storesRef.get();
      const mappedItems = resultData.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
      setStoresFirebase(mappedItems);
      console.log('Stores from Firebase:', mappedItems);
    } catch (err) {
      console.log(err);
    }
  };

  async function getStoresNearUser(userLatitude, userLongitude) {
    
    const client = environment.restaurantId === 'ttJordanDev' ? algoliasearch(
      'AQFFALVPNM',
      '01f30e72eb08a9881f4701717c03599d'
    )
  :
    algoliasearch(
      'BP82AK7SFR',
      '626be990d00969534d3028a74fe84592'
    )
  

    const index = client.initIndex('stores');

    try {
      const result = await index.search('', {
        aroundLatLng: `${userLatitude}, ${userLongitude}`,
        aroundRadius: 'all',
        getRankingInfo: true,
      });

      return result.hits;
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Error fetching stores near user');
    }
  }

  const nearMe = async () => {
    try {
      const storesnearme = await getStoresNearUser(
        currentLocation?.lat,
        currentLocation?.lng
      );
      const filteredStores = storesnearme.filter((store) => !store.is_deleted);
      setStores(filteredStores);
      setLoading(false);
      console.log('Stores near user (filtered):', filteredStores);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    goToMyLocation();
    nearMe();
  }, [currentLocation?.lat]);
  return (
    <>
      <RestaurentSearch
        setLoading={setLoading}
        setStores={setStores}
        setStoresFirebase={setStoresFirebase}
        storesFirebase={storesFirebase}
        getStores={getStores}
        nearMe={nearMe}
      />
      <Container
        sx={{
          marginTop: 10,
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontFamily: 'Playfair Display',
              fontWeight: 900,
              fontSize: '1.5rem',
              lineHeight: '1.969rem',
              color: environment.darkGreyColor,
            }}
          >
            {/* <span style={{ color: environment.mainColor }}>Popular</span>{' '} */}
            {t('restaurants')}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontFamily: 'Poppins',
              fontWeight: 600,
              lineHeight: '1.313rem',
              color: environment.mainColor,
              cursor: 'pointer',
            }}
            onClick={() => navigate('/restaurants')}
          >
            {t('seeAll')}  {i18n.language === 'ar' || i18n.language === 'he' ? <WestIcon/> : <EastIcon />}
          </Typography>
        </Box>
        <Grid container spacing={3}  justifyContent='center'>
          {currentLocation?.lat &&
            (stores?.length > 0 ? (
              stores?.slice(0, 4).map((store) => (
                <Grid
                  item
                  key={store.id}
                  xs={12}
                  sm={6}
                  md={3}
                  style={{
                    cursor: 'pointer',
                    paddingRight: i18n.language === 'ar' || i18n.language === 'he' ? "24px" : "0px",
                    paddingLeft: i18n.language === 'ar' || i18n.language === 'he' ? "0px" : "24px"
                  }}
                >
                  {' '}
                  <RestaurantHomeCard store={store} />
                </Grid>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: environment.darkBlueColor,
                  fontSize: '1.125rem',
                  lineHeight: '1.688rem',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {t('noStoresToDisplay')}
              </Typography>
            ))}

          {!currentLocation?.lat &&
            (storesFirebase?.length > 0 ? (
              storesFirebase?.slice(0, 4).map((store) => (
                <Grid
                  item
                  key={store.id}
                  xs={12}
                  sm={6}
                  md={3}
                  style={{
                    cursor: 'pointer',
                    paddingRight: i18n.language === 'ar' || i18n.language === 'he' ? "24px" : "0px",
                    paddingLeft: i18n.language === 'ar' || i18n.language === 'he' ? "0px" : "24px"
                  }}
                >
                  {' '}
                  <RestaurantHomeCard store={store} />
                </Grid>
              ))
            ) : (
              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: environment.darkBlueColor,
                  fontSize: '1.125rem',
                  lineHeight: '1.688rem',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                {t('noStoresToDisplay')}
              </Typography>
            ))}
        </Grid>
        <Loading
          loading={!!loading}
          background="rgba(86, 100, 210, 0.1)"
          loaderColor={environment.mainColor}
        />
      </Container>
    </>
  );
};

export default PopularRestaurant;
