import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import footer from '../../../assets/background.png';
import { environment } from '../../../environments/environment';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ReviewCard from '../../components/ReviewCard/reviewCard';
import { useTheme } from '@emotion/react';
import axios from 'axios';
import HomeT from '../../../assets/homed.png';
import { Carousel } from 'react-responsive-carousel';
import reviewschool from '../../../assets/reviewschool.png';
import reviewAlyosef from '../../../assets/alyousef/Menu Image.png';
import coma from '../../../assets/coma.png';
import Avatar from '../../components/ReviewCard/Avatar';
import revavatar from '../../../assets/revavatar.png';

function ReviewSection() {
  const [t] = useTranslation();
  const theme = useTheme();
  const data = [0, 1];
  const [reviews, setReviews] = useState([]);
  const [slide, setSlide] = useState(0);
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const fetchGoogleReviews = async () => {
      const apiKey = 'AIzaSyCjXKmwz0IWhznLipLL8OOqJs579HNYvqw';
      const placeId = 'ChIJU6UxHEERBYgRg1c-t-QA3Hc';
      const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apiKey}`;

      try {
        const response = await axios.get(url);
        const reviewsData = response.data.result.reviews;
        setReviews(reviewsData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchGoogleReviews();
  }, [reviews]);

  return (
    <>
      {' '}
      {environment.restaurantId === 'istanbulDk' ||
      environment.restaurantId === 'ttDemo' ||
      environment.restaurantId === 'ttJordanDev'  ||
      environment.restaurantId === 'ttJordan' ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              marginTop: '60px',
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: environment.mainColor,
                fontWeight: 'bold',
                fontFamily: `Hanalei Fill, cursive`,
              }}
            >
              {t('googleRevie')}
            </Typography>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '60%',
              }}
            >
              <span
                className="dot"
                style={{ backgroundColor: environment.mainColor }}
              ></span>

              <Divider
                style={{
                  marginBottom: '5px',
                  marginTop: '5px',
                  height: '2px',
                  backgroundColor: environment.mainColor,
                  width: '50%',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              />
              <span
                className="dot"
                style={{ backgroundColor: environment.mainColor }}
              ></span>
            </div>
          </div>
          <div style={{ height: '100px' }} />
          <div
            style={{
              position: 'relative',
              backgroundImage: `url(${footer})`,
            }}
          >
            <Container maxWidth={'lg'}>
              <Grid container spacing={xsDown ? 0 : 5}>
                <Grid
                  container
                  spacing={xsDown ? 0 : 5}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {(!xsDown ? reviews : reviews.splice(0, 3)).map(
                    (r, index) => {
                      return (
                        <>
                          {slide === 0 && index <= 2 && r.rating == 5 && (
                            <Grid
                              item
                              key={r.name}
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                            >
                              <ReviewCard review={r} />
                            </Grid>
                          )}
                          {slide === 1 &&
                            index >= 3 &&
                            index <= 5 &&
                            r.rating == 5 && (
                              <Grid
                                item
                                key={r.name}
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={4}
                              >
                                <ReviewCard review={r} />
                              </Grid>
                            )}
                        </>
                      );
                    }
                  )}
                </Grid>
              </Grid>
              <div style={{ height: '50px' }} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    marginTop: '10px',
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: '10px',
                    width: ' 100%',
                  }}
                >
                  {data?.map((item, index) => {
                    return (
                      <span
                        className="dot"
                        style={{
                          cursor: 'pointer',
                          height: '8px',
                          width: '8px',
                          margin: ' 0 2px',
                          backgroundColor: slide == index ? 'gray' : '#bbb',
                          borderRadius: '50%',
                          display: 'inline-block',
                          transition: ' background-color 0.6s ease',
                        }}
                        key={index}
                        onClick={(e) => {
                          setSlide(index);
                          // setChange(!change);
                        }}
                      ></span>
                    );
                  })}
                </div>
              </div>
            </Container>
          </div>
          <Grid
            style={{
              position: 'relative',
              backgroundImage: `url(${HomeT})`,
            }}
            sx={{ height: { md: '100%', lg: '35rem' } }}
          >
            <div
              style={{
                paddingTop: '5rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontFamily: `Hanalei Fill, cursive`,
                  }}
                >
                  {t('catering')}
                </Typography>
              </div>

              <br />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '50%',
                  }}
                >
                  <Typography variant="h5" style={{ color: 'white' }}>
                    {t('cateringDetails')}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '50%',
                    marginTop: '18px',
                    marginBottom: '20px',
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: environment.mainColor,
                      color: ' white',
                      borderRadius: '20px',
                      direction: 'initial',
                    }}
                  >
                    <Typography variant="h5" style={{ color: 'white' }}>
                      <LocalPhoneRoundedIcon color="white" />
                      <a
                        href={`tel:${environment.phoneNumber}`}
                        style={{ cursor: 'pointer' }}
                      >
                        {' '}
                        {environment.phoneNumber}
                      </a>
                    </Typography>
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </>
      ) : (
        <Grid direction="row" display="flex">
          <Carousel
            width={'100%'}
            showArrows={false}
            infiniteLoop={xsDown ? true : false}
            autoPlay={xsDown ? true : false}
            showThumbs={false}
            showStatus={false}
            showIndicators={xsDown ? false : true}
            verticalSwipe="standard"
          >
            <div style={{ position: 'relative' }}>
              <img
                src={
                  environment.restaurantId === 'alyousef'
                    ? reviewAlyosef
                    : reviewschool
                }
                alt="home"
                style={{
                  height: xsDown ? '500px' : '550px',
                  width: '100%',
                }}
              />
              <div className="centered">
                <Grid
                  item
                  xs={4}
                  direction="row"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Divider
                    style={{
                      height: '2px',
                      backgroundColor: environment.primaryColor,
                      width: '10%',
                    }}
                  />
                </Grid>
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                  }}
                >
                  Testimonial
                </Typography>
                <Grid
                  item
                  xs={4}
                  direction="row"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    justifyContent: 'center',
                  }}
                >
                  <Divider
                    style={{
                      height: '2px',
                      backgroundColor: environment.primaryColor,
                      width: '10%',
                    }}
                  />
                </Grid>
                <Typography
                  variant="h2"
                  style={{
                    color: 'white',

                    fontFamily: `Cormorant Infant`,
                  }}
                  sx={{
                    fontSize: { xs: '40px', md: '60px', lg: '40px' },
                    marginBottom: '10px',
                  }}
                >
                  What Our Clients Say
                </Typography>
                <img src={coma} alt="coma" style={{ width: '20px' }} />
                <Typography
                  variant="h6"
                  style={{
                    color: 'white',
                    fontFamily: `Cormorant Infant`,
                  }}
                >
                  "Leverage agile frameworks to provide a robust synopsis for
                  high level overviews. Iterative approaches to corporate
                  strategy foster collaborative thinking further overall value
                  proposition organicallfriendly.
                </Typography>
                <br />
                <Grid
                  item
                  xs={4}
                  direction="row"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Avatar name={'test'} profilePicture={revavatar} size={100} />
                </Grid>
              </div>
            </div>
          </Carousel>
        </Grid>
      )}
    </>
  );
}
export default ReviewSection;
