import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import { Button, Grid, TextField } from '@mui/material';
import ExpiryInput from './ExpiryInput';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { useSelector } from 'react-redux';

const CreditCardForm = ({ paymentFlow, onConfirmClover, handleClose }) => {
  const initialValues = {
    number: '',
    name: '',
    expiry: '',
    cvv: '',
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [err, setErr] = useState('');
  const user = useSelector((state) => state.authReducer.userData);

  const validationSchema = Yup.object().shape({
    number: Yup.number()
      .required(' Card number is required')
      .typeError('Please enter a valid card number'),
    expiry: Yup.string().required('Expiry Date is required'),
    cvv: Yup.number()
      .required('CVC is required')
      .typeError('Please enter a valid cvc number'),
  });

  const handleSubmit = async (values) => {

    setErr('');
    const firstSix = values.number;
    const firstSixNumbers = firstSix.substring(0, 6);
    const expirydate = values.expiry;
    const expiryMonth = expirydate.substring(0, 2);
    const expiryYear = '20' + expirydate.substring(expirydate.length - 2);
    const lastFourNumbers = firstSix.substring(firstSix.length - 4);
    try {
      setLoading(true);
     await axios
        .post(
          `${environment?.apiUrl}/ccToken?restaurantId=${environment.restaurantId}`,
          {
            exp_month: expiryMonth,
            exp_year: expiryYear,
            cvv: values?.cvv,
            last4: lastFourNumbers,
            first6: firstSixNumbers,
            number: values?.number,
          }
        )
        .then((result) => {
          if (result.status === 201) {
            console.warn('verified');
            setLoading(false);
            const c = result?.data.id;

            onConfirmClover(c);
            handleClose();
          } else {
            // setErr(result.data.eror.message);
            console.warn(result);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error(e.response?.data?.message ?? 'Something went wrong.');
          setErr('Please enter a valid data!');
          setLoading(false);
        });
    } catch (e) {
      setError(e.message);
      console.log(e.message);
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        number: '',
        name: '',
        expiry: '',
        cvv: '',
      }}
      onSubmit={handleSubmit}
    >
      {({
        handleBlur,
        handleChange,
        values,
        errors,
        handleSubmit,
        touched,
      }) => (
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Cards
            number={values.number}
            name={user?.fullName}
            cvc={values.cvv}
          />
          <Grid item xs={12}>
            <TextField
              fullWidth
              helperText=""
              label={'Card number'}
              name="number"
              inputProps={{
                maxLength: 16,
              }}
              onChange={handleChange('number')}
              onBlur={handleBlur('number')}
              required
              value={values.number}
              sx={{
                '& label.Mui-focused': {
                  color: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'gray',
                  },
                },
              }}
              variant="outlined"
            />
            {errors.number && touched.number ? (
              <span
                style={{
                  marginTop: '10px',
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {errors.number}
              </span>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <div>
              <Field
                type="text"
                name="expiry"
                placeholder="Expiry Date"
                component={ExpiryInput}
                value={values.expiry}
              />
            </div>

            {errors.expiry && touched.expiry ? (
              <span
                style={{
                  marginTop: '10px',
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {errors.expiry}
              </span>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              helperText=""
              label={'CVC'}
              name="cvv"
              inputProps={{
                maxLength: 3,
              }}
              onChange={handleChange('cvv')}
              onBlur={handleBlur('cvv')}
              required
              value={values.cvv}
              sx={{
                '& label.Mui-focused': {
                  color: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'gray',
                  },
                },
              }}
              variant="outlined"
            />
            {errors.cvv && touched.cvv ? (
              <span
                style={{
                  marginTop: '10px',
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {errors.cvv}
              </span>
            ) : null}
          </Grid>
          <Grid item container xs={12}>
          <p style={{color:'red'}}>{err}</p>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                  paddingRight: '0',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={{ width: '30px' }} />
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor:
                      values?.fullName?.replace(/ /g, '')?.length === 0
                        ? 'grey'
                        : '#A01913',
                  }}
                >
                  Place order
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default CreditCardForm;
