import { Box, Grid, Hidden, Typography, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import backgroundHomeSchool from '../../../assets/backgroundhomeschool.png';
import footer from '../../../assets/background.png';
import { environment } from '../../../environments/environment';
import chooseus from '../../../assets/chooseusschool.png';
import truck from '../../../assets/truck.png';
import time from '../../../assets/time.png';
import quality from '../../../assets/quality.png';
import coffee from '../../../assets/coffee.png';
import { useTheme } from '@emotion/react';

function ChooseUs() {
  const [t] = useTranslation();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
  <div
    style={{
      position: 'relative',
      backgroundImage:
        environment.restaurantId === 'elmbrookschools' ||
        environment.restaurantId === 'alyousef' || environment.restaurantId === 'filfil' 
          ? `url(${backgroundHomeSchool})`
          : `url(${footer})`,
      paddingTop: '90px',
    }}
    >
      <Container maxWidth={'xl'}>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {t('aboutHomeDetails') && (
            <>
              <Grid item lg={5} sm={12}>
                <Hidden mdDown>
                  <img
                    loading="lazy"
                    src={chooseus}
                    alt="loading..."
                    style={{ width: '80%', height: '80%' }}
                  />
                </Hidden>
              </Grid>

              <Grid item lg={6} sm={12} style={{ alignItems: 'center' }}>
                <Typography
                  variant="h5"
                  style={{
                    color: environment.primaryColor,
                    fontWeight: 900,
                    fontFamily: `cursive`,
                    fontSize: '30px',
                  }}
                >
                  Why choose us ?
                </Typography>
                <Grid
                  item
                  xs={4}
                  direction="row"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                ></Grid>
                <Typography
                  variant="h2"
                  style={{
                    color: environment.mainColor,

                    fontFamily: `Cormorant Infant`,
                  }}
                  sx={{
                    fontSize: { xs: '40px', md: '60px', lg: '40px' },
                    marginBottom: '10px',
                  }}
                >
                  Why we are the best ?
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    color: 'gray',
                    fontFamily: `Cormorant Infant`,
                  }}
                >

                {environment.restaurantId === 'alyousef'
                  ? `With our commitment to quality and the use of fresh ingredients, we deliver exceptional meals that are both tasty and wholesome. From traditional Middle Eastern delicacies to international favorites, our menu is designed to cater to a diverse range of tastes.`
                  : `Because our Café isn't just a place to grab a cup of coffee; it's a tribute to the school's extraordinary heritage. We take pride in serving not just great coffee, but the history and values that our founder instilled in our institution. When you choose us, you're not just choosing a coffee; you're choosing to be a part of a legacy.`}
              </Typography>
              <br />
              <Typography
                variant="h6"
                style={{
                  color: 'gray',
                  fontFamily: `Cormorant Infant`,
                }}
                >

                {environment.restaurantId === 'alyousef'
                  ? ` But don't take our word for it - our customers have spoken! With positive reviews on Google, Yelp, Facebook, Door Dash, and Grub Hub, we strive to exceed expectations and make every visit a delightful one.`
                  : ` With every sip, you embrace our commitment to excellence,
                education, and a sense of community that's been our guiding
                light for generations. Join us in experiencing the past,
                present, and future, all in a single cup`}
                </Typography>
                <br />
                <Box sx={{ width: '100%' }}>
                  <Grid
                    container
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                  >
                    <Grid
                      item
                      md={5}
                      sm={6}
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        justifyContent: 'flex-start',
                        width: mdDown && '230px',
                      }}
                    >
                      <img
                        loading="lazy"
                        src={truck}
                        alt="loading..."
                        style={{ marginLeft: '20px', marginRight: '20px' }}
                      />
                      Fast Delivery
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={6}
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        justifyContent: 'flex-start',
                        width: mdDown && '230px',
                      }}
                    >
                      <img
                        loading="lazy"
                        src={time}
                        alt="loading..."
                        style={{ marginLeft: '20px', marginRight: '20px' }}
                      />
                      24/7 services
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={6}
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        justifyContent: 'flex-start',
                        width: mdDown && '230px',
                      }}
                    >
                      <img
                        loading="lazy"
                        src={coffee}
                        alt="loading..."
                        style={{ marginLeft: '20px', marginRight: '20px' }}
                      />
                      Fresh Coffee
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={6}
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        justifyContent: 'flex-start',
                        width: mdDown && '230px',
                      }}
                    >
                      <img
                        loading="lazy"
                        src={quality}
                        alt="loading..."
                        style={{ marginLeft: '20px', marginRight: '20px' }}
                      />
                      Quality maintain
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
}
export default ChooseUs;
