import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import React, { useEffect, useState } from 'react';
import ar from 'react-phone-number-input/locale/ar';
import en from 'react-phone-number-input/locale/en';

import { useTranslation } from 'react-i18next';
import { getLocation } from '../../Utils/UtilsFunctions';

const PhoneInternational = ({
  phoneNumber,
  setPhoneNumber,
  backgroundColor = '#f2f2f2',
}) => {
  const [t, i18n] = useTranslation();
  const [countrycode, setcountrycode] = useState('');
  const labels = i18n.language === 'ar' ? ar : en;

  const phoneInputStyle = {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: `1px solid gray`,
    marginTop: '5px',
    backgroundColor: backgroundColor,
  };

  const countryOptionsOrder = ['JO', 'US', 'EG'];
  const defaultcountry = countrycode ?? 'JO';

  const fetchLocation = async () => {
    const locationrespond = await getLocation();
    console.log("locations respond",locationrespond)
    setcountrycode(locationrespond);
  };
  useEffect(() => {
    fetchLocation();
  }, []);

  return (
    <PhoneInput
      labels={labels}
      international
      countryCallingCodeEditable={false}
      defaultCountry={defaultcountry}
      value={phoneNumber}
      onChange={setPhoneNumber}
      countryOptionsOrder={countryOptionsOrder}
      style={{ ...phoneInputStyle }}

      //   countries={certaincountries}
      //   inputComponent={CustomPhoneNumberInput}
    />
  );
};

export default PhoneInternational;

const CustomPhoneNumberInput = React.forwardRef(
  ({ value, onChange, onFocus, onBlur, ...rest }, ref) => {
    const inputStyle = {
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      backgroundColor: '#f2f2f2',
    };

    return (
      <input
        type="tel"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        style={inputStyle}
        {...rest}
      />
    );
  }
);
