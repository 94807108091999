import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import {
  firestore,
  RootFirestore,
} from '../../../../../../libs/firebase-service/src';
import { environment } from '../../../environments/environment';

const PrivacyPolicy = () => {
  const [privacy, setPrivacy] = useState([]);
  const [text, setText] = useState('');
  const getItems = async () => {
    const doc = await RootFirestore.collection('privacy').doc('privacy').get();
    try {
      let ItemsRef = RootFirestore.collection('privacy').doc('privacy');
      let list = [];
      await ItemsRef.get().then((c) => {
        if (c?.exists) {
          console.log(c?.data());
          setPrivacy(c?.data());
          list = c?.data();
        }
      });

      setPrivacy(list);
    } catch (err) {
      // console.log(err);
    }

    // setItems(items);
  };
  useEffect(() => {
    getItems();
  }, [privacy]);
  const res = environment?.headerTitle;
  return (
    <>
      <div style={{ width: '100%', background: 'rgb(235,235,235)' }}>
        <div style={{ marginTop: '82px' }} />
        <Typography
          variant={'h4'}
          black
          style={{
            textAlign: 'center',
            padding: '20px',
            fontWeight: 'bold',
            marginTop: '20px',
          }}
        >
          {'Privacy Policy'}
        </Typography>
      </div>
      <Container fixed maxWidth={'md'}>
        <div style={{ padding: '10vh 16px' }}>
          <Typography variant={'body1'} medium style={{ fontWeight: 'bold' }}>
            Effective: 7th Aug 2023
          </Typography>
          <div style={{ height: '24px' }} />

          <Typography
            variant={'body1'}
            regular
            style={{ lineHeight: '1.5', marginTop: '10px' }}
          >
            {privacy.privacy?.map((c) =>
              c.body?.map((a, index) => {
                // Replace the word 'oldWord' with 'newWord' in the text
                const modifiedText = a.replace('<RESTAURANT_NAME>', res);

                return (
                  <div key={index} style={{ marginBottom: '12px' }}>
                    {modifiedText}
                  </div>
                );
              })
            )}
          </Typography>

          <div style={{ height: '48px' }} />

          <div style={{ height: '48px' }} />
        </div>
      </Container>
      <div
        style={{ height: '1px', width: '100%', background: 'rgb(220,220,220)' }}
      />
    </>
  );
};

export default PrivacyPolicy;
