import React from 'react';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Formik } from 'formik';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export function Toolbar({
  searchMethod,
  addPage,
  buttonText,
  helperText,
  color,
  careers,
  sort,
  sortText,
  marginRight,
  width,
  disableAddButton,
  filterStores,
  handleFilterstoreChange,
  showFilterStores,
  allStores,
  ...props
}) {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box>
    <div style={{ height: '20px' }} />
    <Box sx={{ mt: 3 }}>
      <Box sx={{ maxWidth: '100%' }}>
        <div
          style={{
            display: !xsDown ? 'flex' : 'initial',
            justifyContent: !xsDown ? 'space-between' : 'center',
            alignItems: 'center', 
          }}
        >
          <Formik
            initialValues={{
              searchValue: '',
            }}
            onSubmit={(values) => {
              searchMethod(values.searchValue);
            }}
          >
            {({ handleBlur, handleChange, errors, values, isValid }) => (
              <TextField
                fullWidth
                style={{
                  width: width && !xsDown ? width : !xsDown ? '60%' : '60%',
                  marginBottom: '10px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <Search />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* <Button
                        variant="text"
                        disabled={!!errors.searchValue || !isValid}
                        onClick={() => {}}
                        style={{ color: 'black' }}
                      >
                        Search
                      </Button> */}
                    </InputAdornment>
                  ),
                }}
                name="searchValue"
                onChange={(e) => {
                  handleChange(e); // Update Formik's state
                  searchMethod(e.target.value); // Call searchMethod with the new value
                }}
                type="text"
                onKeyDown={async (e) => {
                  const code = e.keyCode ? e.keyCode : e.which;
                  // on press enter
                  if (code === 13 && isValid) {
                    e.preventDefault();
                    searchMethod(values.searchValue);
                  }
                }}
                onBlur={handleBlur('searchValue')}
                placeholder={helperText}
                variant="outlined"
                // helperText={'Press Enter to search'}
  
                value={values.searchValue}
                sx={{
                  '& label.Mui-focused': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                    fontSize: !xsDown ? '0.8rem' : '1rem',
                  },
                }}
              />
            )}
          </Formik>
          {showFilterStores && (
            <FormControl sx={{
              height: '56px', 
              width: width && !xsDown ? width : !xsDown ? '40%' : '40%',
              marginBottom: '10px',
              marginLeft: '10px', 
            }}>
              <InputLabel id="store-label">Store</InputLabel>
              <Select
                labelId="store-label"
                value={filterStores}
                onChange={handleFilterstoreChange}
                label="Store"
              >
                <MenuItem value="All">All Stores</MenuItem>
                {allStores?.map((store) => (
                  <MenuItem key={store.id} value={store.id}>{store.restaurant_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box>
            {!xsDown && addPage && !careers && !disableAddButton && (
              <Button
                onClick={addPage}
                variant="contained"
                style={{
                  color: 'white',
                  backgroundColor: color,
                  fontWeight: 'bold',
                  marginRight: marginRight,
                  marginBottom: '6px',
                  marginLeft: '6px',
                }}
              >
                {buttonText}
              </Button>
            )}
            {sort && (
              <Button
                onClick={sort}
                variant="contained"
                style={{
                  color: 'white',
                  backgroundColor: color,
                  fontWeight: 'bold',
                  marginBottom: '6px',
                  marginLeft: '6px',
                }}
              >
                {sortText}
              </Button>
            )}
          </Box>
        </div>
      </Box>
      {xsDown && addPage && (
        <div>
          <Box>
            <Button
              onClick={addPage}
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: color,
                width: '100%',
                height: '55px',
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </div>
      )}
    </Box>
  </Box>
  );
}

export default Toolbar;
