import MaskedInput from 'react-text-mask';
import React from 'react';

import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { environment } from '../../../environments/environment';
const useStyles = makeStyles({
  root: {
    '& input': {
      boxSizing: 'border-box',
      height: '48px !important',
    },
    '& .MuiInputBase-root': {
      boxSizing: 'border-box',
      height: '48px !important',
    },
  },
  error: {
    '& input': {
      borderColor: 'red',
      boxShadow: `red`,
    },
  },
});

const CustomPhoneNumber = (props) => {
  const classes = useStyles();

  return (
    <MaskedInput
      {...props}
      mask={
        environment.restaurantId === 'ttJordanDev' || environment.restaurantId === 'ttJordan'
          ? [
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
            ]
          : [
              '(',
              /[1-9]/,
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]
      }
      placeholderChar={'\u2000'}
      guide={false}
      render={(ref, inputProps) => {
        return (
          <TextField
            className={classes.root}
            {...props}
            inputRef={ref}
            {...inputProps}
          />
        );
      }}
    />
  );
};
export default CustomPhoneNumber;
