import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CustomButton } from '@tabletuck/components';
import { Navigate, useNavigate } from 'react-router-dom';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { environment } from '../../../environments/environment';
import { auth } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';

function SuccessDialog({ open, setOpenSuccess }) {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();


  const navigateToTheHistory = () => {
    navigate(`/profile/${auth?.currentUser?.uid}?type=order`);
  };

  return (
    <Dialog open={open} onClose={navigateToTheHistory} maxWidth={'sm'}>
      <DialogTitle>
        <div
          style={{
            position: 'relative',
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '25px',
          }}
        >
          {' '}
          <CancelRoundedIcon
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              cursor: 'pointer',
              marginLeft: '15px',
              color: 'rgb(160, 25, 19)',
            }}
            onClick={navigateToTheHistory}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            alt="category"
            width={'50px'}
            height={'50px'}
            src={require('./success-icon-23194.png')}
          />
          <Typography
            variant="h5"
            style={{
              margin: 20,
              color: 'gray',
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            {t('confirmPage.Yourorderhasbeenaccepted')}
          </Typography>

          <Typography
            variant="body1"
            style={{
              color: 'gray',
              alignSelf: 'center',
              textAlign: 'center',
              marginTop: '2px',
              marginBottom: '7px',
            }}
          >
            {t('confirmPage.Totrackyourordercheckyourorderhistory')}
          </Typography>
          <CustomButton
            buttonText={t(`confirmPage.Takemetoordershistory`)}
            onClick={navigateToTheHistory}
            bg={environment.mainColor}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessDialog;
