import React from "react";
// import Text from "../../data-display/text"
import StarRating from "./star-rating";
import Avatar from "./Avatar";
import { Typography } from "@mui/material";
import { environment } from "../../../environments/environment";
import ReadMore from "./read-more";
const ReviewCard = ({ review }) => {

    return (
        <div style={{ background: environment.mainColor, boxShadow: "0 8px 46px -14px rgba(0,0,0,0.07)", borderRadius: '14px', padding: "18px 20px" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Avatar name={review?.author_name} profilePicture={review?.profile_photo_url} size={32} />
                <div style={{ marginLeft: "8px", lineHeight: "1.0" }}>
                    <Typography variant={"body2"} medium style={{ color: "white" }}>
                        {review?.author_name}
                    </Typography>
                    <Typography variant={"caption"} regular style={{ opacity: "0.8", lineHeight: "1.1", color: "white" }}>
                        {review?.relative_time_description}
                    </Typography>
                </div>
                <div style={{ marginLeft: "auto" }} />
                <StarRating totalAverage={review?.rating} size={18} />
            </div>
            <div style={{ height: "12px" }} />
            <div style={{ height: "4px" }} />
            <Typography variant={"body2"} style={{ color: "white" }} regular>
                {review?.text?.length > 90 ?
                    <ReadMore maxLength={90}>{review?.text}</ReadMore> :
                    review?.text?.trim()
                 }
            </Typography>
        </div>
    )
}

export default ReviewCard;