import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { environment } from '../../../environments/environment';


const FeesDialog = ({
  opentaxes,
  setOpentaxes,
  orderType,
  serviceFees,
  dataStore,
  total,
  data,
}) => {
  const [t] = useTranslation();

  return (
    <Dialog
      open={opentaxes}
      onClose={() => setOpentaxes(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {' '}
        <Typography
          component="p"
          variant="h6"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {orderType === 'pickup' || orderType === 'dinein' ? (
            <span>{t('basket.estTaxesAndFees')}</span>
          ) : (
            <span>{t('basket.estTaxes')}</span>
          )}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {(orderType === 'pickup' || orderType === 'dinein') && (
            <div>
              <span style={{ color: 'black' }}>
                {' '}
                {t('basket.serviceFees')} {environment.currency}
                {orderType === 'dinein'
                  ? dataStore?.service_fee?.dineIn.toFixed(2)
                  : orderType === 'pickup' &&
                    dataStore?.service_fee?.pickUp.toFixed(2)}
              </span>
              <br />
              {t('basket.thisServiceFeesHelpsUsOperateTheOnlineOrderingSystem')}
              <br />
            </div>
          )}
          <br />
          <span style={{ color: 'black' }}>
            {' '}
            {t('basket.estTaxes')} : {environment.currency}
            {dataStore?.tax?.type === 'amount' ? (
              <>
                {' '}
                {orderType === 'dinein' || orderType === 'pickup'
                  ? dataStore?.tax?.tax.toFixed(2)
                  : orderType === 'delivery'
                  ? data?.tax?.tax.toFixed(2)
                  : dataStore?.tax?.tax.toFixed(2)}
              </>
            ) : (
              <>
                {''}
                {orderType === 'dinein' || orderType === 'pickup'
                  ? (dataStore?.tax?.tax * total).toFixed(2)
                  : orderType === 'delivery' &&
                    (dataStore?.tax?.tax * total).toFixed(2)}
              </>
            )}{' '}
          </span>
          <br />
          {t('basket.finalisedTaxWillBeShownOnYourOrderReceipt')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpentaxes(false)}
          color="primary"
          style={{
            backgroundColor: environment.mainColor,
            color: 'white',
          }}
        >
          {t('basket.oK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeesDialog;
