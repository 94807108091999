export const environment = {
    production: false,
    restaurantId: 'ttJordan',
    firebaseConfig: {
        apiKey: "AIzaSyD5WRl76pFb9zlC8_1Byzllsz6YCICzhw4",
        authDomain: "tt-jordan.firebaseapp.com",
        projectId: "tt-jordan",
        storageBucket: "tt-jordan.appspot.com",
        messagingSenderId: "770047830861",
        appId: "1:770047830861:web:e6da7578372b6243d8e504",
        measurementId: "G-KBEK3088LL"
    },
    headerTitle: 'Filfil Jordan',
    apiUrl: 'https://us-central1-wl-restaurants.cloudfunctions.net/apiv3',
    mainColor: '#FF6838',
    facebookLink: '',
    twitterLink: '',
    instagramLink: '',
    yelpLink:
      'https://www.yelp.com/biz/taqwas-bakery-and-restaurant-greenfield?osq=Taqwa',
    phoneNumber: '+1 (414) 550 55 55',
    address: '4651 S 27th St, Greenfield, WI 53221',
    email: 'info@TT.com',
    about: `At a young age, the founders of TT Jordan & Restaurant
      discovered the importance of having the right ingredients to
      create the perfect recipe. Taqwa and her husband, the
      restaurant founders, collaborated and utilized their
      techniques to craft delicious Middle Eastern cuisine that
      delighted their loved ones.`,
  
    isRoot: false,
    primaryColor: '#C7A17A',
    secoundaryColor: '#FAFAFA',
    darkGreyColor: '#302F3C',
    darkBlueColor: '#172B4D',
    grayBlueColor: '#7A869A',
    darkgrayBlueColor: '#323643',
    footerColor: '#2B2B2B',
    shapeBlueColor: '#42526E',
    appGooglePlayLink: 'https://play.google.com/store/apps/details?id=com.zetaton.wl.filfil&pli=1',
    driverGooglePlayLink : 'https://play.google.com/store/apps/details?id=com.zetaton.driver.filfil',
    appleStoreLink: '',
    currency: ' JD ',
  };
  