import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  Grid,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { firestore } from '@tabletuck/firebase-services';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MenuCard } from '../../../../../../libs/components/src';
import { environment } from '../../../environments/environment';
import { auth } from '../../../../../../libs/firebase-service/src';
import { useTranslation } from 'react-i18next';

function FavoriteItems() {
  const theme = useTheme();
  const navigate = useNavigate();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  // const [items, setItems] = useState([]);
  // const [user, setUser] = useState();
  const [fav, setFav] = useState([]);
  const [name, setName] = useState([]);
  const [emptyfav, setEmptyfav] = useState('');
  // name, description, id, item_image_url
  const items = useSelector((state) => state.shopReducer.currentItem);
  const user = useSelector((state) => state.authReducer.userData);

  const getFav = () => {
    let t = [];
    user?.favorites &&
      user?.favorites?.map((c) => {
        return items?.filter((d) => {
          if (d.id === c) {
            t.push(d);
          }
        });
      });
    setName(t);
  };
  useEffect(() => {
    if (user?.favorites?.length > 0) {
      setEmptyfav('');
      getFav();
    } else {
      setName([]); 
      setEmptyfav(`${t("profilee.noFavItems")}`);
    }
  }, [user?.favorites]);

  const [t, i18n] = useTranslation()


  const updateEmptyFavState = (newState) => {
    setEmptyfav(newState);
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      paddingTop="20px"
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
      }}
    >
      <Typography>{emptyfav}</Typography>
      {name.map((item, index) => (
        <Grid item lg={6}>
           <MenuCard
            color={environment.mainColor}
            restaurantId={environment.restaurantId}
            item={item}
            key={index}
            updateEmptyFavState={updateEmptyFavState}
          />
        </Grid>
      ))}
    </Grid>
  );
}
export default FavoriteItems;
