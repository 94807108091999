import React, { useEffect, useState } from 'react';
import { initializeI18n } from './i18n';
import { useSelector } from 'react-redux';

const withI18n = (WrappedComponent) => {
  return (props) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const user = useSelector((state) => state.authReducer.userData);

    useEffect(() => {
      const fetchAndInitialize = async () => {
        try {
          const defaultLanguage = user?.defaultLanguage || 'en';
          initializeI18n(defaultLanguage);
        } catch (error) {
          console.error('Error initializing i18n:', error);
        } finally {
          setIsInitialized(true);
        }
      };

      fetchAndInitialize();
    }, [user]);

    if (!isInitialized) {
      return <div>Loading...</div>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withI18n;



 const getUserData = async () => {
    try {
      const userId = auth?.currentUser?.uid;
      if (!userId) {
        throw new Error('No authenticated user found.');
      }
  
      console.log("user id in get user data",userId)
      const userDoc = await firestore.collection('users').doc(userId).get();
      if (!userDoc.exists) {
        throw new Error('User document does not exist.');
      }
  
      const userData = userDoc.data();
      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };