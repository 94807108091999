import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { environment } from '../../../environments/environment';
import { useTranslation } from 'react-i18next';
import rider from '../../../assets/rider.png';
import partner from '../../../assets/partner.png';
const content = [
  {
    id: '1',
    page: 'earnMoreWithLowerFees',
    singup: 'signupAsBusiness',
    title: 'partnerWithUs',
    btnTitle: 'contactUS',
    url: '/contactus',
    image: partner,
  },
  {
    id: '1',
    page: 'availExclusivePerks',
    singup: 'signupAsRider',
    title: 'rideWithUs',
    btnTitle: 'getStarted',
    url: '',
    image: rider,
  },
];

const BussinessRiderContact = () => {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  
  const handleButtonClick = (url) => {
    if (url.startsWith('http')) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };
  
  return (
    <Container
      sx={{
        marginTop: 10,
      }}
    >
      <Grid container spacing={3}>
        {content.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box
              style={{
                borderRadius: '12px',
                backgroundImage: `url(${item.image})`,
                height: '294px',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                backgroundSize: 'cover',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  fontFamily: 'Poppins',
                  lineHeight: '1.313rem',
                  color: environment.darkBlueColor,
                  fontWeight: 700,
                  backgroundColor: 'white',
                  borderRadius: '0px 0px 12px 12px',
                  position: 'absolute',
                  width: 'fit-content',
                  padding: '5px 12px',
                  left: '24px',
                  top: '0',
                }}
              >
                {t(`rider.${item.page}`)}
              </Typography>
              <Box style={{ position: 'absolute', left: '24px', top: '130px' }}>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: 'Poppins',
                    color: environment.mainColor,
                    fontWeight: 500,
                  }}
                >
                  {t(`rider.${item.singup}`)}
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: 'Poppins',
                    color: 'white',
                    fontWeight: 700,
                    lineHeight: '2.5rem',
                  }}
                >
                  {t(`rider.${item.title}`)}
                </Typography>
                <a href={environment.appGooglePlayLink} target="_blank">

                <Button
                  variant="contained"
                  style={{
                    backgroundColor: environment.mainColor,
                    color: 'white',
                    borderRadius: '120px',
                    textTransform: 'capitalize',
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    lineHeight: '1.313rem',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                  // onClick={() => handleButtonClick(item.url)}     
                             >
                  {t(`rider.${item.btnTitle}`)}
                </Button>
                </a>

              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BussinessRiderContact;
