import React from 'react';
import { Box, Button } from '@mui/material';

const TipButton = ({
  tipTypeValue,
  tipsValue,
  typeTip,
  setTypeTip,
  environment,
  handleClickOpen,
}) => {
  return (
    <Button
      disableRipple={true}
      style={{
        backgroundColor:
          typeTip === tipTypeValue ? environment.mainColor : '#FBFAFA',
        color: typeTip === tipTypeValue ? 'white' : environment.mainColor,
      }}
      variant={'contained'}
      onClick={() => {
        setTypeTip(tipTypeValue);
        if (tipTypeValue === 'other') {
          handleClickOpen();
        }
      }}
    >
            {' '}

      {tipsValue}
    </Button>
  );
};

const TipsBox = ({
  typeTip,
  setTypeTip,
  environment,
  total,
  fees,
  handleClickOpen,
  t,
}) => {
  const isJordan = environment?.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan';

  return (
    <>
      {isJordan ? (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '20px',
              marginBottom: '20px',
              flexDirection: 'row',
            }}
          >
            <TipButton
              tipsValue={`${environment.currency}${`0`}`}
              tipTypeValue={'a'}
              {...{ typeTip, setTypeTip, environment, handleClickOpen }}
            />
            <TipButton
              tipsValue={`${environment.currency}${`0.5`}`}
              tipTypeValue={'b'}
              {...{ typeTip, setTypeTip, environment, handleClickOpen }}
            />
            <TipButton
              tipsValue={`${environment.currency}${`1`}`}
              tipTypeValue={'c'}
              {...{ typeTip, setTypeTip, environment, handleClickOpen }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '20px',
              marginBottom: '20px',
              flexDirection: 'row',
            }}
          >
            <TipButton
              tipsValue={`${environment.currency}${`1.5`}`}
              tipTypeValue={'d'}
              {...{ typeTip, setTypeTip, environment, handleClickOpen }}
            />
            <TipButton
              tipsValue={t('confirmPage.other')}
              tipTypeValue={'other'}
              {...{ typeTip, setTypeTip, environment, handleClickOpen }}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '20px',
            marginBottom: '20px',
            flexDirection: 'row',
          }}
        >
          <TipButton
            tipsValue={`${environment.currency}${total <= 10 ? `1` : `${Math.floor(0.1 * (total + fees))}`}`}
            tipTypeValue={'a'}
            {...{ typeTip, setTypeTip, environment, handleClickOpen }}
          />
          <TipButton
            tipsValue={`${environment.currency}${total <= 10 ? '2' : `${Math.floor(0.2 * (total + fees))}`}`}
            tipTypeValue={'b'}
            {...{ typeTip, setTypeTip, environment, handleClickOpen }}
          />
          <TipButton
            tipsValue={`${environment.currency}${total <= 10 ? '3' : `${Math.floor(0.3 * (total + fees))}`}`}
            tipTypeValue={'c'}
            {...{ typeTip, setTypeTip, environment, handleClickOpen }}
          />
          <TipButton
            tipsValue={t('confirmPage.other')}
            tipTypeValue={'other'}
            {...{ typeTip, setTypeTip, environment, handleClickOpen }}
          />
        </Box>
      )}
    </>
  );
};

export default TipsBox;
