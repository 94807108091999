import React, { useEffect, useState} from 'react';
import { environment } from '../../../environments/environment';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  LoadItems,
  setModifiers,
  LoadCategories,
  ResturantID,
} from '@tabletuck/redux-slices';
import { auth, firestore } from '../../../../../../libs/firebase-service/src';
import school from '../../../assets/schoolHome.png';
import Advs from '../../components/Homepage/Advs';
import TTt from '../../../assets/homeback.png';
import hometabletuckdemo from '../../../assets/hometabletuckdemo.png';
import homeAlyosef from '../../../assets/alyousef/homAlyousef.png';
import homeFilfil from '../../../assets/homefilfil.png';
import './index.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import hours from '../../../assets/hours.png';
import {
  activeMenu,
  allactiveMenues,
  menuDocs,
  menues,
  setStores,
  userAddresses,
  res,
} from '../../../../../../libs/redux-slices/src';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AboutSection from './aboutSection';
import PopularSection from './popularSection';
import ChooseUs from './chooseusSection';
import ReviewSection from './reviewSection';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { fetchData, handleToaster } from '../../Utils/UtilsFunctions';
import { homePageEvent } from '../../../libs/models/facebookPixelEvent';
import homeFilfil1 from '../../../assets/homefilfil1.png';
import homeFilfil2 from '../../../assets/homefilfil2.png';
import homeFilfil3 from '../../../assets/homefilfil3.png';
import AboutFilfil from './Aboutfilfil';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import HomeCard from '../../components/Card/HomeCard';
import PopularRestaurant from './PopularRestaurant';
import HowRestaurantWork from './HowRestaurantWork';
import BussinessRiderContact from './BussinessRiderContact';
import LandingTTj from './landingTTj';

function Home() {
  const [items, setItems] = useState([]);
  const [adv, setAdv] = useState([]);
  const [value, setValue] = useState(0);
  const [modifiers, setModifiersGroups] = useState([]);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const storeId = window.localStorage.getItem('storeId');
  const [store, setStore] = useState([]);
  const userData = useSelector((state) => state.authReducer.userData);
  const stores = useSelector((state) => state.shopReducer.stores);
  const currentCategories =useSelector((state) => state.shopReducer.currentCategories);
  const currentItem =useSelector((state) => state.shopReducer.currentItem);
  const currentModifiers =useSelector((state) => state.shopReducer.modifiers);
  const currentMenu =useSelector((state) => state.authReducer.menues);
  const htmlCode = `
  <span class="yelp-review" data-review-id="RIAb0SWi96knhq4bG2qtAA" data-hostname="www.yelp.com">Read <a href="https://www.yelp.com/user_details?userid=j-b3zpqdgmfpfrFJUMwXHg" rel="nofollow noopener">Hien D.</a>'s <a href="https://www.yelp.com/biz/taqwas-bakery-and-restaurant-greenfield?hrid=RIAb0SWi96knhq4bG2qtAA" rel="nofollow noopener">review</a> of <a href="https://www.yelp.com/biz/Qfj9Tr6egkBK0bHg6nb8BA" rel="nofollow noopener">Taqwa's Bakery & Restaurant</a> on <a href="https://www.yelp.com" rel="nofollow noopener">Yelp</a><script src="https://www.yelp.com/embed/widgets.js" type="text/javascript" async></script></span>
`;
  const Reviewtwo = `<span class="yelp-review" data-review-id="alAjfwJkhklVoxqJknI3pA" data-hostname="www.yelp.com">Read <a href="https://www.yelp.com/user_details?userid=BBqbByjL7gwBX-CVe_RuhQ" rel="nofollow noopener">Lauren W.</a>'s <a href="https://www.yelp.com/biz/taqwas-bakery-and-restaurant-greenfield?hrid=alAjfwJkhklVoxqJknI3pA" rel="nofollow noopener">review</a> of <a href="https://www.yelp.com/biz/Qfj9Tr6egkBK0bHg6nb8BA" rel="nofollow noopener">Taqwa's Bakery & Restaurant</a> on <a href="https://www.yelp.com" rel="nofollow noopener">Yelp</a><script src="https://www.yelp.com/embed/widgets.js" type="text/javascript" async></script></span>`;
  const ReviewThree = `<span class="yelp-review" data-review-id="kv-YQ7-mGPo_AgecfgzvTQ" data-hostname="www.yelp.com">Read <a href="https://www.yelp.com/user_details?userid=7EmY93KOw7J9jpR5G0rxvw" rel="nofollow noopener">Tessa K.</a>'s <a href="https://www.yelp.com/biz/taqwas-bakery-and-restaurant-greenfield?hrid=kv-YQ7-mGPo_AgecfgzvTQ" rel="nofollow noopener">review</a> of <a href="https://www.yelp.com/biz/Qfj9Tr6egkBK0bHg6nb8BA" rel="nofollow noopener">Taqwa's Bakery & Restaurant</a> on <a href="https://www.yelp.com" rel="nofollow noopener">Yelp</a><script src="https://www.yelp.com/embed/widgets.js" type="text/javascript" async></script></span>`;
  const ReviewFour = `<span class="yelp-review" data-review-id="3bKz8F0F29QrFAVYNEfNiQ" data-hostname="www.yelp.com">Read <a href="https://www.yelp.com/user_details?userid=3mdM_myHQeS1BotVf1sUmQ" rel="nofollow noopener">Sarah I.</a>'s <a href="https://www.yelp.com/biz/taqwas-bakery-and-restaurant-greenfield?hrid=3bKz8F0F29QrFAVYNEfNiQ" rel="nofollow noopener">review</a> of <a href="https://www.yelp.com/biz/Qfj9Tr6egkBK0bHg6nb8BA" rel="nofollow noopener">Taqwa's Bakery & Restaurant</a> on <a href="https://www.yelp.com" rel="nofollow noopener">Yelp</a><script src="https://www.yelp.com/embed/widgets.js" type="text/javascript" async></script></span>`;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const ListofImage = [`${homeFilfil2}`, `${homeFilfil1}`, `${homeFilfil3}`];

  const popularCategory = {
    id: 'Popular',
    name: 'Popular',
    description: 'Popular',
    is_deleted: true,
    category_image_thumb_url: "popularImage",
  };
  const getStrores = async () => {
    try {
      let storeRef = firestore.collection('stores');
      storeRef = storeRef.where('is_deleted', '==', false);
      let list = [];
      const StoresList = await storeRef.get();
      for (let index = 0; index < StoresList?.docs?.length; index++) {
        let element = StoresList?.docs[index]?.data();
        element.id = StoresList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setStores(list));
      setStore(list);
    } catch (err) {
      console.log(err);
    }
  };

  const getMenuFirebase = async () => {
    try {
      let menusRef = firestore.collection('menus');
      menusRef = menusRef.where('is_deleted', '==', false);
      // Use the get method to retrieve all documents in the collection
      const querySnapshot = await menusRef.get();

      const mappedMenus = querySnapshot.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
        dispatch(menues([...mappedMenus]));
      // get all active menu
      const activMenus = mappedMenus.filter((item) => item.off_menu === false).map((item) => item.id);
      dispatch(allactiveMenues([...activMenus]));
      querySnapshot.forEach((doc) => {
          dispatch(menuDocs(doc.data()));
      });
      } catch (error) {
        console.error('Error getting documents:', error);
      }
    };

  const getMenues = async () => {
    try {
      const apiUrl = `${environment?.apiUrl}/getMenu?restaurantId=${environment?.restaurantId}`;

      const response = await fetchData(apiUrl, 'get');
      //  console.log("menu response",response.data)
      dispatch(activeMenu(response?.data.active_menu_now));
      dispatch(allactiveMenues(response?.data.all_active_menus));
      dispatch(menues(response.data?.menus));
      dispatch(res(response.data));
      // console.log(response.data,"data");
    } catch (error) {
      console.log('message Error:', error.response?.data?.message);
      // handleToaster('error' , error.response.data.message || error.response.data || "error in get menues" )
      console.log('FetchData Error:', error);
    }
  };

  const getResturantID = async () => {
    try {
      const doc = await firestore
        .collection('restaurant')
        .doc('Restaurant')
        .get();
      if (doc?.exists && checkResId != doc?.data()?.update_items_id) {
        dispatch(ResturantID(doc?.data()?.update_items_id));
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const getItems = async () => {
    try {
      let ItemsRef = firestore.collection('items');
      ItemsRef =ItemsRef.where('is_deleted', '==', false);
      // let list = [];
      const itemsList = await ItemsRef.where(
        'item_state',
        '==',
        'available'
      ).get();
      const mappedItems = itemsList.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
        dispatch(LoadItems([...mappedItems]));
        setItems([...mappedItems]);
    } catch (err) {
      // console.log(err);
    }

    // setItems(items);
  };
  const getCategories = async () => {
    try {
      let categoryRef = firestore
        .collection('categories')
        .orderBy('sortOrder', 'asc');
      categoryRef = categoryRef.where('is_deleted', '==', false);
      categoryRef = categoryRef.where('enable_items', '==', true);
  
      const categoriesList = await categoryRef.get();
      const mappedItems = categoriesList.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
  
      dispatch(LoadCategories([popularCategory, ...mappedItems]));
      setCategories([popularCategory, ...mappedItems]);
    } catch (err) {
    console.log(err);
    }
  };
  const getaPopularItems = async () => {
    let itemsRef = await firestore
      .collection('items')
      .orderBy('name')
      .where('is_popular', '==', true)
      .where('item_state', '==', 'available');
      itemsRef = itemsRef.where('is_deleted', '==', false);
    let items = [];
    const itemsList = await itemsRef.get();
    for (let index = 0; index < itemsList.docs.length; index++) {
      let element = itemsList.docs[index].data();
      element.id = itemsList.docs[index].id;
      if (element?.item_state === 'available') {
        items.push(element);
      }
    }
    console.log('popularitems', items);
    setItems(items);
  };

  const getModifiers = async () => {
    try {
      let modifiersRef = firestore.collection('modifier_groups');
      modifiersRef = modifiersRef.where('is_deleted', '==', false);
      const modifierList = await modifiersRef.get();
      const mappedModifier = modifierList.docs.map((d) => {
        return {
          id: d.id,
          ...d.data(),
        };
      });
        dispatch(setModifiers([...mappedModifier]));
        setModifiersGroups([...mappedModifier]);
    } catch (err) {
      console.log(err);
    }
  };


  const getUserAddress = async (token, callback, handleErr) => {
    try {
      await firestore
        .collection('users')
        .doc(userData?.uid)
        .collection('addresses')
        .get()
        .then((docs) => {
          let adds = [];
          docs.forEach((doc) => {
            adds.push(doc.data());
          });
          dispatch(userAddresses(adds));
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getOffers = async () => {
    let itemsRef = await firestore
      .collection('offers')
      .where('available', '==', true);
    let items = [];
    const itemsList = await itemsRef.get();
    for (let index = 0; index < itemsList.docs.length; index++) {
      let element = itemsList.docs[index].data();
      items.push(element);
    }
    setAdv(items);
  };
  const [t, i18n] = useTranslation();

  useEffect(() => {
    getUserAddress(auth?.currentUser?.uid);
    getItems();
    getCategories();
    getModifiers();
    if(environment?.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan' ){
      getMenuFirebase();
    }else{
      getMenues();
    }
    getStrores();
  
    homePageEvent();
  }, []);

 

  return (
    <>
      <>
        {environment?.restaurantId === 'istanbulDk' ||
          (environment.restaurantId === 'taqwasbakery' && (
            <Helmet>
              <title>
                Taqwa's Bakery and Restaurant | Middle Eastern Cuisine
              </title>
              <meta
                name="description"
                content="Explore the flavors of authentic Middle Eastern cuisine infused with Za'atar magic at Taqwa's Bakery and Restaurant. Learn more by visiting our website."
              />
              <meta
                name="keywords"
                content="TaqwasBakery, bakery, delicious, baked goods"
              />
              <meta property="og:title" content="TaqwasBakery - Home" />
              <meta
                property="og:description"
                content="Welcome to TaqwasBakery - Serving delicious baked goods!"
              />
            </Helmet>
          ))}
        {environment.restaurantId === 'ttDemo' ? (
          <img
            alt="tesxt"
            height="100%"
            src={hometabletuckdemo} // use normal <img> attributes as props
            width="100%"
          />
        ) : environment.restaurantId === 'elmbrookschools' ? (
          <img
            alt="tesxt"
            height="80%"
            src={school} // Replace with the appropriate image source for Taqwasbakery
            width="100%"
          />
        ) : environment.restaurantId === 'alyousef' ? (
          <img
            alt="tesxt"
            height="50%"
            src={homeAlyosef}
            width="100%"
            loading="lazy"
          />
        ) : environment.restaurantId === 'filfil' ? (
          <>
            {ListofImage.length > 0 && (
              <Carousel
                width={'100%'}
                showArrows={false}
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                verticalSwipe="standard"
              >
                {ListofImage?.map((item, index) => (
                  <>
                    <img
                      src={item}
                      alt="home"
                      style={{
                        width: '100%',
                        height: smDown && '300px',
                      }}
                    />
                  </>
                ))}
              </Carousel>
            )}
          </>
        ) : environment.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan'  ? (
           <LandingTTj />
        ) : (
          <img
            alt="tesxt"
            height="100%"
            src={TTt} // use normal <img> attributes as props
            width="100%"
          />
        )}


        {(environment.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan' ) && <PopularRestaurant />}
        {(environment.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan' ) && <BussinessRiderContact />}
        {(environment.restaurantId === 'ttJordanDev' || environment?.restaurantId === 'ttJordan' ) && <HowRestaurantWork />}
        {/* <HomeCard /> */}

      
        {environment.restaurantId === 'filfil' && <AboutFilfil />}
        {environment.restaurantId !== 'filfil' || environment.restaurantId !== 'ttJordanDev'  || environment.restaurantId !== 'ttJordan' && <AboutSection />}
        {/* { environment.restaurantId !=='filfil'  &&  <PopularSection items={items} modifiers={modifiers} />   } */}
        {environment.restaurantId === 'elmbrookschools' && <ReviewSection />}
        {environment.restaurantId === 'elmbrookschools' && <ChooseUs />}

        {/* {environment.restaurantId !== 'taqwasbakery' && (
          <PopularSection items={items} modifiers={modifiers} />
        )} */}
        {environment?.restaurantId === 'istanbulDk' && (
          <Grid
            style={{
              position: 'relative',
              backgroundImage: `url(${hours})`,

              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            sx={{ height: { md: '100%', lg: '33rem' } }}
          >
            <div
              style={{
                paddingTop: '3rem',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h1"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontFamily: `Hanalei Fill, cursive`,
                    textAlign: 'center',
                  }}
                >
                  Opening Hours
                </Typography>
              </div>
              <div
                style={{
                  display: 'grid',
                  justifyContent: 'center',
                  paddingBottom: '3rem',
                }}
              >
                <br></br>

                {stores &&
                  stores[0]?.timing.map((c) => (
                    <Typography
                      variant="body1"
                      style={{
                        color: 'white',
                        fontWeight: 'normal',
                        marginTop: '10px',
                      }}
                    >
                      {c.day === 'Fri' && (
                        <div key={c.day}>
                          <Typography
                            variant="h4"
                            style={{
                              color: 'white',
                              textAlign: 'center',
                              fontWeight: 200,
                            }}
                          >
                            {'Friday - Saturday '}
                          </Typography>{' '}
                          {c.slots.map((slot, slotIndex) => {
                            const timestamp =
                              slot.open_time.seconds * 1000 +
                              slot.open_time.nanoseconds / 1e6;
                            const openTime = new Date(timestamp);
                            const timestampclose =
                              slot.close_time.seconds * 1000 +
                              slot.close_time.nanoseconds / 1e6;
                            const close_time = new Date(timestampclose);

                            return (
                              <div key={slotIndex}>
                                <Typography
                                  variant="h3"
                                  style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  {' '}
                                  {moment(openTime).format('h:mm A')} -{' '}
                                  {moment(close_time).format('h:mm A')}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {c.day === 'Sun' && (
                        <div key={c.day}>
                          <Typography
                            variant="h4"
                            style={{
                              color: 'white',
                              textAlign: 'center',
                              fontWeight: 200,
                            }}
                          >
                            {'Sunday - Thursday'}
                          </Typography>{' '}
                          {c.slots.map((slot, slotIndex) => {
                            const timestamp =
                              slot.open_time.seconds * 1000 +
                              slot.open_time.nanoseconds / 1e6;
                            const openTime = new Date(timestamp);
                            const timestampclose =
                              slot.close_time.seconds * 1000 +
                              slot.close_time.nanoseconds / 1e6;
                            const close_time = new Date(timestampclose);

                            return (
                              <div key={slotIndex}>
                                <Typography
                                  variant="h3"
                                  style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                >
                                  {' '}
                                  {moment(openTime).format('h:mm A')} -{' '}
                                  {moment(close_time).format('h:mm A')}
                                </Typography>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Typography>
                  ))}
              </div>

              <br />
            </div>
          </Grid>
        )}

        {environment.restaurantId === 'elmbrookschools' && <ReviewSection />}
        {environment.restaurantId === 'elmbrookschools' && <ChooseUs />}

        {/* reviews */}

        {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              marginTop: '60px',
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: environment.mainColor,
                fontWeight: 'bold',
                fontFamily: `Hanalei Fill, cursive`,
                direction: "initial"
              }}
            >
              Yelp {t("reviews")}
            </Typography>
          </div> */}
        {/* <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '60%',
              }}
            >
              <span
                className="dot"
                style={{ backgroundColor: environment.mainColor }}
              ></span>

              <Divider
                style={{
                  marginBottom: '5px',
                  marginTop: '5px',
                  height: '2px',
                  backgroundColor: environment.mainColor,
                  width: '50%',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              />
              <span
                className="dot"
                style={{ backgroundColor: environment.mainColor }}
              ></span>
            </div>
          </div> */}
        {/* <Container maxWidth={'lg'} style={{ marginTop: '100px' }}>
            <Grid container spacing={xsDown ? 0 : 5}>
              <Grid
                container
                spacing={xsDown ? 0 : 5}
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <Helmet>
                  <script
                    src="https://www.yelp.com/embed/widgets.js"
                    type="text/javascript"
                    async
                  />
                </Helmet>

                <div
                  style={{ minWidth: '30%' }}
                  dangerouslySetInnerHTML={{ __html: htmlCode }}
                />

                <div
                  style={{ minWidth: '39%' }}
                  dangerouslySetInnerHTML={{ __html: ReviewFour }}
                />
                <div
                  style={{ minWidth: '30%' }}
                  dangerouslySetInnerHTML={{ __html: ReviewThree }}
                />
              </Grid>
            </Grid>
            <div style={{ height: '50px' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  marginTop: '10px',
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: '10px',
                  width: ' 100%',
                }}
              >
                {data?.map((item, index) => {
                  return (
                    <span
                      className="dot"
                      style={{
                        cursor: 'pointer',
                        height: '8px',
                        width: '8px',
                        margin: ' 0 2px',
                        backgroundColor: slide == index ? 'gray' : '#bbb',
                        borderRadius: '50%',
                        display: 'inline-block',
                        transition: ' background-color 0.6s ease',
                      }}
                      key={index}
                      onClick={(e) => {
                        setSlide(index);
                        // setChange(!change);
                      }}
                    ></span>
                  );
                })}
              </div>
            </div>
          </Container> */}
        {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              marginTop: '60px',
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: environment.mainColor,
                fontWeight: 'bold',
                fontFamily: `Hanalei Fill, cursive`,
              }}
            >
              Yelp Reviews
            </Typography>
          </div> */}
        {/* <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '60%',
              }}
            >
              <span
                className="dot"
                style={{ backgroundColor: environment.mainColor }}
              ></span>

              <Divider
                style={{
                  marginBottom: '5px',
                  marginTop: '5px',
                  height: '2px',
                  backgroundColor: environment.mainColor,
                  width: '50%',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              />
              <span
                className="dot"
                style={{ backgroundColor: environment.mainColor }}
              ></span>
            </div>
          </div> */}
        {/* <Container maxWidth={'lg'} style={{ marginTop: '100px' }}>
            <Grid container spacing={xsDown ? 0 : 5}>
              <Grid
                container
                spacing={xsDown ? 0 : 5}
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <Helmet>
                  <script
                    src="https://www.yelp.com/embed/widgets.js"
                    type="text/javascript"
                    async
                  />
                </Helmet>

                <div
                  style={{ minWidth: '30%' }}
                  dangerouslySetInnerHTML={{ __html: htmlCode }}
                />

                <div
                  style={{ minWidth: '39%' }}
                  dangerouslySetInnerHTML={{ __html: ReviewFour }}
                />
                <div
                  style={{ minWidth: '30%' }}
                  dangerouslySetInnerHTML={{ __html: ReviewThree }}
                />
              </Grid>
            </Grid>
            <div style={{ height: '50px' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  marginTop: '10px',
                  textAlign: 'center',
                  position: 'absolute',
                  bottom: '10px',
                  width: ' 100%',
                }}
              >
                {data?.map((item, index) => {
                  return (
                    <span
                      className="dot"
                      style={{
                        cursor: 'pointer',
                        height: '8px',
                        width: '8px',
                        margin: ' 0 2px',
                        backgroundColor: slide == index ? 'gray' : '#bbb',
                        borderRadius: '50%',
                        display: 'inline-block',
                        transition: ' background-color 0.6s ease',
                      }}
                      key={index}
                      onClick={(e) => {
                        setSlide(index);
                        // setChange(!change);
                      }}
                    ></span>
                  );
                })}
              </div>
            </div>
          </Container> */}
        {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
              marginTop: '60px',
            }}
          >
            <Typography
              variant="h2"
              style={{
                color: environment.mainColor,
                fontWeight: 'bold',
                fontFamily: `Hanalei Fill, cursive`,
              }}
            >
              Our Gallery
            </Typography>
          </div> */}

        {/* <br /> */}
        {/* <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
            }}
          > */}
        {/* <Typography variant="h6">
              Our it's authentic... it's fresh...it's taqwas.
            </Typography>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                width: { md: '100%', lg: '40%' },
                bgcolor: 'background.paper',
                border: '1px solid lightgray',
                marginTop: '30px',
                boxShadow:
                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                centered
                indicatorColor=""
              >
                <Tab
                  label="RESTAURANT"
                  style={{
                    color: type === 'Res' && environment.mainColor,
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    setType('Res');
                  }}
                />
                <Tab
                  label="DESSERT"
                  style={{
                    color: type === 'Des' && environment.mainColor,
                    fontWeight: 'bold',
                  }}
                  onClick={() => {
                    setType('Des');
                  }}
                />
                {/* <Tab
              label="ENTRÉES"
              style={{ color: type === 'Ent' && environment.mainColor, fontWeight:'bold' }}
              onClick={() => {
                setType('Ent');
              }}
            /> */}
        {/* </Tabs>
            </Box>
          </div> */}

        {/* {type === 'Res' ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '50%',
                  marginTop: '18px',
                }}
              >
                <img loading="lazy" src={Res} alt="loading..." />
              </div>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '50%',
                  marginTop: '18px',
                }}
              >
                <img loading="lazy" src={Des} alt="loading..." />
              </div>
            </div>
          )} */}
      </>

      {adv.length > 0 && (
        <Carousel
          width={'100%'}
          showArrows={false}
          infiniteLoop={false}
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          verticalSwipe="standard"
        >
          {adv?.map((item, index) => (
            <>
              <Advs item={item} />
            </>
          ))}
        </Carousel>
      )}
    </>
  );
}
export default Home;
