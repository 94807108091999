import { firestore } from '../../../../../../libs/firebase-service/src';

export const validateUserAddress = async (token, address, setErr) => {
  try {
    const tokenId = await auth?.currentUser.getIdToken().then((token) => token);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: tokenId,
    };
    fetch(`${environment?.apiUrl}/pickUpOrDelivery`, {
      method: 'POST',
      body: {
        ...address,
      },
      headers: headers,
    });
  } catch (e) {
    setErr(e.message);
  }
};
const get4ays = (data) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = new Date().getDay();
  let yesterday = today === 0 ? 6 : today - 1;
  let tomo = today < 5 ? today + 1 : today == 5 ? 6 : 1;
  let after = today < 5 ? today + 2 : today === 5 ? 0 : 1;
  const wanted = [days[yesterday], days[today], days[tomo], days[after]];
  let times = wanted.map((day) => {
    return data.filter((item) => item.day == day)[0] || [];
  });
  return times;
};
// getTimeZones function
export const getTimeZones = async (restId, onFail) => {
  const res = await firestore.collection('stores').doc(restId).get();

  if (res.data().endTime) {
    const endtime = new Date(
      res.data().endTime.seconds * 1000 + res.data().endTime.nanoseconds / 1e6
    );
    console.log('store data end time', endtime);
    if (endtime > new Date()) return false;
  }

  const timing = get4ays(res.data().timing);
  const times = {};
  const todayData = timing[1]?.day;
  const tomorrowData = timing[2]?.day;
  const afterTomorrowData = timing[3]?.day;

  // extract  times from all date
  const convertedTimes = timing.map((item) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    const slots = item?.slots?.map((slot) => {
      const close_time = slot.close_time
        .toDate()
        .toLocaleString('en-US', options);
      const open_time = slot.open_time
        .toDate()
        .toLocaleString('en-US', options);
      return { ...slot, open_time, close_time };
    });
    return { ...item, slots };
  });
  //-------
  // Calculate current availability for today
  times.today = todayData ? iterateTimeForToday(convertedTimes[1]?.slots) : [];
  times.tomorrow = tomorrowData ? iterateTime(convertedTimes[2]?.slots) : [];
  times.afterTomorrow =
    timing[3] && afterTomorrowData ? iterateTime(convertedTimes[3]?.slots) : [];
  times.asap = asap_Is_Available(convertedTimes[1]?.slots);
  return times;
};

const asap_Is_Available = (value) => {
  const now = new Date();
  console.log("Current Date and Time:", now);

  if (value !== null && value !== undefined) {
    console.log("Value is provided:", value);

    for (const slot of value) {
      console.log("Processing slot:", slot);

      const startTime = new Date(now.toDateString() + ' ' + slot?.open_time);
      const endTime = new Date(now.toDateString() + ' ' + slot?.close_time);
      
      console.log("Start Time:", startTime);
      console.log("End Time:", endTime);

      if (now >= startTime && now <= endTime) {
        console.log("Current time is within the slot:", { startTime, endTime });
        return true; // Early return when a valid slot is found
      } else {
        console.log("Current time is outside the slot:", { startTime, endTime });
      }
    }
  } else {
    console.log("Value is null or undefined");
  }

  console.log("No valid slot found, returning false");
  return false;
};

// end getTimeZones function

// Sample iterateTime function (updated to handle your data structure)
export const iterateTime = (data) => {
  if (!data || !Array.isArray(data)) return [];
  return data.reduce((arr, slot) => {
    const currentDate = new Date(); // Get the current date
    const openTime = new Date(
      currentDate.toDateString() + ' ' + slot.open_time
    );
    const closeTime = new Date(
      currentDate.toDateString() + ' ' + slot.close_time
    );
    if (!isNaN(openTime) && !isNaN(closeTime)) {
      while (openTime < closeTime) {
        const nextTime = new Date(openTime.getTime() + 60 * 60 * 1000);
        const timeRange = `${formatTime(openTime)} - ${formatTime(nextTime)}`;
        arr.push({ time: timeRange, timezone: slot.timezone });
        openTime.setTime(nextTime.getTime());
      }
    }
    return arr;
  }, []);
};
//iteration time for today
export const iterateTimeForToday = (data) => {
  if (!data || !Array.isArray(data)) return [];
  const now = new Date();
  now.setHours(now.getHours() + 1); // Add 1 hour to now
  const arr = [];
  data.forEach((slot) => {
    const currentDate = new Date(); // Get the current date
    const openTime = new Date(
      currentDate.toDateString() + ' ' + slot.open_time
    );
    const closeTime = new Date(
      currentDate.toDateString() + ' ' + slot.close_time
    );
    if (!isNaN(openTime) && !isNaN(closeTime)) {
      // Use a plain for loop to iterate through the time slots
      for (
        let time = new Date(openTime);
        time < closeTime;
        time.setHours(time.getHours() + 1)
      ) {
        const nextTime = new Date(time.getTime() + 60 * 60 * 1000);
        if (nextTime <= now) {
          continue; // Skip past time slots that are in the past
        }
        const timeRange = `${formatTime(time)} - ${formatTime(nextTime)}`;
        arr.push({ time: timeRange, timezone: slot.timezone });
      }
    }
  });
  return arr;
};

// -----

const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export function getOffsetForLoc(loc, date = new Date()) {
  let opts = {
    hour: 'numeric',
    timeZone: 'America/Chicago',
    timeZoneName: 'short',
  };
  let getOffset = (lang) =>
    new Intl.DateTimeFormat(lang, opts)
      .formatToParts(date)
      .reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
      }, {}).timeZoneName;
  let offset = getOffset('en');
  // If offset is an abbreviation, change language
  if (!/^UCT|GMT/.test(offset)) {
    offset = getOffset('fr');
  }
  // Remove GMT/UTC
  let res = offset.substring(3);
  if (res[0] == '−') res = -+res.replace('−', '');

  return +res;
}

export const getTomorrowScheduleLater = () => {
  // Get current date
  var today = new Date();
  // Add one day to the current date
  var tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  // Get the month and day components of the tomorrow's date
  var month = tomorrow.getMonth() + 1; // Months are zero-based
  var day = tomorrow.getDate() + 1;
  // Format the date as "mm/d"
  var formattedDate = month.toString().padStart(2, '0') + '/' + day;

  return formattedDate; // Output: "06/6" (for June 6th)
};
export const getTomorrow = () => {
  const Englishdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const Arabicdays = [
    'الأحد',
    'الاثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ];

  const tomorrow = new Date();
  const afterTo = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  afterTo.setDate(afterTo.getDate() + 2);
  const tomorrowData = {
    date: tomorrow.getDate(),
    day: Englishdays[tomorrow.getDay()],
    dayInArabic: Arabicdays[tomorrow.getDay()],
    month: tomorrow.getMonth(),
  };
  const afterData = {
    date: afterTo.getDate(),
    day: Englishdays[afterTo.getDay()],
    dayInArabic: Arabicdays[afterTo.getDay()],
    month: afterTo.getMonth(),
  };
  return { tomorrow: tomorrowData, after: afterData };
};

export function startsWithPlus(phoneNumber, countryCode) {
  let regex;
  if (countryCode === '+1') {
    regex = /^\+1\d{10}$/;
  } else if (countryCode === '+962') {
    regex = /^\+962\d{9}$/;
  } else if (countryCode === '+20') {
    regex = /^\+20\d{10}$/;
  } else {
    throw new Error('Unsupported country code');
  }
  return regex.test(phoneNumber);
}
