import React from 'react'
import './snipper.css'
function Snipper({color}) {
  return (
    <div id="loading" 
    style={{borderTopColor:color}} 
     ></div>
  )
}

export default Snipper