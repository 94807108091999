import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Card, CardContent, Grid, Hidden, IconButton } from '@mui/material';
import { CustomButton } from '@tabletuck/components';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineSharpIcon from '@mui/icons-material/PersonOutlineSharp';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartDrawer from '../../../../../apps/table-tuck-web/src/app/components/CartDrawer/CartDrawer';
import { auth, firestore } from '@tabletuck/firebase-services';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { environment } from '../../../../../apps/table-tuck-web/src/environments/environment';
import {
  loggedOutSuccess,
  setCart,
  setUser,
  loggedInSuccess,
  activeMenu,
  allactiveMenues,
  menues,
  setStores,
  checkStore,
} from '@tabletuck/redux-slices';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from 'react-router';
import logo from '../../../../../apps/table-tuck-web/src/assets/white-logo.png';
import blacklogo from '../../../../../apps/table-tuck-web/src/assets/blacklogo.png';
import TTdemoLogo from '../../../../../apps/table-tuck-web/src/assets/TTdemoLogo.png';
import schoolLogo from '../../../../../apps/table-tuck-web/src/assets/logoschool.png';
import ttJordenLogo from '../../../../../apps/table-tuck-web/src/assets/ttJordenLogo.png';

import ttjordanlogo from '../../../../../apps/table-tuck-web/src/assets/Filfillogo.png';
import ttjordanlogopng from '../../../../../apps/table-tuck-web/src/assets/ttjordanlogopngabovesmall.png';

import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dialog } from '@mui/material';
import { RootFirestore } from '@tabletuck/firebase-services';
import SettingsIcon from '@mui/icons-material/Settings';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Formik } from 'formik';
import { setDefaultStore } from 'apps/table-tuck-web/src/app/components/CartDrawer/utils';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export function Header(props) {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const cart = useSelector((state) => state.shopReducer.cart);
  const token = useSelector((state) => state.authReducer.token);
  const userData = useSelector((state) => state.authReducer.userData);
  const res = useSelector((state) => state.authReducer.res);

  const [addresses, setAddresses] = useState([]);
  const location = useLocation();
  const [counter, setCounter] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = anchorEl;
  const [env, setEnv] = useState([]);
  const stores = useSelector((state) => state.shopReducer.stores);
  const [store, setStore] = React.useState('');
  const [t, i18n] = useTranslation();
  const menuDocs = useSelector((state) => state.authReducer.menuDocs);
  const allMenues = useSelector((state) => state.authReducer.menues);
  const [defaultStoreAdress, setDefaultStoreAdress] = useState('');
  const storedItems = JSON.parse(localStorage.getItem('cartItems'));
  const items = useSelector((state) => state.shopReducer.currentItem);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const check = window.localStorage.getItem('items');
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openCartDialoge, setOpenCartDialoge] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
 


  const handleNavigation = (path) => {
    navigate(path);

  };
  const getStrores = async () => {
    try {
      let storeRef = firestore.collection('stores');
      storeRef = storeRef.where('is_deleted', '==', false);
      let list = [];
      const StoresList = await storeRef.get();
      for (let index = 0; index < StoresList?.docs?.length; index++) {
        let element = StoresList?.docs[index]?.data();
        element.id = StoresList?.docs[index]?.id;
        list.push(element);
      }
      dispatch(setStores(list));
      setStore(list);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getENV = async () => {
    const doc = await RootFirestore.collection('environment')
      .doc(props.env.restaurantId)
      .get();
    setEnv(doc.data());
  };

  const handleSubmit = async (values) => {
    await RootFirestore.collection('environment')
      .doc(props.env.restaurantId)
      .set(
        {
          paymentFlow: values?.paymentFlow,
          orderFlow: values?.orderFlow,
        },
        { merge: true }
      )
      .catch((err) => {
        console.warn('success', err);
      });
    setOpenDialog(false);
  };

  const getCount = () => {
    let count = 0;
    for (let index = 0; index < cart?.length; index++) {
      const element = cart[index];
      count = count + element?.quantity;
    }
    setCounter(count);
  };

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'categories':
        return licenses?.licenses.dashboard.categories;
      case 'signup':
        return check != 'true' ? licenses?.licenses.frontend.signup : false;
      case 'profile':
        return check != 'true' ? licenses?.licenses.frontend.profile : false;
      case 'checkout':
        return check != 'true' ? licenses?.licenses.frontend.checkout : false;
      case 'items':
        return licenses?.licenses.dashboard.items;
      case 'drivers':
        return licenses?.licenses.dashboard.drivers;
      case 'modifierGroup':
        return licenses?.licenses.dashboard.modifierGroup;
      case 'admins':
        return licenses?.licenses.dashboard.admins;
      case 'stores':
        return licenses?.licenses.dashboard.stores;
      case 'offers':
        return licenses?.licenses.dashboard.offers;
      case 'orderHistory':
        return licenses?.licenses.dashboard.orderHistory;

      default:
        return false;
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      dispatch(loggedOutSuccess());
      dispatch(setCart([]));
      navigate('/');
      localStorage.clear();
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickOpen = () => {
    stores.length > 1 ? setOpen(true) : navigate('/menu');
  };
  const handleClickOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleClickOpenCartDialoge = () => {
    setOpenCartDialoge(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleCloseCartDialoge = () => {
    setOpenCartDialoge(false);
  };

  const numberToWord = (number) => {
    const words = [
      'Zero',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
      'Ten',
    ];
    return words[number];
  };

  const menuDefaultStore = () => {
    dispatch(activeMenu(res[userData?.defaultStore]?.active_menu_now));
    dispatch(allactiveMenues(res[userData?.defaultStore]?.all_active_menus));
    dispatch(menues(res[userData?.defaultStore]?.menus));
    navigate('/menu');
  };

  useEffect(() => {
    stores?.filter((store) =>
      store.id === userData?.defaultStore
        ? setDefaultStoreAdress(store?.address?.displayName)
        : ''
    );
  }, [defaultStoreAdress]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const res = await firestore.collection('users').doc(user?.uid).get();
        if (res.exists) {
          const data = res.data();
          if (data?.email && data.fullName) {
            dispatch(loggedInSuccess({ token: user.uid }));
            dispatch(setUser({ user: user, userData: data }));
          }
        }
      }
    });
  }, [auth, window.location.pathname]);

  useEffect(() => {
    getCount();
  }, [cart, auth?.currentUser?.uid, token]);
  useEffect(() => {
    getENV();
    getStrores();
  }, []);
  useEffect(() => {

    setActiveLink(location.pathname);
  }, [location]);
  return (
    licenses && (
      <AppBar
        dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
        position="absolute"
        style={{
          backgroundColor:
            props.env.restaurantId === 'istanbulDk' ||
            props.env.restaurantId === 'elmbrookschools' ||
            props.env.restaurantId === 'alyousef' ||
            props.env.restaurantId === 'filfil' ||
            props.env.restaurantId === 'ttDemo' ||
            props.env.restaurantId === 'ttJordanDev' ||
            props.env.restaurantId === 'ttJordan'
              ? 'transparent'
              : 'transparent',
          boxShadow:
            props.location === '/'
              ? 'none'
              : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <div sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

            <Typography
              onClick={() => navigate('/')}
              noWrap
              component="a"
              style={{ display: 'flex', cursor: 'pointer' }}
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 700,
                color: 'black',
              }}
            >
              {props.env.restaurantId === 'ttJordanDev' ||
              props.env.restaurantId === 'ttJordan' ? (
                <>
                  {props.location === '/menu-items' ? (
                    <img
                      src={ttjordanlogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '7rem',
                        height: '2rem',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {props.location === '/' ? (
                    <img
                      src={ttjordanlogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '7rem',
                        height: '2rem',
                      }}
                    />
                  ) : (
                    <>
                      {props.location != '/menu-items' ? (
                        <img
                          src={ttjordanlogo}
                          alt="logo"
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            width: '7rem',
                            height: '2rem',
                          }}
                        />
                      ) : (
                        props.location != '/menu-items' && (
                          <img
                            src={ttjordanlogo}
                            alt="logo1"
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              width: '7rem',
                              height: '2rem',
                            }}
                          />
                        )
                      )}
                    </>
                  )}
                </>
              ) : props.env.restaurantId === 'istanbulDk' ? (
                <>
                  {props.location === '/menu-items' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    ''
                  )}

                  {props.location === '/' ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : (
                    <>
                      {props.location != '/menu-items' ? (
                        <img
                          src={blacklogo}
                          alt="logo"
                          style={{
                            display: 'flex',
                            cursor: 'pointer',
                            width: '4rem',
                          }}
                        />
                      ) : (
                        props.location != '/menu-items' && (
                          <img
                            src={logo}
                            alt="logo1"
                            style={{
                              display: 'flex',
                              cursor: 'pointer',
                              width: '4rem',
                            }}
                          />
                        )
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {props.env.restaurantId === 'istanbulDk' ||
                  (props.env.restaurantId === 'taqwasbakery' &&
                    props.isAdmin) ? (
                    <img
                      src={blacklogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '4rem',
                      }}
                    />
                  ) : props.env.restaurantId === 'istanbulDk' ||
                    props.env.restaurantId === 'ttJordanDev' ||
                    props.env.restaurantId === 'ttJordan' ? (
                    props.title
                  ) : props.env.restaurantId === 'ttDemo' ? (
                    <img
                      src={TTdemoLogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '5rem',
                      }}
                    />
                  ) : props.env.restaurantId === 'elmbrookschools' ? (
                    <img
                      src={schoolLogo}
                      alt="logo"
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        width: '5rem',
                      }}
                    />
                  ) : (
                    <h2 style={{ color: 'white' }}>{props.title}</h2>
                  )}
                </>
              )}
            </Typography>
            {auth?.currentUser?.uid &&
              userData?.defaultStore?.length > 0 &&
              stores?.length > 1 &&
              !(
                props.env.restaurantId === 'ttJordanDev' ||
                props.env.restaurantId === 'ttJordan'
              ) && (
                <Typography
                  variant="caption"
                  style={{
                    color: '#c6c6c6',
                    cursor: 'pointer',
                    fontSize: smDown ? '10px' : '16px',
                  }}
                  onClick={handleClickOpen}
                >
                  (
                  {stores
                    ?.find((c) => c.id === userData?.defaultStore)
                    ?.address?.displayName.split(',')
                    .slice(0, 1)
                    .join(',')}
                  )
                </Typography>
              )}

            {props.isAdmin ? (
              <>
                <Hidden smUp>
                  <>
                    <IconButton
                      styly={{
                        backgroundColor: 'white',
                        marginLeft: '10px',
                      }}
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleClickOpenDrawer}
                    >
                      <MenuIcon
                        style={{
                          color: 'rgb(160, 25, 19)',
                          marginLeft: '10px',
                        }}
                      />
                    </IconButton>
                  </>
                </Hidden>
              </>
            ) : (
              <>
                {props.env.restaurantId !== 'filfil' && (
                  <>
                    <>
                      {/* {props.env.restaurantId !== 'ttJordanDev' ||
                        (props.env.restaurantId !== 'ttJordan' && ( */}
                         {props.env.restaurantId === 'taqwasbakery' && (
                          <>
                            <CustomButton
                              bg={props.env.mainColor}
                              onClick={() =>
                                auth?.currentUser?.uid &&
                                userData?.defaultStore?.length > 0
                                  ? menuDefaultStore()
                                  : handleClickOpen()
                              }
                              buttonText={t('ourMenu')}
                            />
                            <Hidden smDown>
                              <Button
                                id="demo-customized-button"
                                sx={{
                                  background: props.env.mainColor,
                                  borderRadius: '24px',
                                  color: 'white',
                                }}
                                aria-controls={
                                  openMenu ? 'demo-customized-menu' : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={openMenu ? 'true' : undefined}
                                style={{ fontWeight: 'bold' }}
                                onClick={handleClick}
                                endIcon={<KeyboardArrowDownIcon />}
                              >
                                {t('contact')}
                              </Button>
                            </Hidden>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem
                                onClick={() => {
                                  navigate('/contactus');
                                  handleCloseMenu();
                                }}
                                disableRipple
                              >
                                {t('contact')}
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  navigate('/careers');
                                  handleCloseMenu();
                                }}
                                disableRipple
                              >
                                {t('career')} 
                              </MenuItem>
                              <Divider />
                              {props.env.restaurantId === 'istanbulDk' ||
                                props.env.restaurantId === 'taqwasbakery' ||
                                (props.env.restaurantId === 'alyousef' && (
                                  <MenuItem
                                    onClick={() => {
                                      navigate('/aboutus');
                                      handleCloseMenu();
                                    }}
                                    disableRipple
                                  >
                                    {t('about')}
                                  </MenuItem>
                                ))}
                            </StyledMenu>
                          </>
                        )}
                    </>
                    {(props.env.restaurantId === 'ttJordanDev' ||
                      props.env.restaurantId === 'ttJordan') && (
                        (mdDown ? (
                          <>
                            <Button
                              id="demo-customized-button"
                              sx={{
                                background: props.env.mainColor,
                                borderRadius: '24px',
                                color: 'white',
                                marginRight: '10px',
                                '&:hover': {
                                  background: props.env.mainColor,
                                },
                              }}
                              aria-controls={
                                openMenu ? 'demo-customized-menu' : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined}
                              style={{ fontWeight: 'bold' }}
                              onClick={handleClick}
                            >
                              <MenuIcon />
                            </Button>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                              }}
                              anchorEl={anchorEl}
                              open={openMenu}
                              onClose={handleCloseMenu}
                            >
                              <MenuItem
                                onClick={() => {
                                  navigate('/');
                                  handleCloseMenu();
                                }}
                                disableRipple
                              >
                                {t('home')}
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  navigate('/restaurants');
                                  handleCloseMenu();
                                }}
                                disableRipple
                              >
                                {t('restaurants')}
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  navigate('/contactus');
                                  handleCloseMenu();
                                }}
                                disableRipple
                              >
                                {t('contact')}
                              </MenuItem>
                              <Divider />
                              <MenuItem
                                onClick={() => {
                                  navigate('/careers');
                                  handleCloseMenu();
                                }}
                                disableRipple
                              >
                                {t('career')} 
                              </MenuItem>
                              <Divider />
                            </StyledMenu>
                          </>
                        ) : (
                          <Grid
                            container
                            columnSpacing={5}
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              width:
                                !props.isAdmin &&
                                !auth?.currentUser?.uid &&
                                checkLicenses('signup')
                                  ? '69%'
                                  : '100%',
                            }}
                          >
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: 'Poppins',
                                  fontWeight: activeLink === '/' ? 700 : 400,
                                  textAlign: 'center',
                                  color:
                                    activeLink === '/'
                                      ? environment.mainColor
                                      : environment.darkGreyColor,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleNavigation('/')}
                                // onClick={() => navigate('/')}
                              >
                                {t('home')}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: 'Poppins',
                                  fontWeight:
                                    activeLink === '/restaurants' ? 700 : 400,
                                  textAlign: 'center',
                                  color:
                                    activeLink === '/restaurants'
                                      ? environment.mainColor
                                      : environment.darkGreyColor,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleNavigation('/restaurants')}
                              >
                                {t('restaurants')}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: 'Poppins',
                                  fontWeight:
                                    activeLink === '/careers' ? 700 : 400,
                                  textAlign: 'center',
                                  color:
                                    activeLink === '/careers'
                                      ? environment.mainColor
                                      : environment.darkGreyColor,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleNavigation('/careers')}
                              >
                                {t('career')} 
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: 'Poppins',
                                  fontWeight:
                                    activeLink === '/contactus' ? 700 : 400,
                                  textAlign: 'center',
                                  color:
                                    activeLink === '/contactus'
                                      ? environment.mainColor
                                      : environment.darkGreyColor,
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleNavigation('/contactus')}
                              >
                                {t('contact')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )))}

                    {store.length == 1 ? (
                      <>
                        {/* <Button
                        disableRipple={true}
                        disableFocusRipple={true}
                        sx={{ my: 2, display: 'block' }}
                        disabled={open}
                      >
                        {store &&
                          store?.map((c) => (
                            <CustomButton
                              bg={props.env.mainColor}
                              onClick={async () => {
                                // setDefaultStore(
                                //   token,
                                //   c.id,
                                //   c?.restaurant_name,
                                //   setStore,
                                //   c?.pickup_enabled,
                                //   c?.pickup_enabled,
                                //   c.dinein_enable,
                                //   c.dinein_enable,
                                //   c.address.displayName,
                                //   c?.address?.displayName
                                // );
                                // if (
                                //   userData?.bagItems?.length > 0 &&
                                //   cart?.length > 0
                                // ) {
                                //   dispatch(checkStore(true));
                                // } else dispatch(checkStore(false));

                                // onClickItem(item.restaurant_name)
                                handleClose();
                                const updatedUser = {
                                  ...userData,
                                  defaultStore: c.id,
                                };
                                navigate('/menu');
                                dispatch(setUser({ userData: updatedUser }));
                                dispatch(
                                  activeMenu(res[c?.id]?.active_menu_now)
                                );
                                dispatch(
                                  allactiveMenues(res[c?.id]?.all_active_menus)
                                );
                                dispatch(menues(res[c && c?.id]?.menus));
                              }}
                              buttonText={"sssssssss"}
                            />
                          ))}
                      </Button> */}
                      </>
                    ) : (
                      <>
                        {/* <Button
                          bg={props.env.mainColor}
                          style={{
                            fontWeight: 'bold',
                            color: props.env?.primaryColor
                            // color:
                            //   location.pathname == '/menu'
                            //     ? props.env?.primaryColor
                            //     : 'white',
                          }}
                          onClick={handleClickOpen}
                        >
                          Menu
                        </Button> */}

                        <Dialog onClose={handleClose} open={open}>
                          <div style={{ width: '100%' }}>
                            <div
                              style={{
                                padding: '15px 20px 0px 0px',
                                display: 'flex',
                                justifyContent: 'end',
                                borderRadius: '25px',
                              }}
                            >
                              {' '}
                              <CancelRoundedIcon
                                style={{
                                  color: 'black',
                                  cursor: 'pointer',
                                  marginLeft: '15px',
                                }}
                                onClick={handleClose}
                              />
                            </div>

                            <DialogTitle
                              style={{
                                display: 'grid',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography
                                  component="p"
                                  variant="h5"
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {' '}
                                  {numberToWord(stores?.length)} Branches, One
                                  Appetite!
                                </Typography>
                              </div>

                              <Typography
                                component="p"
                                variant="body1"
                                style={{ color: 'gray' }}
                              >
                                {numberToWord(stores?.length)} Branches, Endless
                                Choices! Explore our menu at your preferred
                                spot.
                              </Typography>

                              <div></div>
                            </DialogTitle>

                            <DialogContent
                              dividers
                              style={{ textAlign: 'center' }}
                            >
                              {stores?.map((c) => (
                                <Grid
                                  item
                                  key={c.id}
                                  lg={4}
                                  style={{
                                    display: 'inline-block',
                                    color: 'white',
                                    textAalign: 'center',
                                    padding: '14px',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Card
                                    style={{
                                      border: '2px solid lightgray',
                                      borderRadius: '10px',
                                      borderColor: 'gray',
                                      width: '180px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={async () => {
                                      setDefaultStore(
                                        token,
                                        c.id,
                                        c?.restaurant_name,
                                        setStore,
                                        c?.pickup_enabled,
                                        c?.pickup_enabled,
                                        c.dinein_enable,
                                        c.dinein_enable,
                                        c.address.displayName,
                                        c?.address?.displayName
                                      );
                                      if (
                                        userData?.bagItems?.length > 0 &&
                                        cart?.length > 0
                                      ) {
                                        dispatch(checkStore(true));
                                      } else dispatch(checkStore(false));
                                      // onClickItem(item.restaurant_name)
                                      handleClose();
                                      const updatedUser = {
                                        ...userData,
                                        defaultStore: c.id,
                                      };
                                      dispatch(
                                        setUser({ userData: updatedUser })
                                      );

                                      if (
                                        environment?.restaurantId ===
                                          'ttJordanDev' ||
                                        environment?.restaurantId === 'ttJordan'
                                      ) {
                                        // Fetch and store menus for the selected store
                                        const selectedStoreMenusRef = firestore
                                          .collection('menus')
                                          .where('store_id', '==', c.id);
                                        selectedStoreMenusRef
                                          .get()
                                          .then((snapshot) => {
                                            const selectedStoreMenusData =
                                              snapshot.docs.map((menuDoc) => {
                                                const menuData = menuDoc.data();
                                                return { ...menuData };
                                              });
                                            const popularItems = [];
                                            if (
                                              selectedStoreMenusData?.length > 0
                                            ) {
                                              selectedStoreMenusData.forEach(
                                                (menu) => {
                                                  menu.category_id.forEach(
                                                    (category) => {
                                                      category.items_id.forEach(
                                                        (itemId) => {
                                                          const item =
                                                            items.find(
                                                              (item) =>
                                                                item.id ===
                                                                itemId
                                                            );
                                                          if (
                                                            item &&
                                                            item.is_popular ===
                                                              true
                                                          ) {
                                                            // If yes, add its ID to the popularItems array
                                                            popularItems.push(
                                                              itemId
                                                            );
                                                          }
                                                        }
                                                      );
                                                    }
                                                  ); // id:Popular //items_id:[]
                                                }
                                              );
                                              // Check if the "Popular" category already exists in the first menu
                                              const popularCategoryIndex =
                                                selectedStoreMenusData[0].category_id.findIndex(
                                                  (category) =>
                                                    category.id === 'Popular'
                                                );
                                              // If "Popular" category exists, update its items_id
                                              if (popularCategoryIndex !== -1) {
                                                selectedStoreMenusData[0].category_id[
                                                  popularCategoryIndex
                                                ].items_id = popularItems;
                                              } else {
                                                selectedStoreMenusData[0].category_id.unshift(
                                                  {
                                                    id: 'Popular',
                                                    items_id: popularItems,
                                                  }
                                                );
                                              }
                                            }
                                            dispatch(
                                              menues(selectedStoreMenusData)
                                            );
                                            dispatch(
                                              allactiveMenues(
                                                selectedStoreMenusData
                                                  .filter(
                                                    (item) =>
                                                      item.off_menu === false
                                                  )
                                                  .map((item) => item.id)
                                              )
                                            );
                                          });
                                      } else {
                                        dispatch(
                                          activeMenu(res[c.id]?.active_menu_now)
                                        );
                                        dispatch(
                                          allactiveMenues(
                                            res[c.id]?.all_active_menus
                                          )
                                        );
                                        dispatch(menues(res[c?.id]?.menus));
                                      }
                                      navigate('/menu');
                                    }}
                                  >
                                    <CardContent
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        height: '245px',
                                      }}
                                    >
                                      <img
                                        src={c.image}
                                        alt={c.image}
                                        style={{
                                          width: '100px',
                                          height: '80px',
                                        }}
                                      />
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontWeight: 'bold',
                                          color: 'black',
                                        }}
                                      >
                                        {c.restaurant_name}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        style={{ color: 'gray' }}
                                      >
                                        {c.address.displayName
                                          .split(',')
                                          .slice(0, 2)
                                          .join(',')}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </DialogContent>
                          </div>
                        </Dialog>
                      </>
                    )}
                  </>
                )}
                <Hidden smDown></Hidden>
                {!props.isAdmin &&
                  auth?.currentUser?.uid &&
                  checkLicenses('profile') && (
                    <>
                      <div
                        style={{
                          border: '6px solid white',
                          background: 'white',
                          borderRadius: '20px',
                          marginRight: '14px',
                          marginLeft: '10px',
                          boxShadow:
                            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        }}
                      >
                        <PersonOutlineSharpIcon
                          style={{
                            color: props.env.mainColor,
                            cursor: 'pointer',
                          }}
                          onClick={() => navigate('/profile')}
                        />
                      </div>
                    </>
                  )}
              </>
            )}
            <div>
              {!props.isAdmin &&
              !auth?.currentUser?.uid &&
              checkLicenses('signup') ? (
                <>
                  <Hidden smUp>
                    <CustomButton
                      bg={props.env.mainColor}
                      onClick={() => navigate('/signup')}
                      buttonText={t('Joinus')}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <CustomButton
                      bg={props.env.mainColor}
                      onClick={() => navigate('/signup')}
                      buttonText={t('signIn') + '/' + t('Joinus')}
                      style={{}}
                    />
                  </Hidden>
                </>
              ) : (
                // </Button>
                ''
              )}
            </div>

            {!props.isAdmin &&
            checkLicenses('checkout') &&
            auth?.currentUser?.uid ? (
              <>
                <CartDrawer
                  openCartDialoge={openCartDialoge}
                  handleCloseCartDialoge={handleCloseCartDialoge}
                  addresses={addresses}
                  userData={userData}
                  defaultStoreAdress={defaultStoreAdress}
                  setDefaultStoreAdress={setDefaultStoreAdress}
                />
                {counter > 0 ? (
                  <Badge
                    badgeContent={counter > 0 ? counter : ''}
                    sx={{
                      '& .MuiBadge-badge': {
                        color: 'white',
                        backgroundColor: props.env.mainColor,
                      },
                    }}
                    style={{
                      marginBottom: '1pc',
                      background: 'radial-gradient(black, transparent)',
                    }}
                  ></Badge>
                ) : (
                  ''
                )}

                <Hidden smUp>
                  <ShoppingCartIcon
                    sx={{
                      color: 'white',
                      width: '10%',
                      display: 'flex',
                      borderRadius: '30px',
                      height: '35px',
                      backgroundColor: props.env.mainColor,
                      padding: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={handleClickOpenCartDialoge}
                  />
                </Hidden>
                <Hidden smDown>
                  <ShoppingCartIcon
                    sx={{
                      color: 'white',
                      width: '3%',
                      display: 'flex',
                      borderRadius: '30px',
                      height: '35px',
                      backgroundColor: props.env.mainColor,
                      padding: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={handleClickOpenCartDialoge}
                  />
                </Hidden>

                {/* </IconButton> */}
              </>
            ) : (
              !auth?.currentUser?.uid &&
              storedItems && (
                <>
                  <CartDrawer
                    openCartDialoge={openCartDialoge}
                    handleCloseCartDialoge={handleCloseCartDialoge}
                    addresses={addresses}
                    userData={userData}
                    defaultStoreAdress={defaultStoreAdress}
                    setDefaultStoreAdress={setDefaultStoreAdress}
                  />
                  {counter > 0 ? (
                    <Badge
                      badgeContent={counter > 0 ? counter : ''}
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                          backgroundColor: props.env.mainColor,
                        },
                      }}
                      style={{
                        marginBottom: '1pc',
                        background: 'radial-gradient(black, transparent)',
                      }}
                    ></Badge>
                  ) : (
                    ''
                  )}

                  <Hidden smUp>
                    <ShoppingCartIcon
                      sx={{
                        color: 'white',
                        width: '10%',
                        display: 'flex',
                        borderRadius: '30px',
                        height: '35px',
                        backgroundColor: props.env.mainColor,
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClickOpenCartDialoge}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <ShoppingCartIcon
                      sx={{
                        color: 'white',
                        width: '3%',
                        display: 'flex',
                        borderRadius: '30px',
                        height: '35px',
                        backgroundColor: props.env.mainColor,
                        padding: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClickOpenCartDialoge}
                    />
                  </Hidden>
                </>
              )
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Formik
                initialValues={{
                  paymentFlow: '',
                  orderFlow: '',
                }}
                onSubmit={handleSubmit}
              >
                {({
                  handleBlur,
                  handleChange,
                  values,
                  errors,
                  handleSubmit,
                  touched,
                }) =>
                  env && (
                    <Dialog
                      style={{ zIndex: 10 }}
                      open={openDialog}
                      keepMounted
                      onClose={() => {
                        setOpenDialog(false);
                      }}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <div style={{ height: '200px', width: '200px' }}>
                          <Box sx={{ minWidth: 120 }}>
                            <div style={{ height: '20px' }} />
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Payment flow
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={env?.paymentFlow}
                                value={env?.paymentFlow}
                                label="Payment flow"
                                name={'paymentFlow'}
                                onChange={handleChange('paymentFlow')}
                              >
                                <MenuItem value={'clover'}>Clover</MenuItem>
                                <MenuItem value={'stripe'}>Stripe</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <div style={{ height: '30px' }} />
                          <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Order flow
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={env?.orderFlow}
                                defaultValue={env?.orderFlow}
                                label="Order flow"
                                name={'orderFlow'}
                                onChange={handleChange('orderFlow')}
                              >
                                <MenuItem value={'tablet'}>
                                  Kitchen app
                                </MenuItem>
                                <MenuItem value={'pos'}>Point of sale</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          style={{ color: props.env.mainColor }}
                          onClick={() => {
                            setOpenDialog(false);
                          }}
                        >
                          cancel
                        </Button>
                        <Button
                          style={{ color: props.env.mainColor }}
                          onClick={handleSubmit}
                        >
                          save
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )
                }
              </Formik>
              {auth?.currentUser?.uid && props?.isAdmin && env && (
                <Tooltip
                  title="App setting"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SettingsIcon
                    style={{
                      color: props.env.mainColor,
                      marginLeft: '5px',
                      cursor: 'pointer',
                      height: 30,
                    }}
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  />
                </Tooltip>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {auth?.currentUser?.uid &&
                !props.isAdmin &&
                checkLicenses('signup') && (
                  <Tooltip
                    title="Log out"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ExitToAppIcon
                      style={{
                        color:
                          props.env.restaurantId === 'ttDemo'
                            ? props.env.mainColor
                            : props.env.restaurantId != 'istanbulDk' ||
                              props.env.restaurantId != 'taqwasbakery' ||
                              props.env.restaurantId != 'alyousef'
                            ? props.env.mainColor
                            : location.pathname == '/' ||
                              location.pathname == '/menu-items' ||
                              location.pathname == '/careers' ||
                              location.pathname == '/contactus'
                            ? 'white'
                            : props.env.mainColor,
                        marginLeft: '5px',
                        cursor: 'pointer',
                        height: 30,
                        transform:
                          i18n.language === 'ar' ? 'scaleX(-1.0)' : 'none',
                      }}
                      onClick={signOut}
                    />
                  </Tooltip>
                )}
              {auth?.currentUser?.uid && props?.isAdmin && (
                <Tooltip
                  title="Log out"
                  dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ExitToAppIcon
                    style={{
                      color: props.env.mainColor,
                      marginLeft: '5px',
                      cursor: 'pointer',
                      height: 30,
                    }}
                    onClick={signOut}
                  />
                </Tooltip>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    )
  );
}
export default Header;
