import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMapReact from 'google-map-react';

const Marker = ({ text, x }) => (
  <LocationOnIcon
    style={{
      color: 'blue',
    }}
    width="100px"
    height="100px"
  />
);

const GoogleMap = ({
  center = {
    lat:  42.960280,
    lng:-87.949702,
   
  },
  zoom = 16,
}) => {
  return (
    <div>
      <div style={{ height: '500px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCjXKmwz0IWhznLipLL8OOqJs579HNYvqw' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
            
          <Marker lat={center.lat} lng={center.lng} text="My Marker" />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default GoogleMap;
