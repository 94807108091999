import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import App from './app/app';
import { ReduxWrapper } from '../../../libs/components/src';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ReduxWrapper>
      <App />
    </ReduxWrapper>
  </BrowserRouter>
);
