import React, { useRef, useState } from 'react';
import {
  createStyles,
  Paper,
  TextField,
  useTheme,
  Container,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import styled from '@emotion/styled';
import { firestore } from '@tabletuck/firebase-services';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CustomButton } from '@tabletuck/components';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { useSelector } from 'react-redux';

const useStyles = styled((theme) =>
  createStyles({
    root: {
      display: 'flex',

      borderRadius: '30px',
    },
    form: {
      display: 'contents',
    },
    input: {
      flex: 1,
      height: '100%',
      minHeight: '100%',
      '& .MuiFilledInput-underline:after': {
        border: 'none',
      },
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
      '&.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& fieldset': {
        border: 'none',
      },
      '& input': {
        fontSize: '14px',
      },
      '& label': {
        fontSize: '14px',
        color: '#666',
      },
      '& .MuiInputBase-root': {
        borderRadius: 0,
      },
    },
    select: {
      borderRadius: '29px 0 0 29px',
      width: '25%',
      background: '#F5F3F4',
      borderRight: '1px solid #ccc',
      fontSize: '0.75rem',
      '&.MuiNativeSelect-root .MuiNativeSelect-select': {
        height: '100%',
      },
      '& select': {
        height: '100%',
        paddingLeft: '1rem',
      },
      overflow: 'hidden',
      '&.MuiInput-underline:after': {
        border: 'none !important',
      },
      '&.MuiInput-underline': {
        '&::before': {
          content: 'initial !important',
          borderBottom: '0 !important',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    serachBtn: {
      color: '#fff',
      borderRadius: '0px 29px 29px 0px',
      '&:hover': {
        background: `${theme.palette.primary.main} !important`,
      },
    },
  })
);

export function CategorySearchBar(props) {
  const classes = useStyles();
  const formRef = useRef(null);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const items = useSelector((state) => state.shopReducer.currentItem);

  const handleAvailabilityChange = (event) => {
    const { checked } = event.target;
    props.onFilterByAvailabilityChange(checked);
  };

  const handleChange = (e) => {
    const search = e.target.value.toLowerCase();
    const filterdName = items.filter((names) =>
      names.name.toLowerCase().includes(search)
    );
    props.updateSearch(filterdName);
  };
  return (
    <>
      <Container
        maxWidth={'lg'}
        style={{ marginTop: '104px', marginBottom: '30px' }}
      >
        <Paper
          sx={{
            width: { xs: 'none', md: '640px' },
            marginLeft: xsDown ? '-13px' : 'unset',
            transform: xsDown ? 'scale(0.94)' : 'scale(1)',
          }}
        >
          <form ref={formRef} className={classes.form}>
            <div style={{ display: 'contents' }}>
              <TextField
                label="Search a product here."
                variant="filled"
                sx={{
                  '& label.Mui-focused': {
                    color: 'black',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                  },
                }}
                onChange={(e) => handleChange(e)}
                InputProps={{ type: 'search' }}
                style={{
                  width: xsDown ? '400px' : '100%',
                  color: props.env.mainColor,
                }}
              />
            </div>
          </form>
        </Paper>
      </Container>

      <Hidden xsDown>
        {props.admin && (
          <div style={{ width: '200px', marginTop: '100px' }}>
            <CustomButton
              bg={props.env?.mainColor}
              onClick={() => navigate('/details/add')}
              buttonText={'Add new Item'}
              admin={true}
            />
          </div>
        )}
      </Hidden>
      <br/>
      {props.admin && (
        <Container
          maxWidth={'lg'}
          style={{ marginTop: '104px', marginBottom: '30px' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={props.filterByAvailability}
                onChange={handleAvailabilityChange}
              />
            }
            label="Available Items"
          />
        </Container>
      )}
    </>
  );
}
export default CategorySearchBar;
