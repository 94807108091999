import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card, CardContent, TextField } from '@mui/material';
import { Theme } from '@mui/material';
import { environment } from '../../../environments/environment';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';
let searchOptions;

if (
  environment.restaurantId === 'ttJordanDev' ||
  environment.restaurantId === 'ttJordan'
) {
  searchOptions = {
    types: ['geocode'],
    componentRestrictions: { country: 'JO' },
  };
} else {
  searchOptions = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' },
  };
}

const AddressPickerWithGoogle = React.forwardRef(
  ({ initialValue, getValues }) => {
    const [googleAddress, setGoogleAddress] = useState('');

    useEffect(() => {
      if (initialValue) setGoogleAddress(initialValue);
    }, [initialValue]);

    const [showOptions, setShowOptions] = useState(false);

    // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    const handleSelect = async (address) => {
      setShowOptions(false);
      setGoogleAddress(address);

      let route = '';
      let country = '';
      let state = '';
      let city = '';
      let zipCode = '';
      let streetNumber = '';

      const geoCode = await geocodeByAddress(address);

      const { lat, lng } = await getLatLng(geoCode[0]);

      const placeId = geoCode[0].place_id;
      const displayName = geoCode[0].formatted_address;

      geoCode[0]['address_components'].forEach((addressComponent) => {
        const { types } = addressComponent;

        if (types.includes('country')) {
          country = addressComponent['short_name'];
        }

        if (types.includes('route')) {
          route += addressComponent['short_name'];
        }
        if (types.includes('street_number')) {
          streetNumber += ' ' + addressComponent['short_name'];
        }

        if (types.includes('postal_code')) {
          zipCode = addressComponent['short_name'];
        }
        if (types.includes('administrative_area_level_1')) {
          state = addressComponent['short_name'];
        }
        if (
          types.includes('sublocality_level_1') ||
          types.includes('administrative_area_level_3') ||
          types.includes('locality') ||
          types.includes('sublocality_level_1')
        ) {
          city = addressComponent['short_name'];
        }
      });
      getValues({
        country,
        streetNumber,
        state,
        city,
        zipCode,
        placeId,
        displayName,
        lat,
        lng,
        route,
      });
    };
    useEffect(() => {
      if (googleAddress.trim() === '') {
        getValues({});
      }
    }, [googleAddress]);
    const [t, i18n] = useTranslation();
    return (
      <PlacesAutocomplete
        // ref={ref}
        searchOptions={searchOptions}
        value={googleAddress}
        onChange={(value) => {
          if (value) setShowOptions(true);
          else setShowOptions(false);
          setGoogleAddress(value);
        }}
        onSelect={handleSelect}
      >
        {({ getInputProps, loading, suggestions, getSuggestionItemProps }) => (
          <div>
            <TextField
              label={t('profilee.searchAddress')}
              aria-label="search-address"
              variant="outlined"
              name="googleSearchField"
              sx={{
                '& label.Mui-focused': {
                  color: 'black',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'gray',
                  },
                },
              }}
              style={{ width: '100%' }}
              {...getInputProps({ placeholder: t('profilee.typeToSearch') })}
              onBlur={() => {
                setShowOptions(false);
              }}
            />
            {showOptions && (
              <Card id="search-address">
                <CardContent>
                  {suggestions.length === 0 ? (
                    <div>{t('profilee.loading')}</div>
                  ) : (
                    suggestions.map((s, index) => {
                      return (
                        <div {...getSuggestionItemProps(s)} key={index}>
                          <Grid container alignItems="center">
                            <Grid item>
                              {/* <LocationOnIcon className={classes.icon} /> */}
                            </Grid>
                            <Grid item xs>
                              <Typography variant="body1" color="textPrimary">
                                {s.formattedSuggestion.mainText}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {s.formattedSuggestion.secondaryText}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })
                  )}
                </CardContent>
              </Card>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
);

export default AddressPickerWithGoogle;
