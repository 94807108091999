import React, { useRef } from 'react';
import {
  createStyles,
  Paper,
  TextField,
  useTheme,
  Container,
} from '@mui/material';
import styled from '@emotion/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import { environment } from 'apps/table-tuck-web/src/environments/environment';

const useStyles = styled((theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '640px',
      borderRadius: '30px',
    },
    form: {
      display: 'contents',
    },
    input: {
      flex: 1,
      height: '100%',
      minHeight: '100%',
      '& .MuiFilledInput-underline:after': {
        border: 'none',
      },
      '& .MuiFormControl-root': {
        margin: '0 !important',
      },
      '&.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& fieldset': {
        border: 'none',
      },
      '& input': {
        fontSize: '14px',
      },
      '& label': {
        fontSize: '14px',
        color: '#666',
      },
      '& .MuiInputBase-root': {
        borderRadius: 0,
      },
    },
    select: {
      borderRadius: '29px 0 0 29px',
      width: '25%',
      background: '#F5F3F4',
      borderRight: '1px solid #ccc',
      fontSize: '0.75rem',
      '&.MuiNativeSelect-root .MuiNativeSelect-select': {
        height: '100%',
      },
      '& select': {
        height: '100%',
        paddingLeft: '1rem',
      },
      overflow: 'hidden',
      '&.MuiInput-underline:after': {
        border: 'none !important',
      },
      '&.MuiInput-underline': {
        '&::before': {
          content: 'initial !important',
          borderBottom: '0 !important',
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    serachBtn: {
      background: environment.mainColor,
      color: '#fff',
      borderRadius: '0px 29px 29px 0px',
      '&:hover': {
        background: `${theme.palette.primary.main} !important`,
      },
    },
  })
);

const SearchAddress = () => {
  const classes = useStyles();
  const formRef = useRef(null);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Container
      maxWidth={'lg'}
      style={{ marginTop: '20px', marginBottom: '30px' }}
    >
      <Paper
        style={{
          width: xsDown ? 'fit-content' : '500px',
          marginLeft: xsDown ? '-13px' : 'unset',
          transform: xsDown ? 'scale(0.94)' : 'scale(1)',
        }}
      >
        <form ref={formRef} className={classes.form}>
          <div style={{ display: 'contents' }}>
            <TextField
              label="Search fo address here."
              variant="outlined"
              onChange={() => console.log('')}
              InputProps={{ type: 'search' }}
              style={{
                width: xsDown ? '400px' : '100%',
                color: environment.mainColor,
              }}
              sx={{
                        '& label.Mui-focused': {
                          color: 'gray',
                          fontWeight:'500',
                          fontSize:18
                          // display:'none'
                        },
                        '& .MuiOutlinedInput-root': {      
                        '&.Mui-focused fieldset': {
                          borderColor: 'gray' ,
                        },
                        },
                      }}
            />
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default SearchAddress;
