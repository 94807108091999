import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { environment } from 'apps/table-tuck-web/src/environments/environment';
import { useSelector } from 'react-redux';
import { getStrores, setDefaultStore } from './utils';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { setStores, updateUser } from '@tabletuck/redux-slices';
import { firestore } from '../../../../../../libs/firebase-service/src';
import { setUser } from '../../../../../../libs/redux-slices/src';
import { useTranslation } from 'react-i18next';

function DailogForm({
  open,
  handleClose,
  type,
  onClickItem,
  title,
  setErr,
  enablePickup,
  enableDinin,
  defaultStoreAdress,
  setDefaultStoreAdress,
  onClickAdress,
}) {
  const [t, i18n] = useTranslation();
  const token = useSelector((state) => state.authReducer.token);
  const [items, setItems] = useState([]);
  const stores = useSelector((state) => state.shopReducer.stores);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.userData);
  useEffect(() => {
    if (stores?.length > 0) {
      setItems(stores);
    }
  }, [stores]);

  return (
    <Dialog onClose={handleClose} open={open} style={{ borderRadius: '250px' }}>
      <div style={{ width: '32pc' }}>
        <div
          style={{
            padding: '15px 20px 0px 0px',
            display: 'flex',
            justifyContent: 'end',
            borderRadius: '25px',
          }}
        >
          {' '}
          <CancelRoundedIcon
            style={{
              color: 'black',
              cursor: 'pointer',
              marginLeft: '15px',
            }}
            onClick={handleClose}
          />
        </div>

        <DialogTitle
          style={{
            display: 'grid',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              component="p"
              variant="h4"
              style={{ fontWeight: 'bold' }}
            >
              {' '}
              {type === 'dinein'
                ? `${t('menu.selectDineStore')}`
                : `${t('menu.selectPickupStore')}`}{' '}
            </Typography>
          </div>

          
        </DialogTitle>

        <DialogContent dividers>
          {items?.map((item, index) => (
            <Box key={item.id} style={{ marginTop: '20px' }}>
              <div class="p-2 flex flex-col justify-start cursor-pointer">
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={async () => {
                    setDefaultStore(
                      token,
                      item.id,
                      item?.restaurant_name,
                      onClickItem,
                      item?.pickup_enabled,
                      enablePickup,
                      item.dinein_enable,
                      enableDinin,
                      item.address.displayName,
                      onClickAdress
                    );
                    
                    handleClose();
                    const updatedUser = { ...userData, defaultStore: item.id };
                    dispatch(setUser({ userData: updatedUser }));
                  }}
                >
                  <StorefrontIcon
                    fontSize="large"
                    style={{
                      color: 'white',
                      backgroundColor: environment.mainColor,
                      width: '50px',
                      height: '50px',
                      borderRadius: '5px',
                    }}
                  />
                  <h5
                    class="text-gray-900 text-xl font-medium"
                    style={{ marginLeft: '10px' }}
                  >
                    {item.restaurant_name}
                    <br />{' '}
                    <p style={{ fontSize: '12px' }}>
                      {item?.address?.displayName}
                    </p>
                  </h5>
                </div>
              </div>

              <Divider />
            </Box>
          ))}
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default DailogForm;
