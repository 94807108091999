import React, { useState } from 'react';
import { useTheme, } from '@mui/material';
import { firestore } from '@tabletuck/firebase-services';
import background from '../../../assets/banner.png';
import MenuDisplay from "../../components/Menu's/Menu-display";
import { useSelector } from 'react-redux';

function Menu() {
  const theme = useTheme();
  const items = useSelector((state) => state.shopReducer.currentItem);
  // const [items, setItems] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const getItems = async () => {
  //   let itemsRef = firestore.collection('items');
  //   let items = [];
  //   const itemsList = await itemsRef.get();
  //   for (let index = 0; index < itemsList.docs.length; index++) {
  //     let element = itemsList.docs[index].data();
  //     element.id = itemsList.docs[index].id;
  //     items.push(element);
  //   }
  //   setItems(items);
  // };

  // React.useEffect(() => {
  //   getItems();
  // }, []);

  return (
    <>
       <img
       src={background}
       alt='background'
     />
      
     

      <MenuDisplay />
    </>
  );
}
export default Menu;
