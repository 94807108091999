import React from 'react';
import TTJMainFooter from './TTJMainFooter';
import RegisterFooter from './RegisterFooter';
import DownloadApp from './DownloadApp';
import { auth } from '@tabletuck/firebase-services';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
const FooterTTJ = (props) => {
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const check = window.localStorage.getItem('items');
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'categories':
        return licenses?.licenses?.dashboard?.categories;
      case 'signup':
        return  licenses?.licenses?.frontend?.signup
      case 'profile':
        return  licenses?.licenses?.frontend?.profile
      case 'checkout':
        return  licenses?.licenses?.frontend?.checkout 
      case 'items':
        return licenses?.licenses?.dashboard?.items;
      case 'drivers':
        return licenses?.licenses?.dashboard?.drivers;
      case 'modifierGroup':
        return licenses?.licenses?.dashboard?.modifierGroup;
      case 'admins':
        return licenses?.licenses?.dashboard?.admins;
      case 'stores':
        return licenses?.licenses?.dashboard?.stores;
      case 'offers':
        return licenses?.licenses?.dashboard?.offers;
      case 'orderHistory':
        return licenses?.licenses?.dashboard?.orderHistory;

      default:
        return false;
    }
  };
  return (
    <div style={{ marginTop: isLGScreen? "120px" :'80px' }}>
      <DownloadApp />
      {!props?.isAdmin && !auth?.currentUser?.uid && checkLicenses('signup') && (
        <RegisterFooter />
      )}
      <TTJMainFooter />
    </div>
  );
};

export default FooterTTJ;
