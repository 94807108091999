import { useEffect, useState } from 'react';
import { connectRestaurantDb, firestore, getAppLicenses } from './firebase';

import { useDispatch, useSelector } from 'react-redux';
import { setlicences } from 'libs/redux-slices/src/lib/slices/licensesSlice';

export function FirebaseService({ children, configs, restaurantId, isRoot }) {
  const [isReady, setIsReady] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isRoot) {
      if (configs && restaurantId) {
        connectToFirebase(configs, restaurantId);
      }
    }
  }, [configs, restaurantId]);

  const connectToFirebase = async (configs, restaurantId) => {
    const isReady = await connectRestaurantDb(restaurantId, configs);
    setIsReady(isReady);
    // if (
    //   environment?.restaurantId === 'ttJordanDev' ||
    //   environment?.restaurantId === 'ttJordan'
    // ) {

    //   async function getRestaurantttJordanLicenses(restaurantId) {
    //     try {
    //       const querySnapshot = await firestore
    //         .collection('licenses') 
    //         .doc(restaurantId)
    //         .get();
      
    //       if (querySnapshot.exists) {
    //         const licenseData = querySnapshot.data().licenses;
    //         return licenseData;

    //       } else {
    //         console.log('No licenses found for restaurant ID:', restaurantId);
    //       }
    //     } catch (error) {
    //       console.log('error', error);
    //     }
    //   }
      
    //   const res = await getRestaurantttJordanLicenses(restaurantId);
    //   console.log("licences tt jordan",res)
    //   dispatch(setlicences(res));
    // } else {
      const res = await getAppLicenses(restaurantId);

      dispatch(setlicences(res));
    // }
  };

  return (
    <div style={{ flex: 1 }}>
      {isReady ? (
        <div style={{ flex: 1 }}>{children}</div>
      ) : (
        <div style={{ flex: 1 }}></div>
      )}
      {isRoot ? (
        <div style={{ flex: 1 }}>{children}</div>
      ) : (
        <div style={{ flex: 1 }}></div>
      )}
    </div>
  );
}
export default FirebaseService;
