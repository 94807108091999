import {
    Button,
    Divider,
    Grid,
    Hidden,
    Paper,
    Typography,
    useMediaQuery,
  } from '@mui/material';
  import { Container } from '@mui/system';
  import React, { useState } from 'react';
  import { useTranslation } from 'react-i18next';
  import backgroundHomeSchool from '../../../assets/backgroundhomeschool.png';
  import footer from '../../../assets/background.png';
  import { environment } from '../../../environments/environment';
  import aboutFilfil from '../../../assets/aboutfilfil.png';
  import LocationOnIcon from '@mui/icons-material/LocationOn';
  import aboutfilfilhome from '../../../assets/aboutfilfilhome.png';
  import about1 from '../../../assets/grid.webp';
  import about2 from '../../../assets/about2.png';
  import gridmin1 from '../../../assets/gridmin1.png';
  import gridmin2 from '../../../assets/gridmin2.png';
  import gridmin3 from '../../../assets/gridmin3.png';
  import gridmin4 from '../../../assets/gridmin4.png';
  import gridmin5 from '../../../assets/gridmin5.png';
  import gridmin6 from '../../../assets/gridmin6.png';
  import gridmin7 from '../../../assets/gridmin7.png';
  import Modal from 'react-modal';
  import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
  import ChevronRightIcon from '@mui/icons-material/ChevronRight';
  import { auth } from '../../../../../../libs/firebase-service/src';
  import { useNavigate } from 'react-router';
  import { useSelector } from 'react-redux';
  import { useTheme } from '@emotion/react';
  import pop from '../../../assets/pop.png';
  
  function AboutFilfil() {
    const [t] = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const userData = useSelector((state) => state.authReducer.userData);
    const navigate = useNavigate();
    const theme = useTheme();
    const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  
    const openModal = (index) => {
      setSelectedImageIndex(index);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setSelectedImageIndex(0);
      setIsModalOpen(false);
    };
    const handleNextImage = () => {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === smallImages.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const handlePrevImage = () => {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? smallImages.length - 1 : prevIndex - 1
      );
    };
    const bigImage = about1;
    const smallImages = [
      `${gridmin1}`,
      `${gridmin5}`,
      `${gridmin7}`,
      `${gridmin2}`,
      `${gridmin3}`,
      `${gridmin4}`,
    ];
  
    return (
      <>
        <div
          style={{
            height: '180px',
            backgroundColor: 'maroon',
            display: 'flex',
            justifyContent: 'center',
        //  paddingBottom:'50px',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
        
          <Typography
            variant="body1"
            style={{
              color: 'white',
              opacity: '0.8',
              cursor: 'pointer',
              marginTop: '60px',
              marginBottom:'60px',
              height: '100px',
            }}
          >
            <span style={{fontWeight:'bold', fontSize:'30px'}}>
          OUR LOCATIONS
            </span>
          
            <br/> 
  
            {' '}
            <LocationOnIcon style={{ color: 'yellow' }} />{' '}
            <a
              href={
                'https://www.google.com/maps/place/700+W+Wisconsin+Ave+%23A,+Milwaukee,+WI+53233,+EUA/@43.0389455,-87.9205169,17z/data=!3m1!4b1!4m6!3m5!1s0x8805199d8fc34fc9:0x19a0735cad76b699!8m2!3d43.0389455!4d-87.9205169!16s%2Fg%2F11qzdbhjb8'
              }
              target="_blank"
            >
              {environment.address}
            </a>
            <br />
            <span>
              <LocationOnIcon style={{ color: 'yellow' }} />{' '}
              <a
                href={
                  'https://www.google.com/maps/place/1125+N+9th+St,+Milwaukee,+WI+53233,+USA/@43.0449532,-87.926173,17z/data=!3m1!4b1!4m6!3m5!1s0x8805197a07844bf1:0x6753493f28655d70!8m2!3d43.0449532!4d-87.9235981!16s%2Fg%2F11c1xpx0z9?entry=ttu'
                }
                target="_blank"
              >
                1125 N 9th St, Milwaukee
              </a>
            </span>
          </Typography>{' '}
        </div>
        <Hidden smDown>
          <Grid container>
            {/* First row */}
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper style={{ height: '400px' }}>
                <img
                  src={about2}
                  alt="Image 1"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper style={{ height: '400px' }}>
                <div
                  style={{
                    position: 'relative',
                    height: '400px',
                    backgroundImage: `url(${aboutfilfilhome})`,
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={8}>
                    <div>
                      <Typography
                        variant="h3"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        ABOUT US
                      </Typography>
  
                      <Typography
                        variant="h6"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        SAVORY. DELIGHTFUL. UNFORGETTABLE
                      </Typography>
  
                      <Typography
                        variant="subtitle1"
                        style={{ color: 'white', marginTop: '10px' }}
                      >
                        Welcome to FilFil, where passion meets taste. We are
                        dedicated to bringing you the finest culinary experience
                        with our authentic and innovative dishes.
                      </Typography>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Grid>
  
            {/* Second row */}
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper style={{ height: '400px' }}>
                <div
                  style={{
                    position: 'relative',
                    height: '400px',
                    backgroundImage: `url(${aboutfilfilhome})`,
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={8}>
                    <div>
                      <Typography
                        variant="h3"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        OUR CONCEPT
                      </Typography>
  
                      <Typography
                        variant="h6"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        Delightful Culinary Creations: Where Passion Meets Plate!
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ color: 'white', marginTop: '10px' }}
                      >
                        We are committed to crafting delicious meals, from the
                        classic FilFil Sandwich to innovative vegan options. Come
                        join us for a meal today, and experience the love and
                        passion that goes into each and every dish.
                      </Typography>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper style={{ height: '400px' }}>
                <img
                  src={about1}
                  alt="Image 4"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Hidden>
  
        <Hidden smUp>
          <Grid container>
            {/* First row */}
  
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper>
                <div
                  style={{
                    position: 'relative',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    backgroundImage: `url(${aboutfilfilhome})`,
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={8}>
                    <div>
                      <Typography
                        variant="h4"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        ABOUT US
                      </Typography>
  
                      <Typography
                        variant="body1"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        SAVORY. DELIGHTFUL. UNFORGETTABLE
                      </Typography>
  
                      <Typography
                        variant="subtitle1"
                        style={{ color: 'white', marginTop: '10px' }}
                      >
                        Welcome to FilFil, where passion meets taste. We are
                        dedicated to bringing you the finest culinary experience
                        with our authentic and innovative dishes.
                      </Typography>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper style={{ height: '500px' }}>
                <img
                  src={about2}
                  alt="Image 1"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Paper>
            </Grid>
            {/* Second row */}
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper>
                <div
                  style={{
                    position: 'relative',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    backgroundImage: `url(${aboutfilfilhome})`,
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={8}>
                    <div>
                      <Typography
                        variant="h4"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        OUR CONCEPT
                      </Typography>
  
                      <Typography
                        variant="body1"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          marginTop: '10px',
                        }}
                      >
                        Delightful Culinary Creations: Where Passion Meets Plate!
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ color: 'white', marginTop: '10px' }}
                      >
                        We are committed to crafting delicious meals, from the
                        classic FilFil Sandwich to innovative vegan options. Come
                        join us for a meal today, and experience the love and
                        passion that goes into each and every dish.
                      </Typography>
                    </div>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6}>
              <Paper style={{ height: '500px' }}>
                <img
                  src={about1}
                  alt="Image 4"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Hidden>
  
        <div
          style={{
            position: 'relative',
            height: '300px',
            backgroundImage: `url(${pop})`,
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid item xs={5}>
            <div>
              <Hidden smUp>
                <Typography
                  variant="h4"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    marginTop: '10px',
                  }}
                >
                  KNOWN FOR
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'white',
                    marginTop: '10px',
                    // width: smDown ? '0x' : '550px',
                  }}
                >
                  At Fil Fil, it's all about orafting your perfect protein bowl!
                  Fresh, flavorful, and totally customizable. It's like creating
                  your own delicious masterpiece in a bowl.
                </Typography>
              </Hidden>
              <Hidden smDown>
                <Typography
                  variant="h3"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    marginTop: '10px',
                  }}
                >
                  KNOWN FOR
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'white',
                    marginTop: '10px',
                    width: '550px',
                  }}
                >
                  At Fil Fil, it's all about orafting your perfect protein bowl!
                  Fresh, flavorful, and totally customizable. It's like creating
                  your own delicious masterpiece in a bowl.
                </Typography>
              </Hidden>
  
              {auth?.currentUser?.uid && userData?.defaultStore?.length > 0 ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'black',
                    marginTop: '20px',
                  }}
                  onClick={() => navigate('/menu')}
                >
                  Explore Our Menu
                </Button>
              ) : (
                ''
              )}
            </div>
          </Grid>
        </div>
  
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {/* Big Image on the Left */}
            <img
              src={bigImage}
              alt="Big Image"
              style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
              // onClick={() => openModal(0)}
            />
          </div>
          <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
            {/* Two Rows of Three Small Images on the Right */}
            {smallImages.map((smallImage, index) => (
              <img
                key={index}
                src={smallImage}
                alt={`Small Image ${index + 1}`}
                style={{
                  width: '33.33%',
                  height: 'auto',
                  cursor: 'pointer',
                  transform:
                    hoveredImageIndex === index ? 'scale(1.1)' : 'scale(1)',
                  transition: '.3s ease-in-out',
                }}
                onMouseEnter={() => setHoveredImageIndex(index)}
                onMouseLeave={() => setHoveredImageIndex(null)}
                onClick={() => openModal(index)}
              />
            ))}
          </div>
        </div>
  
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              border: 'none',
              background: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
          }}
        >
          {/* Close Icon */}
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              fontSize: '24px',
            }}
            onClick={closeModal}
          >
            &#10006;
          </button>
  
          {/* Navigation Arrows */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              background: 'transparent',
            }}
          >
            <button onClick={handlePrevImage}>
              <ChevronLeftIcon
                style={{
                  color: 'maroon',
                  backgroundColor: 'white',
                  fontSize: '36px',
                }}
              />
            </button>
            <button onClick={handleNextImage}>
              <ChevronRightIcon
                style={{
                  color: 'maroon',
                  backgroundColor: 'white',
                  fontSize: '36px',
                }}
              />
            </button>
          </div>
  
          {/* Selected Image */}
          <img
            src={smallImages[selectedImageIndex]}
            alt="Selected Image"
            style={{ width: '60%', height: 'auto' }}
          />
        </Modal>
      </>
    );
  }
  export default AboutFilfil;
  