import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import SignUp from '../Pages/Signup/Signup';
import Details from '../Pages/Details';
import ProtectedRoutes from '../protected-routes/Protected-routes';
import Home from '../Pages/Menu/Home';
import Profile from '../Pages/Profile/Profile';
import PaymentHandler from '../Pages/PaymentHandler/PaymentHandler';
import ConfirmOrderPage from '../Pages/CheckoutPage/ConfirmOrderPage';
import Menu from '../Pages/Menu/Menu';
import { useEffect } from 'react';
import { Licenses } from '../Pages/licences/licenses';
import { useDispatch, useSelector } from 'react-redux';
import MenuItems from '../Pages/Menu/Menu-items';
import { environment } from '../../environments/environment';
import { firestore } from '../../../../../libs/firebase-service/src';
import ContactUs from '../Pages/Contactus/contactus';
import Careers from '../Pages/Contactus/careers';
import { createBrowserHistory } from 'history';
import ScrollToTop from './ScrollTop';
import Aboutus from '../Pages/Aboutus/aboutus';
import PrivacyPolicy from '../Pages/PP-terms/PrivacyPolicy';
import TermsConditions from '../Pages/PP-terms/termsConditions';
// import { useTranslation } from 'react-i18next';
import MenuItems2 from '../Pages/Menu/Menu-items2';
import Sitemap from '../Pages/Sitemap/sitemap';
import Restaurants from '../Pages/Menu/Restaurants';
import RestaurantPage from '../Pages/Restaurants/RestaurantPage';

const Navigation = () => {
  const licenses = useSelector((state) => state.licenReducer.licenses);
  const check = window.localStorage.getItem('items');

  useEffect(() => {
    const pageTitle =
      environment.restaurantId === 'istanbulDk'
        ? `${'IstanbulDk'}`
        : environment.restaurantId === 'ttJordanDev'
        ? 'filfil jordan'
        : environment.restaurantId === 'ttJordan'
        ? 'filfil jordan'
        : environment.restaurantId === 'alyousef'
        ? 'alyousef'
        : environment.restaurantId === 'filfil'
        ? 'Filfil'
        : environment.restaurantId === 'istanbulDk' || environment.restaurantId === 'taqwasbakery' 
        ? 'TaqwasBakery'
        : environment.restaurantId === 'elmbrookschools'
        ? 'Elmbrookschools'
        : 'Table Tuck';
    document.title = pageTitle;
  }, []);

  const checkLicenses = (pageName) => {
    switch (pageName) {
      case 'checkout':
        return licenses.licenses?.frontend.checkout;
      case 'signup':
        return check != 'true' ? licenses.licenses?.frontend.signup : false;
      case 'home':
        return check != 'true' ? licenses.licenses?.frontend.home : true;
      case 'details':
        return check != 'true' ? licenses.licenses?.frontend.details : true;
      case 'profile':
        return check != 'true' ? licenses.licenses?.frontend.checkout : false;
      case 'basket':
        return check != 'true' ? licenses.licenses?.frontend.basket : false;
      case 'menu':
        return check != 'true' ? licenses.licenses?.frontend.menu : true;

      default:
        return false;
    }
  };

  if (!licenses) {
    return <> </>;
  }
  // const [t, i18n] = useTranslation();

  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route
          path="/signup"
          element={checkLicenses('signup') ? <SignUp /> : <Licenses />}
        />

        <Route
          path="/*"
          element={checkLicenses('home') ? <Home /> : <Licenses />}
        />
        {/* <Route
          path="/menu/:id"
          element={checkLicenses('menu') ? <Menu /> : <Licenses />}
        /> */}
        <Route
          path="/menu-items"
          element={checkLicenses('menu') ? <MenuItems /> : <Licenses />}
        />
        <Route
          path="/menu-items/:id"
          element={checkLicenses('menu') ? <MenuItems /> : <Licenses />}
        />
        <Route
          path="/menu"
          element={checkLicenses('menu') ? <MenuItems2 /> : <Licenses />}
        />
        <Route
          path="/menu/:id"
          element={checkLicenses('menu') ? <MenuItems2 /> : <Licenses />}
        />
        <Route
          path="/details/:id"
          element={checkLicenses('details') ? <Details /> : <Licenses />}
        />
        <Route
          path="/contactus"
          element={checkLicenses('menu') ? <ContactUs /> : <Licenses />}
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route
          path="/careers"
          element={checkLicenses('menu') ? <Careers /> : <Licenses />}
        />
        <Route
          path="/restaurants"
          element={checkLicenses('menu') ? <RestaurantPage /> : <Licenses />}
        />
        <Route
          path="/aboutus"
          element={checkLicenses('menu') ? <Aboutus /> : <Licenses />}
        />
        <Route path="/sitemap.xml" element={<Sitemap />} />
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/profile/*"
            element={checkLicenses('profile') ? <Profile /> : <Licenses />}
          />
          <Route
            path="/payment"
            element={
              checkLicenses('checkout') ? <PaymentHandler /> : <Licenses />
            }
          />
          <Route path="/confirmorder" element={<ConfirmOrderPage />} />
        </Route>
      </Routes>
    </div>
  );
};

export default Navigation;
